import React from "react";

export const CartProductQuantity = ({ item, settings }) => {
  const { routes } = settings;
  const AjaxCartQuantity = "ajax-cart-quantity" as keyof JSX.IntrinsicElements;

  return (
    <>
      <div className="cart-table__line-item-qty">
        <AjaxCartQuantity>
          <div className="flex items-center w-full h-8 border max-w-[100px] rounded-xl border-tertiary">
            <a
              href={`${routes.cart_change_url}?id=${item.key}&quantity=${item.quantity - 1}`}
              className="px-2"
              data-ajax-cart-quantity-minus
            >
              <span className="text-xs text-primary">
                <svg fill="#323D3E" width="12px" height="12px" viewBox="0 0 256 256" xmlns="http://www.w3.org/2000/svg">
                  <path d="M216,132H40a4,4,0,0,1,0-8H216a4,4,0,0,1,0,8Z" fill="#323D3E" />
                </svg>
              </span>
            </a>
            <input
              type="number"
              value={`${item.quantity}`}
              name="updates[]"
              data-ajax-cart-quantity-input={`${item.key}`}
              className="noscroll !pb-[2px] quantity-control-input !text-xs text-tertiary !bg-transparent !border-none"
              readOnly
            />
            <a
              href={`${routes.cart_change_url}?id=${item.key}&quantity=${item.quantity + 1}`}
              className="px-2"
              data-ajax-cart-quantity-plus
            >
              <i className={`icon icon-plus text-xs text-tertiary`} />
            </a>
          </div>
        </AjaxCartQuantity>
      </div>
      <div className="hidden text-sm font-semibold font-body text-primary lg:block">
        {item.final_line_price / 100}
      </div>
    </>
  );
};