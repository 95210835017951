import React, { useState } from "react";
interface ButtonDropdownProps {
  type?: string;
  text: string;
  options: any;
  onSelect?: (option: any) => void;
  inputType?: string;
  showInput?: boolean;
}

export const ButtonDropdown: React.FC<ButtonDropdownProps> = ({
  type = "form",
  text,
  options,
  onSelect = (option: any) => {},
  inputType = "checkbox",
  showInput = true,
}) => {
  const [dropdownVisible, setDropdownVisible] = useState(false);

  function toggleDropdown() {
    setDropdownVisible(!dropdownVisible);
  }

  const handleSelect = (option: any) => {
    if (onSelect) {
      onSelect(option);
    }
    setDropdownVisible(false);
  };

  return (
    <div className="relative w-full">
      <button
        className={`relative pl-4 ${
          type === "link"
            ? "bg-transparent text-white text-xs/5 pr-4 font-semibold -top-[3px] lg:pr-6"
            : "bg-[#EBF3F1] text-primary hover:bg-gray-50 b3 b3--lh28 pr-8 py-2 font-medium"
        } h-[32px] min-h-[auto] normal-case`}
        id="menu-button"
        onClick={toggleDropdown}
      >
        {text}
        <span
          className={`${
            dropdownVisible && "rotate-180"
          } absolute pointer-events-none ${
            type === "link"
              ? "top-[9px] right-0 lg:right-2.5"
              : "top-2 right-2.5"
          } icon-chevron-down`}
        ></span>
      </button>

      <div
        className={`${
          !dropdownVisible && "hidden"
        } absolute w-full mt-2 z-10 left-0 origin-top-right bg-secondary rounded-md shadow-lg min-w-max ring-1 ring-[#323D3E33] focus:outline-none ${
          type === "link" ? "py-2 px-4" : inputType === "radio" ? "p-4" : "py-[22px] px-[28px]"
        }`}
        role="menu"
        aria-orientation="vertical"
        aria-labelledby="menu-button"
      >
        {options ? (
          type === "link" ? (
            <ul>
              {options.map((option, index) => (
                <li key={index}>
                  <a
                    href={option.url}
                    className="block text-primary text-xs/5 hover:text-highlight"
                    target="_blank"
                    onClick={() => handleSelect(option)}
                  >
                    {option.label}
                  </a>
                </li>
              ))}
            </ul>
          ) : (
            <div className="grid gap-y-[6px]" role="none">
              {options.map((option) => (
                <label
                  className={`flex items-center cursor-pointer text-primary ${inputType === "radio" ? "text-base font-body hover:text-highlight" : "b3 b3--lh10 font-normal"} ${option.className}`}
                  role="menuitem"
                  id={option.id}
                  key={option.id}
                  onClick={() => handleSelect(option)}
                >
                  {showInput && (
                    <input
                      type={inputType}
                      className={`w-4 h-4 mr-2 rounded ${inputType === "radio" ? "!hidden" : ""}`}
                      name={inputType === "radio" ? "radioGroup" : undefined}
                      checked={option.selected || false}
                      onChange={() => {}}
                    />
                  )}
                  {option.label}
                </label>
              ))}
            </div>
          )
        ) : (
          <>No available options</>
        )}
      </div>
    </div>
  );
};

export default ButtonDropdown;