import * as React from "react";
import {default as ImageComponent} from "@project-stories/elements/ExtendedImage/ExtendedImage";
import {getImageUrl} from "@src/stories/components/Tile/TileMethods";

const TileImage = ({ data, height, isMobile }) => {
    const imageUrl = getImageUrl(data, isMobile)
    return (
        <>
        {data.settings.image && (
                <div
                    className={`image-container ${data.settings.darken_image && "content-over-media"} relative`}
                >
                    <ImageComponent
                        imageUrl={imageUrl}
                        alt={data.settings.title}
                        classes={`object-cover h-[${height}] w-full`}
                        style={{ borderRadius: data.settings.media_rounding }}
                    />
                </div>
            )}
        </>
    );
};

export default TileImage;
