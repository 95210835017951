import React from "react";
import StackedLayout from "./Layouts/SingleColumn";
import TwoColumnLayout from "./Layouts/TwoColumn";
import "./BlogFilters.scss";
import useMobileBreakpoint from "@src/hooks/useMobileBreakpoint";

const layouts = {
    "single": StackedLayout,
    "twocolumn": TwoColumnLayout,
};

export const BlogFilters = ({ settings }) => {
    const isMobile = useMobileBreakpoint(settings.mobile_breakpoint);
    let Layout = layouts[settings.section.layout_type] || StackedLayout;

    if (isMobile) {
        Layout = layouts[settings.section.layout_type_mobile] || StackedLayout;
    }
    return (
        <div
            className={`${settings.section.reveal_animation_class} relative`}
            style={{
                paddingTop: `${settings.section.padding_top}px`,
                paddingBottom: `${settings.section.padding_bottom}px`,
            }}
        >
            <div className="relative items-end justify-end w-full h-full">
                <Layout settings={settings} />
            </div>
        </div>
    );
};
