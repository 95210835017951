import React, { useSyncExternalStore } from "react";
import { navigation } from "../js/alpine/stores/navigation";
import ExtendedFooter from "../src/stories/components/ExtendedFooter/ExtendedFooter";
import ExtendedHeader from "../src/stories/components/Header/ExtendedHeader";
import ExtendedMegamenu from "../src/stories/components/Megamenu/ExtendedMegamenu";
import ExtendedPromoBar from "../src/stories/components/PromotionBar/ExtendedPromotionBar";
import useMobileBreakpoint from "../src/hooks/useMobileBreakpoint";

export function PromobarComponent({ ...props }) {
  return (
    <>
      <ExtendedPromoBar settings={props.settings} />
    </>
  );
}

export function HeaderComponent({ ...props }) {
  return (
    <>
      <ExtendedHeader
        settings={props.settings}
        links={props.settings.links}
        item_count={props.settings.cart.item_count}
        show_search_bar={props.settings.section.show_search_bar}
      />
    </>
  );
}

export function FooterComponent({ ...props }) {
  return (
    <>
      <ExtendedFooter
        shop={props.settings.shop}
        globals={props.settings.globals}
        settings={props.settings.section}
        links={props.settings.blocks}
        type={props.settings.section.layout}
      />
    </>
  );
}

export function MegamenuBlock({ ...props }) {
  const isMobile = useMobileBreakpoint("1024px");

  const id =
    "header-" +
    props.settings.section.attach_to.replace(" ", "-").toLowerCase();

  const menuItems = {
    id: id,
    label: props.settings.section.title,
    subMenuItems: props.settings.links,
    blocks: props.settings.blocks,
  };

  const settings = {
    section_settings: props.settings.section,
    mobileLinks: props.settings.mobile_links,
  };

  function useMenuId() {
    return useSyncExternalStore(
      navigation.store().subscribe,
      navigation.store().getMenuId
    );
  }

  return (
    <>
      {isMobile ? (
        <div>
          <ExtendedMegamenu
            settings={settings}
            menuItems={menuItems}
            id={id}
            state={useMenuId()}
          />
        </div>
      ) : (
        <div
          onMouseLeave={function () {
            navigation.store().setMenuId(null);
          }}
        >
          <ExtendedMegamenu
            settings={settings}
            menuItems={menuItems}
            id={id}
            state={useMenuId()}
          />
        </div>
      )}
    </>
  );
}
