import algoliasearch from 'algoliasearch'
import algoliarecommend from '@algolia/recommend'

export default {
    name: 'algolia-search',
    component() {
        return {
            open: false,
            init() {
                console.log('Algolia Search Component Initialized.')
            },
            toggle() {
                this.open = !this.open
            },
            close() {
                this.open = false
            },
            openDropdown() {
                this.open = true
            },
        }
    }
}
