import ListProducts  from "@mindarc/arctheme-components/frontend/src/stories/components/ecommerce/ListProducts/ListProducts";
import recentlyviewed from "../js/alpine/components/recentlyviewed";
import ExtendedRecommendedProducts from "../src/stories/components/RecommendedProducts/ExtendedRecommendedProducts";
import {useEffect, useState} from "react";
import shopify from "../js/shopify";
import recommendedProducts
    from "@mindarc/arctheme-components/frontend/src/stories/components/ecommerce/RecommendedProducts/RecommendedProducts";

const sampleProducts = [
    { product: { id: '1', title: 'Product A', image: 'https://via.placeholder.com/150', price: 99.99, url: '/' }},
    { product: { id: '2', title: 'Product B', image: 'https://via.placeholder.com/150', price: 149.99, url: '/' }},
    { product: { id: '3', title: 'Product C', image: 'https://via.placeholder.com/150', price: 199.99, url: '/' }},
];

export function RecommendedProductList({ ...props }) {

    const [products, setProducts] = useState([]);
    const { product } = props.settings;

    useEffect(() => {
        async function fetchProductRecommendations(productId) {
            const recommendedProducts = await shopify.getRecommendedProducts(productId);
            setProducts(recommendedProducts);
        }

        if (product?.id) {
            fetchProductRecommendations(product.id);
        }
    }, [product]);


    const recentlyviewedproducts = recentlyviewed.component().get();

    if (products.length === 0 || recentlyviewedproducts.length === 0) {
        return null;
    }

    return (
        <ExtendedRecommendedProducts products={products} settings={props.settings.section} recentlyviewed_products={recentlyviewedproducts}/>
    )
}

export function ProductsList({ ...props }) {

    let productData = sampleProducts;

    if (props.settings.blocks.length > 0){
        productData = props.settings.blocks;
    }


    return (
        <ListProducts products={productData} settings={props.settings.section}/>
    )
}

export function RecentlyViewedProductsList({ ...props }) {
    console.log('Retrieiving Products from Recently Viewed Component');
    const products = recentlyviewed.component().get();

    if (products.length <= 0){
        return false;
    }

    return (
        <ListProducts products={products} settings={
            {
                classes: 'list-default',
                is_dynamic: true
            }
        }/>
    )
}

