import React, { useState } from "react";
import { Paragraph } from "@mindarc/arctheme-components/frontend/src/stories/components/elements/Paragraph/Paragraph";
import ExtendedImage from "../../elements/ExtendedImage/ExtendedImage";

export const ExtendedProductAccordion = ({ items, show_header }) => {
  const [activeIndices, setActiveIndices] = useState([]);

  const handleToggle = (index) => {
    if (activeIndices.includes(index)) {
      setActiveIndices(activeIndices.filter(i => i !== index));
    } else {
      setActiveIndices([...activeIndices, index]);
    }
  };

  const isActive = (index) => activeIndices.includes(index);

  // Filter out items without a title or content, and map them only once
  const filteredItems = items
    .filter((item) => item.title && item.content)
    .map((item, index) => (
      <div key={index} className="border-b border-[#DFC7B0]">
        <div
          className="flex items-center justify-between py-2 cursor-pointer lg:py-4"
          onClick={() => handleToggle(index)}
        >
          <span className="font-semibold text-base/7 text-primary font-body lg:text-xl">
            {item.title}
          </span>
          <span className="flex items-center">
            <i className={isActive(index) ? "icon-chevron-up rotate-0" : "icon-chevron-up rotate-180"}></i>
          </span>
        </div>
        <div
          className={`overflow-hidden transition-max-height duration-200 ease-in-out ${isActive(index) ? "max-h-[600px] opacity-100" : "max-h-0 opacity-0"}`}
        >
          <div className={`transition-opacity duration-300 ease-in-out ${isActive(index) ? 'opacity-100' : 'opacity-0'}`}>
            <div className={item.layout_type === 'inline' ? 'flex items-center pb-4' : ''}>
              {item.layout_type === 'inline' && item.image && (
                <div className="flex-shrink-0 mr-7">
                  <ExtendedImage
                    imageUrl={item.image}
                    isLazy={true}
                    classes={'max-w-[100px]'} 
                  />
                </div>
              )}
              <div>
                <Paragraph
                  text={item.content}
                  type="text-sm text-primary font-body font-normal pb-4"
                />
                {item.layout_type === 'stacked' && item.image && (
                  <div className="py-4">
                    <ExtendedImage
                      imageUrl={item.image}
                      isLazy={true}
                      classes={'h-auto w-auto'} 
                    />
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    ));

  return (
    <div className="accordion">
      {show_header && <h4 className="py-4">Additional Information</h4>}
      {filteredItems}
    </div>
  );
};

export default ExtendedProductAccordion;