import * as React from "react";
import { useState } from "react";
import ExtendedImage from "../../elements/ExtendedImage/ExtendedImage";
import ExtendedHeadings from "../../elements/ExtendedHeadings/ExtendedHeadings";
import { Paragraph } from "@mindarc/arctheme-components/frontend/src/stories/components/elements/Paragraph/Paragraph";
import useMobileBreakpoint from "@src/hooks/useMobileBreakpoint";
import { mobileBreakpoint } from "../../../../entrypoints/theme";
import './CollectionBanner.scss';

export const CollectionBanner = ({ settings }) => {
  const isMobile = useMobileBreakpoint(mobileBreakpoint);
  const [isExpanded, setIsExpanded] = useState(false);
  const showReadMore = settings.description && settings.description.length > 280;

  const toggleReadMore = () => {
    setIsExpanded(!isExpanded);
  };

  const getDescriptionText = () => {
    if (isExpanded || !showReadMore) {
      return settings.description;
    }
    if (isMobile) {
      return settings.description.slice(0, 60) + "...";
    }
    return settings.description.slice(0, 200) + "...";
  };

  return (
    <div className="relative grid w-full h-full grid-cols-1">
      <div className="flex flex-col p-3 gap-3 bg-[#f7f4ef] lg:px-[71px] lg:py-9">
        <nav aria-label="Breadcrumb">
          <ol
            role="list"
            className="flex items-center space-x-2.5 text-[10px]/[25px] font-normal font-body"
          >
            <li>
              <a
                href="/"
                className="text-tertiary hover:text-primary hover:no-underline"
              >
                Home
              </a>
            </li>
            <i className="icon-arrow-long-right text-[12px]"></i>
            <li>
              <span
                className="text-primary"
                aria-current="page"
              >
                {settings.title}
              </span>
            </li>
          </ol>
        </nav>
        <div>
          <ExtendedHeadings
            text={settings.title}
            classes="h2 !text-[28px]/9 font-semibold text-primary lg:!text-4xl"
          />
        </div>
        {settings.description && (
          <Paragraph
            text={getDescriptionText()}
            type="text-sm font-body font-normal text-tertiary"
          />
        )}
        {showReadMore && (
          <button
            onClick={toggleReadMore}
            className="inline-block h-auto p-0 text-sm font-normal text-left underline normal-case text-primary min-h-max"
          >
            {isExpanded ? "Show less" : "Read more"}
          </button>
        )}
      </div>
      {/* <div className="relative w-full h-full">
        <ExtendedImage
          imageUrl={isMobile ? settings.mobile_image : settings.desktop_image}
          alt="Banner image"
          isLazy={true}
          classes="object-cover object-center w-full h-full"
        />
      </div> */}
    </div>
  );
};

export default CollectionBanner;