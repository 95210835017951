export const DEFAULT_TRENDING_QUERIES = [
  {
    searchQuery: "Cocoon Swaddle",
    popularity: 3
  },
  {
    searchQuery: "Jersey Sleeping Bag",
    popularity: 2
  },
  {
    searchQuery: "Butterfly Cardi",
    popularity: 2
  },
  {
    searchQuery: "Bodywear Singlet",
    popularity: 1
  }
]