import React from "react";
import { CartProductOptions } from "./CartProductOptions";
import { CartProductQuantity } from "./CartProductQuantity";

export const CartGridProduct = ({ item, settings }) => {
  const showVariantOptions = false;
  if (!item) {
    return false;
  }

  return (
    <div className="product-thumb-wrapper flex gap-x-[16px] h-[135px]">
      <a href={item.url}>
        <img
          src={item.image}
          alt={item.title}
          className="w-[118px] h-full object-cover"
          loading="lazy"
        />
      </a>
      <div className="product-details flex flex-col gap-y-[24px] justify-between">
        <div className="product-details__inner flex flex-col space-y-[4px]">
          <div
            className={`product-details__inner-top flex flex-row gap-x-[8px]`}
          >
            <div className="product-title flex basis-[154px]">
              {item.product_title}
            </div>
            <div className="product-price b3__regular flex justify-self-end">
              ${item.price / 100}
            </div>
          </div>

          <div className={`product-details__inner-info flex flex-col`}>
            {item.selling_plan_allocation && (
              <div className="product-selling-plan b3">
                {item.selling_plan_allocation.selling_plan.name}
              </div>
            )}
            {showVariantOptions && (
              <div className="product-options b3">
                {CartProductOptions(item.options_with_values)}
              </div>
            )}
          </div>
        </div>

        <CartProductQuantity item={item} settings={settings} />
      </div>
      <div className="text-red-500 b4" id={`cart-error-${item.key}`}>
        {item.error}
      </div>
    </div>
  );
};
