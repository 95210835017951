import React from "react";
import { Paragraph } from "@arctheme-components/elements/Paragraph/Paragraph";
import ExtendedImage from "../../elements/ExtendedImage/ExtendedImage";

export const BlogArticleTile = ({
  article,
  show_excerpt = false,
  settings,
}) => {

  const parseTags = (tags) => {
    let parsedTags: any = [];

    if (typeof tags === "string") {
      // Split string by comma to get individual tags
      tags = tags.split(",").map((tag) => tag.trim());
    }

    if (Array.isArray(tags)) {
      tags.forEach((tag) => {
        const parts = tag.split(":").map((item) => item.trim());
        if (parts.length === 2) {
          const key = parts[0];
          const value = parts[1];
          parsedTags.push(value);
        }
      });
    }

    return parsedTags;
  };

  let tags: any = parseTags(article.tags);
  const showTags = tags.length > 0;

  const charLimit = 90;
  const excerpt_text = article.excerpt || "";
  const truncateText = (text, charLimit) => {
    if (text.length <= charLimit) {
      return text;
    }
    return text.substring(0, charLimit) + "...";
  };

  const showReadMore = true;
  let article_url = "";

  if (article.blog?.handle && !article.handle.includes(article.blog?.handle)) {
    article_url = "/blogs/" + article.blog?.handle + "/" + article.handle;
  } else {
    if (settings) {
      if (
        settings.blog?.handle &&
        !article.handle.includes(settings.blog?.handle)
      ) {
        article_url = "/blogs/" + settings.blog?.handle + "/" + article.handle;
      } else {
        article_url = "/blogs/" + article.handle;
      }
    } else {
      article_url = "/blogs/" + article.handle;
    }
  }

  return (
    <>
      <div className="flex flex-col p-3 space-y-5 blog-article-tile bg-secondary">
        <a
          href={article_url}
          className={
            "blog-article__image-container h-[154px] lg:h-[282px] overflow-hidden relative"
          }
        >
          <ExtendedImage
            imageUrl={
              typeof article.image === "object" && article.image.src
                ? article.image.src
                : article.image
            }
            classes={
              "blog-article__image object-cover w-full top-0 absolute left-0 h-full"
            }
          />
        </a>

        {showTags && (
          <div className={"featured_blog__details flex"}>
            {tags.map((tag, i, arr) => {
              return (
                <React.Fragment key={i}>
                  <span className="px-2 py-1 mx-5 text-xs font-semibold text-white font-body bg-primary">
                    {tag}
                  </span>
                  {i < arr.length - 1 && arr.length > 1 && (
                    <span>&nbsp;|&nbsp;</span>
                  )}
                </React.Fragment>
              );
            })}
          </div>
        )}

        {/* {article.blog_tags && (
          <div className="min-h-14">
            <div className="flex flex-wrap gap-2 lg:ml-5">
              {article.blog_tags.split(' ').map((tag, index) => (
                <span key={index} className="px-2 py-1 text-xs font-semibold text-white font-body bg-primary">
                  {tag.split('-').map(word => word.charAt(0).toUpperCase() + word.slice(1)).join(' ')}
                </span>
              ))}
            </div>
          </div>
        )} */}

        <div className={`flex flex-col space-y-3 h-full flex-wrap min-h-12 lg:min-h-14 lg:px-5`}>
          <a
            href={article_url}
            className="text-sm/[18px] font-semibold font-body text-primary text-ellipsis overflow-hidden line-clamp-2 lg:text-xl/7"
          >
            {article.title}
          </a>

          {show_excerpt && (
            <div className="blog-article__content b3">
              <Paragraph
                text={show_excerpt && truncateText(excerpt_text, charLimit)}
                type={"b3"}
              />
            </div>
          )}
        </div>
      </div>

      {showReadMore && (
        <a
          href={`${article_url}`}
          className="flex items-center justify-center w-full py-3 font-semibold uppercase bg-darker text-xs/5 font-body text-primary"
        >
          <i className="pr-2 icon icon-plus"></i>
          Read More
        </a>
      )}
    </>
  );
};
