import productform from "./productform";

export default {
  name: "sellinggroups",
  component() {
    return {
      init() {
        console.log("Shopify Product Selling Groups Handler");

        document.addEventListener(
          "liquid-ajax-cart:request-start",
          function (event) {
            const { requestState } = event.detail;

            if (requestState.requestType === "add") {
              // Find the selected selling plan from the radio buttons
              let selectedSellingPlan = document.querySelector(
                ".pdp-details .product-selling-groups input[type=radio]:checked",
              );

              if (!selectedSellingPlan) {
                selectedSellingPlan = document.querySelector(
                  ".product-tile-hovered input[type=radio]:checked",
                );
              }
              console.log("Liquid request start", selectedSellingPlan);

              if (selectedSellingPlan) {
                const selectedSellingPlanId = selectedSellingPlan.value; // assuming the value attribute contains the selling plan ID

                console.log("Selling Plan Value", selectedSellingPlan.value);
                if (selectedSellingPlan.value !== "on") {
                  if (
                    requestState.requestBody instanceof FormData ||
                    requestState.requestBody instanceof URLSearchParams
                  ) {
                    requestState.requestBody.set(
                      "selling_plan",
                      selectedSellingPlanId,
                    );
                  } else {
                    requestState.requestBody.selling_plan =
                      selectedSellingPlanId;
                  }
                }
              }
            }
          },
        );
      },
      sellingGroupSelected(sellingGroup) {
        const variantPrice = sellingGroup.target.getAttribute(
          "data-sellinggroup-price",
        );

        const currentVariant = productform.component().getCurrentVariant();
        console.log("Current variant", currentVariant);
        const variantAvailability = currentVariant.target.getAttribute(
          "data-variant-available",
        );

        let displayPrice = variantPrice;

        if (variantAvailability !== "false") {
          productform
            .component()
            .updateAddButtonDom(false, `Add to Cart - $${displayPrice}`);
        }
      },
    };
  },
};
