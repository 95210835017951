import React from "react";
import { CartTableProduct } from "../CartTableProduct";
import { CartProductQuantity } from "../CartProductQuantity";

export const TableLayout = ({ settings }) => {
  const { cart, section, routes } = settings;

  const cartItems = cart.items;
  const productLayout = section.product_layout;

  return (
    <div className="cart-table">
      {cartItems.map((item, index) => {
        if (item.selling_plan_allocation) return null;
        return (
          <div key={index}>

              <div className="cart-table__line-item">
                <CartTableProduct item={item} />
                <CartProductQuantity item={item} settings={settings} />
                <div className="cart-table__line-item-action lg:text-center">
                  <button
                    data-ajax-cart-request-button={`/cart/change?id=${item.key}&quantity=0`}
                    className="px-0 w-auto h-auto min-h-[auto] text-sm underline normal-case text-tertiary font-body hover:text-highlight"
                  >
                    Remove
                  </button>
                </div>
              </div>
            {/* <td>
              <CartTableProduct item={item} />
            </td>
            <td>
              <CartProductQuantity item={item} settings={settings} />
            </td> */}
          </div>
        );
      })}
    </div>
  );
};
