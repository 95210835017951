import productform from "./productform";

export default {
  name: "variants",
  component() {
    return {
      hoveredSize: "",
      selectedSize: "",
      init() {
        console.log("Shopify Product Variants Handler");
      },
      selectVariant(variantOption) {
        // console.log("New variant selected", variantOption.target);
        console.log(
          "ARCTHEME: Updating variant ID input",
          variantOption.target.value,
        );

        const currentSelectedVariant = productform
          .component()
          .getCurrentVariant();
        if (currentSelectedVariant) {
          currentSelectedVariant.target.classList.remove("selected");
        }

        this.selectedSize = variantOption.target.value;

        productform.component().setCurrentVariant(variantOption);
        variantOption.target.classList.add("selected");

        this.updateAvailability(variantOption);
        // this.updateProductsIncluded(variantOption);
        this.updateAddtoCartInputIds(variantOption);
      },
      hoverVariant(variantValue) {
        // console.log("Hovering over variant", variantValue);
        this.hoveredSize = variantValue;
      },
      updateAddtoCartInputIds(variantOption) {
        const productForms = document.querySelectorAll(
          '.pdp-details .product-form input[name="id"]',
        );

        const variantId = variantOption.target.getAttribute("data-variant-id");

        productForms.forEach((productForm) => {
          productForm.value = variantId;
        });
      },
      updateSelectedVariantTitle(variantOption) {
        const newVariantTitle = variantOption.target.value;

        variantTitleElements.forEach((element) => {
          element.innerHTML = newVariantTitle;
        });
      },
      updateProductsIncluded(variantOption) {
        // ZEROCO SPECIFIC - Included Products
        const variantMetafields = variantOption.target.getAttribute(
          "data-variant-metafields",
        );
        const metafieldIds = variantMetafields
          .split(",")
          .map((id) => id.trim()) // Trim whitespace from each ID
          .filter((id) => id); // Filter out any null, undefined, or empty strings
        const event = new CustomEvent("variantProductsIncluded", {
          detail: {
            ids: metafieldIds,
          },
        });

        // Dispatch the event
        document.dispatchEvent(event);

        const variantTitleElements = document.querySelectorAll(
          "[data-arctheme-variant-title]",
        );
      },
      updateAvailability(targetVariant) {
        const variantAvailable = targetVariant.target.getAttribute(
          "data-variant-available",
        );

        // console.log("Variant Availability", variantAvailable);
        const variantPrice =
          targetVariant.target.getAttribute("data-variant-price");

        let displayPrice = variantPrice;

        const addToCartButtonText =
          variantAvailable === "false"
            ? "Out of stock"
            : this.shouldShowPriceInButton()
              ? `Add to Bag - ${displayPrice}`
              : `Add to Bag`;

        productform
          .component()
          .updateAddButtonDom(
            variantAvailable === "false",
            addToCartButtonText,
          );
      },
      shouldShowPriceInButton() {
        return false;
      },
    };
  },
};
