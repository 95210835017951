import * as React from "react";
import useSearchSpring from "@src/hooks/useSearchSpring";
import useMobileBreakpoint from "@src/hooks/useMobileBreakpoint";
import {mobileBreakpoint} from "../../../../entrypoints/theme";

export const SearchResults = ({ settings }) => {
  const isMobile = useMobileBreakpoint(mobileBreakpoint);

  const {
    products
  } = useSearchSpring(null);

  return (
    <></>
  )
}

export default SearchResults;