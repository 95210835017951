import React, { useState, useEffect } from 'react';

export const LoadingSpinner = ({ id, classes = '' }) => {
    const [isVisible, setIsVisible] = useState(false);

    useEffect(() => {
        const handleShowSpinner = (event) => {
            const { detail } = event;
            if (detail && detail.id === id) {
                setIsVisible(true);
            }
        };

        const handleHideSpinner = (event) => {
            const { detail } = event;
            if (detail && detail.id === id) {
                setIsVisible(false);
            }
        };

        window.addEventListener('show-spinner', handleShowSpinner);
        window.addEventListener('hide-spinner', handleHideSpinner);

        return () => {
            window.removeEventListener('show-spinner', handleShowSpinner);
            window.removeEventListener('hide-spinner', handleHideSpinner);
        };
    }, [id]);

    return (
        <div className={`spinner-container ${isVisible ? 'visible' : 'hidden'}`}>
            {isVisible &&
                <div className={`spinner-overlay ${classes}`}>
                    <div className="spinner"></div>
                </div>
            }
        </div>
    );
};

