import React, { useState } from "react";

const TwoColumnLayout = ({ settings }) => {
    const {blocks, url} = settings;

    const [selectedTags, setSelectedTags] = useState([]);

    const show_all = false;

    const handleTagClick = (title) => {
        if (selectedTags.includes(title)) {
            setSelectedTags(selectedTags.filter((tag) => tag !== title));
        } else {
            setSelectedTags([title]);
        }
    };

    return (
        <div x-data="blogfilters" className={`flex justify-between`}>
            <div className={'flex'}>
                <h1>Blog</h1>
            </div>
            <div className="blog-filters">
                <ul className="blog-filters__list">
                    {show_all && <li
                        className={`blog-filters__button button button-tertiary ${selectedTags.length === 0 && "selected"}`}
                        onClick={() => setSelectedTags([])}
                    >
                        <div data-id={url}>All</div>
                    </li>}
                    {blocks.map((block, index) => (
                        <li
                            key={index}
                            className={`blog-filters__button button button-tertiary ${selectedTags.includes(block.settings.title) && "selected"}`}
                            onClick={() => handleTagClick(block.settings.title)}
                        >
                            <div data-id={block.settings.url}>{block.settings.title}</div>
                        </li>
                    ))}
                </ul>
            </div>
        </div>
    );
};

export default TwoColumnLayout;
