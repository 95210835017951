import React, { useEffect, useRef } from "react";
import IconCurvedArrowLeft from "@src/stories/client/NavigationElements/ArrowLeft";
import IconCurvedArrowRight from "@src/stories/client/NavigationElements/ArrowRight";

declare var Flickity: any;

const CarouselNavigation = ({
  carouselRef,
  shiftPageDots = true,
  spread = false,
}) => {
  const dotsContainerRef = useRef(null);

  const handlePrevClick = () => {
    if (carouselRef.current) {
      if (typeof Flickity !== "undefined") {
        Flickity.data(carouselRef.current).previous();
      }
    }
  };

  const handleNextClick = () => {
    if (carouselRef.current) {
      if (typeof Flickity !== "undefined") {
        Flickity.data(carouselRef.current).next();
      }
    }
  };

  useEffect(() => {
    if (shiftPageDots && dotsContainerRef.current && carouselRef.current) {
      const observer = new MutationObserver(() => {
        const flickityDots = carouselRef.current.querySelector(
          ".flickity-page-dots",
        );
        if (flickityDots) {
          dotsContainerRef.current.appendChild(flickityDots);
          observer.disconnect(); // Stop observing once the dots have been moved
        }
      });

      observer.observe(carouselRef.current, { childList: true, subtree: true });
    }
  }, [shiftPageDots]);

  return (
    <>
      <div
        ref={dotsContainerRef}
        className="flickity-dots-container lg:hidden"
      />
      <div
        className={`carousel-nav hidden ${spread ? "justify-between items-center w-full z-[5] absolute top-1/2 transform -translate-y-1/2 lg:flex" : "lg:flex gap-3"}`}
      >
        <IconCurvedArrowLeft
          size={12}
          onClick={handlePrevClick}
        />
        {!spread && <div className="flex-grow" />}
        <IconCurvedArrowRight
          size={12}
          onClick={handleNextClick}
        />
      </div>
    </>
  );
};

export default CarouselNavigation;
