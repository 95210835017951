let currentVariant = null;

export default {
  name: "productform",
  component() {
    return {
      init() {
        console.log("Shopify Product Form Handler");
      },
      setCurrentVariant(variant) {
        currentVariant = variant;
      },
      getCurrentVariant() {
        return currentVariant;
      },
      updateAddButtonDom(disable = true, text, modifyClass = true) {
        const productForms = document.querySelectorAll(
          ".pdp-details .product-form",
        );
        console.log("ARCTHEME: Updating add button");

        productForms.forEach((productForm) => {
          if (!productForm) return;
          const addButton = productForm.querySelector('[name="add"]');
          const addButtonText = productForm.querySelector(
            '[name="add"] > span',
          );
          if (!addButton) return;
          if (disable) {
            addButton.setAttribute("disabled", "disabled");
            addButton
              .querySelector("span")
              .setAttribute(
                "x-on:click",
                "$nextTick(() => { window.scrollTo({ top: 0, behavior: 'smooth' }); })",
              );
            if (text) addButtonText.textContent = text;
          } else {
            addButton.removeAttribute("disabled");
            if (text) addButtonText.textContent = text;
          }

          if (!modifyClass) return;

          if (disable) {
            addButton.classList.add("disabled");
          } else {
            addButton.classList.remove("disabled");
          }
        });
      },
    };
  },
};
