import Alpine from 'alpinejs';

export default {
  name: 'carousel',
  component() {
    return {
      active: 0,
      hasInit: false,
      flickity: null,
      showPagination: false,
      showNavigation: true,
      fadePartial: true,
      init() {},
      initialise(showPageDots = false, showNavigation = true, fadePartial = true) {
        this.showPagination = showPageDots;
        this.showNavigation = showNavigation;
        this.fadePartial = fadePartial;
        // console.log('hasInit', this.hasInit)
        if (!this.hasInit) {
          console.log('Initialised Carousel');
          var script = document.createElement('script');
          script.type = 'text/javascript';
          script.src = 'https://unpkg.com/flickity@2/dist/flickity.pkgd.min.js';

          var stylesheet = document.createElement("link");
          stylesheet.type = "text/css";
          stylesheet.rel = "stylesheet";
          stylesheet.href = 'https://unpkg.com/flickity@2/dist/flickity.min.css';

          document.head.appendChild(stylesheet);
          document.head.appendChild(script);

          script.addEventListener('load', () => {
            if (typeof Flickity !== 'undefined') {
              this.$nextTick(() => {
                this.initializeFlickity();
                if (this.flickity) {
                  this.flickity.reloadCells();
                  this.flickity.resize();
                }
                // Dispatch the event after initializing Flickity
                this.$dispatch('carousel-initialised', true);
                this.hasInit = true;
              });
            }
          });
        } else {
          console.log('Flickity Already Loaded, Initialising');
          if (typeof Flickity !== 'undefined') {
            this.$nextTick(() => {
              this.initializeFlickity();
              if (this.flickity) {
                this.flickity.reloadCells();
                this.flickity.resize();
              }
              // Dispatch the event after initializing Flickity
              this.$dispatch('carousel-initialised', true);
            });
          }
        }
      },
      destroy() {
        if (this.flickity) {
          this.$nextTick(() => {
            return new Promise((resolve) => {
              this.flickity.destroy();
              resolve();
            }).then(() => {
              const children = this.$refs.carousel?.children;
              if (children) {
                for (let i = 0; i < children.length; i++) {
                  const child = children[i];
                  child.style.transform = '';
                  child.style.setProperty('transform', '');
                }
              }
              this.hasInit = false;
              console.log('Component destroyed');
            });
          });
        }
      },
      initializeFlickity() {
        if (this.flickity) {
          this.flickity.destroy();
        }
        this.flickity = new Flickity(this.$refs.carousel, {
          wrapAround: true,
          resize: true,
          contain: true,
          draggable: true,
          pageDots: this.showPagination,
          cellAlign: 'left',
          setGallerySize: false,
          imagesLoaded: true,
          prevNextButtons: this.showNavigation,
        });
        this.flickity.on('change', i => this.active = i);

        const observer = new MutationObserver((mutationsList, observer) => {
          for (let mutation of mutationsList) {
            if (mutation.type === 'childList') {
              this.flickity.reloadCells();
              this.flickity.resize();
            }
          }
        });

        if (this.fadePartial) {
          this.flickity.on('scroll', () => this.applyOpacity());
          this.flickity.on('settle', () => this.applyOpacity());

          this.applyOpacity();
        }

        observer.observe(this.$refs.carousel, { childList: true, subtree: true });
      },
      applyOpacity() {
        if (!this.flickity) return;
        if (this.flickity) {
          const cells = this.flickity.getCellElements();
          const margin = 50;

          cells.forEach(cell => {
            const cellRect = cell.getBoundingClientRect();
            if (this.$refs.carousel) {
              const parentRect = this.$refs.carousel.getBoundingClientRect();

              const isPartiallyVisible = (cellRect.left < (parentRect.left - margin) && cellRect.right > (parentRect.left + margin)) ||
                (cellRect.right > (parentRect.right + margin) && cellRect.left < (parentRect.right - margin));

              cell.classList.toggle('flickity-partially-visible', isPartiallyVisible);
            }
          });
        }
      },
      prev() {
        this.flickity.previous();
      },
      next() {
        this.flickity.next();
      },
    }
  }
};

window.initialiseCarousel = function () {
  Alpine.store('carousel').initialise();
}

window.destroyCarousel = function () {
  Alpine.store('carousel').destroy();
}