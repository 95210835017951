import * as React from "react";
import { useState } from "react";
import ExtendedImage from "../../elements/ExtendedImage/ExtendedImage";
import { Paragraph } from "@mindarc/arctheme-components/frontend/src/stories/components/elements/Paragraph/Paragraph";

export const TabbedWithImage = ({ settings, blocks }) => {
  const [activeTab, setActiveTab] = useState(0);
  const [animationKey, setAnimationKey] = useState(0);

  const handleTabClick = (index) => {
    setActiveTab(index);
    setAnimationKey((prevKey) => prevKey + 1);
  };

  return (
    <div className="flex flex-col lg:flex-row lg:gap-10 lg:ml-16 xl:gap-16">
      <div className="flex flex-col mx-3 lg:w-1/2 lg:mx-0">
        <ul className="flex justify-between w-full pt-2 border-b-2 border-primary/20 lg:pt-10">
          {blocks.map(
            (block, index) =>
              block.settings.title &&
              block.settings.description &&
              block.settings.featured_image && (
                <li
                  key={index}
                  className={`relative cursor-pointer flex justify-center items-center py-3 px-4 text-primary font-body max-w-max w-full text-center transition duration-300 font-semibold text-xs/9 lg:text-base lg:pb-4 xl:text-xl/9 ${
                    activeTab === index ? "active" : ""
                  }`}
                  onClick={() => handleTabClick(index)}
                >
                  {block.settings.title}
                  {activeTab === index && (
                    <span className="absolute left-0 right-0 h-1 -bottom-[2px] bg-primary"></span>
                  )}
                </li>
              )
          )}
        </ul>

        {blocks[activeTab] && blocks[activeTab].settings.description && (
          <div className="flex items-center justify-center flex-grow p-9 min-h-[200px] lg:max-w-3xl lg:pl-10 lg:py-0 lg:pr-0 xl:pl-20">
            <Paragraph
              text={blocks[activeTab].settings.description}
              type={"text-xl font-body font-semibold slide-fade-in-element xl:text-2xl/7"}
              key={animationKey}
            />
          </div>
        )}
      </div>

      <div className="flex items-center justify-center lg:w-1/2">
        {blocks[activeTab] && blocks[activeTab].settings.featured_image && (
          <ExtendedImage
            imageUrl={blocks[activeTab].settings.featured_image}
            alt={blocks[activeTab].settings.title}
            isLazy={true}
            classes="w-full h-auto object-cover slide-fade-in-element"
            key={animationKey + 1}
          />
        )}
      </div>
    </div>
  );
};

export default TabbedWithImage;