import React, { useRef } from "react";
import { useEffect, useLayoutEffect, useState } from "react";
import useMobileBreakpoint from "@src/hooks/useMobileBreakpoint";
import Tile from "@src/stories/components/Tile/Tile";
import { Headings } from "@arctheme-components/elements/Headings/Headings";
import { Paragraph } from "@arctheme-components/elements/Paragraph/Paragraph";
import { mobileBreakpoint } from "@src/../entrypoints/theme";

export default function Grid({
     settings,
     columns,
     headerText,
     subtext,
     tagline,
     columns_count,
}) {
    const { section, mobile_breakpoint } = settings;
    const isMobile = useMobileBreakpoint(mobileBreakpoint);

    const isFirstColumnDefaultLayout = columns[0]?.settings.layout === "default";
    let firstBlockHeight = isMobile
        ? columns[0]?.settings.mobile_block_height
        : columns[0]?.settings.block_height;

    if (!isFirstColumnDefaultLayout) {
        firstBlockHeight = 0;
    }
    const gapBetweenBlocksX = isMobile
        ? settings.section.gap_mobile
        : settings.section.gap;
    const gapBetweenBlocksY = isMobile
        ? settings.section.gap_y_mobile
        : settings.section.gap_y;

    return (
        <div className="multicolumn">
            {(tagline || headerText || subtext) && (
                <div className="multicolumn-content-top w-full lg:w-[700px] my-[16px]">
                    {tagline && <Paragraph text={tagline} type={"bc"}/>}
                    {headerText && <Headings text={headerText} type={"h3"}/>}
                    {subtext && <Paragraph text={subtext} type={"b2"}/>}
                </div>
            )}

            <div
                className={`flex w-full lg:h-[${firstBlockHeight}px] flex-wrap ${settings.direction} justify-${settings.section.content_alignment_mobile} lg:justify-${settings.section.content_alignment_desktop}`}
                {...(settings.section.use_carousel &&
                    !isMobile && {
                        "x-data": "carousel()",
                        "x-init": "$nextTick(() => { initialise(false, true, false) })",
                        "x-ref": "carousel",
                    })}
                {...(settings.section.use_carousel &&
                    isMobile && {
                        "x-data": "carousel()",
                        "x-init": "$nextTick(() => { destroy() })",
                        "x-ref": "carousel",
                    })}
            >
                {columns.map((column, index) => {
                    const columnWidth = isMobile
                        ? column.settings.width_mobile
                        : column.settings.width;
                    const defaultFlexBasis = `${100 / columns_count}%`;
                    const flexBasis =
                        columnWidth > 0
                            ? `calc(${columnWidth}% - ${gapBetweenBlocksX}px)`
                            : `calc(${defaultFlexBasis} - ${gapBetweenBlocksX}px)`;
                    const columnHeight = isMobile
                        ? column.settings.mobile_block_height
                        : column.settings.block_height;
                    const heightStyle = columnHeight > 0 ? `${columnHeight}px` : "auto";

                    return (
                        <div
                            key={column.key || index}
                            style={{
                                flexBasis,
                                height: column.settings.layout == "default" && heightStyle,
                                marginLeft:
                                    !settings.section.use_carousel &&
                                    index % columns_count !== 0
                                        ? `${gapBetweenBlocksX / 2}px`
                                        : 0,
                                marginRight:
                                    !settings.section.use_carousel &&
                                    (index + 1) % columns_count !== 0
                                        ? `${gapBetweenBlocksX / 2}px`
                                        : 0,
                                marginTop: `${gapBetweenBlocksY / 2}px`,
                                marginBottom: `${gapBetweenBlocksY / 2}px`,
                                ...(settings.section.use_carousel && {
                                    marginLeft: `${gapBetweenBlocksX / 2}px`,
                                    marginRight: `${gapBetweenBlocksX / 2}px`,
                                }),
                            }}
                            className="box-border overflow-hidden"
                        >
                            <Tile
                                data={column}
                                layout={column.settings.layout}
                                height={heightStyle}
                                settings={settings}
                            />
                        </div>
                    );
                })}
            </div>
        </div>
    );
}
