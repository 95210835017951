import React, { useState } from "react";
import { Paragraph } from "@arctheme-components/elements/Paragraph/Paragraph";
import ExtendedImage from "../../elements/ExtendedImage/ExtendedImage";

export const ExtendedBlogArticleTile = ({ props }) => {
  const [visibleArticles, setVisibleArticles] = useState(9);
  const articles = props.articles;

  const loadMoreArticles = () => {
    setVisibleArticles((prevVisible) => prevVisible + 9);
  };

  return (
    <>
      <div className={`gap-4 lg:gap-8 ${props.settings.layout === 'grid' ? `grid grid-cols-1 md:grid-cols-2 lg:grid-cols-${props.settings.columns_count}` : ''}`}>
        {articles.slice(0, visibleArticles).map((article, index) => (
          <div key={index} className="relative flex flex-col blog-article-tile gap-y-4 bg-secondary slide-fade-in-element">
            <div className="w-full h-[300px] overflow-hidden">
              <ExtendedImage
                imageUrl={article.image}
                isLazy={true}
                classes={'object-cover w-full h-full'}
              />
            </div>

            <div className="flex gap-2.5 mx-5 flex-col">
              <div className="flex flex-wrap items-center gap-2">
                {article.tags && article.tags.map((tag, tagIndex) => (
                  <span key={tagIndex} className="px-2 py-1 text-xs font-semibold text-secondary font-body bg-primary">
                    {tag}
                  </span>
                ))}
              </div>
              <span className="text-xs font-semibold uppercase text-highlight font-body">
                {new Date(article.created_at).toLocaleDateString("en-GB", {
                  day: "2-digit",
                  month: "long",
                  year: "numeric"
                })}
              </span>

              <a
                href={article.url}
                className="font-semibold font-body min-h-[48px]"
              >
                {article.title}
              </a>

              <Paragraph
                text={article.content}
                type={"text-sm font-body text-primary"}
              />

              <a
                href={article.url}
                className="flex items-center justify-center w-full py-3 font-semibold uppercase bg-darker text-xs/5 font-body text-primary hover:text-highlight"
              >
                <i className="pr-2 icon icon-plus"></i>
                Read More
              </a>
            </div>
          </div>
        ))}
      </div>

      {visibleArticles < articles.length && (
        <div className="mt-8 text-center">
          <button
            onClick={loadMoreArticles}
            className="btn-primary"
          >
            Load More
          </button>
        </div>
      )}
    </>
  );
};