import React, { useState, useEffect } from "react";
import { ButtonDropdown } from "../../../../src/stories/elements/ButtonDropdown";

export const CollectionToolbar = ({
  sortingOptions,
  changeSort,
  initialSortOption,
  onViewChange,
  currentView,
  totalResults
}) => {
  const [selectedSortOption, setSelectedSortOption] = useState(initialSortOption);

  const handleSortChange = (selectedOption) => {
    const [field, direction] = selectedOption.value.split("|");
    const selectedOptionValue = sortingOptions.find(option => option.field === field && option.direction === direction);
    setSelectedSortOption(selectedOptionValue);
    changeSort({ field, direction });
  };

  const dropdownOptions = sortingOptions?.map((option, index) => ({
    id: index,
    label: option.label,
    value: `${option.field}|${option.direction}`,
    className: selectedSortOption?.field === option.field && selectedSortOption?.direction === option.direction ? "font-bold" : "",
  })) || [];

  useEffect(() => {
    if (!selectedSortOption && sortingOptions.length > 0) {
      const defaultOption = sortingOptions.find(option => option.isDefault);
      if (defaultOption) {
        setSelectedSortOption(defaultOption);
      }
    }
  }, [sortingOptions]);

  const dropdownText = selectedSortOption?.label 
    ? `Sort by: ${selectedSortOption.label}` 
    : "Sort by";

  return (
    <>
      <div className="flex flex-col gap-y-5">
        <div className="flex flex-wrap items-center gap-3 lg:space-x-4 lg:gap-0 lg:justify-end lg:flex-nowrap">
          <div className="flex bg-[#F4F6F5] p-1 w-full lg:w-auto">
            <button
              className={`px-[15px] py-[5px] font-semibold font-body text-base/[22px] capitalize h-auto min-h-[auto] ${currentView === "product" ? "bg-tertiary text-white" : "bg-[#F4F6F5] text-primary"}`}
              onClick={() => onViewChange("product")}
            >
              Product View
            </button>
            <button
              className={`px-[15px] py-[5px font-semibold font-body text-base/[22px] capitalize h-auto min-h-[auto] ${currentView === "outfit" ? "bg-tertiary text-white" : "bg-[#F4F6F5] text-primary"}`}
              onClick={() => onViewChange("outfit")}
            >
              Outfit View
            </button>
          </div>
          <div className="w-full border toolbar-items lg:w-auto lg:flex">
            <div className="relative flex items-center justify-center">
              <ButtonDropdown
                key={`dropdown-${selectedSortOption?.value || "default"}`}
                text={dropdownText}
                options={dropdownOptions}
                onSelect={handleSortChange}
                inputType={"radio"}
              />
            </div>
          </div>
        </div>
        <div className="flex justify-end">
          <span className="hidden text-sm font-normal text-tertiary font-body xl:block">
            Showing {totalResults} Products
          </span>
        </div>
      </div>
    </>
  );
};

export default CollectionToolbar;