import React, { useSyncExternalStore } from "react";
import { EcommTrackOrder } from "@mindarc/arctheme-components/frontend/src/stories/components/ecommerce/TrackOrder/TrackOrder";
import trackorder from "../js/alpine/components/trackorder";
import ReviewsList from "@mindarc/arctheme-components/frontend/src/stories/components/ecommerce/ReviewsList/ReviewsList";
import hasher from "../js/alpine/stores/hasher";
import Collection from "../src/stories/ecommerce/Collection/Collection";
import CollectionFilters from "../src/stories/ecommerce/Collection/CollectionFilters";
import CollectionToolbar from "../src/stories/ecommerce/Collection/CollectionToolbar";
import SearchResults from "../src/stories/ecommerce/Search/SearchResults";
import HeaderSearch from "../src/stories/ecommerce/HeaderSearch/HeaderSearch";
import { CartItems } from "../src/stories/ecommerce/Cart/CartItems/CartItems";
import { CartSummary } from "../src/stories/ecommerce/Cart/CartSummary/CartSummary";
import { Minicart } from "../src/stories/ecommerce/Minicart/Minicart";

export function MinicartComponent({ ...props }) {
  return (
    <>
      <Minicart
        cartData={props.settings.cart}
        item_count={props.settings.cart.item_count}
        settings={props.settings.section_settings}
      />
    </>
  );
}

export function ReviewsListComponent({ ...props }) {
  return (
    <>
      <ReviewsList
        reviews={[
          {
            author: "John Certeza-Vella",
            content: "This product is freaking insane.",
            date: "19th March, 2024",
          },
        ]}
        settings={props.settings.section}
      />
    </>
  );
}
export function TrackYourOrder({ ...props }) {
  async function orderCheck(id) {
    trackorder.component().order = id;
    await hasher.store().dispatchHash();
    await trackorder.component().getOrderTrackingInfo(id);
  }

  function useGetOrder() {
    return useSyncExternalStore(
      trackorder.component().subscribe,
      trackorder.component().getOrder,
    );
  }

  return (
    <>
      <EcommTrackOrder
        title={"Track Your Order"}
        description={
          "Please enter your order number below to find tracking information about your order."
        }
        buttonText={"Search"}
        clickEvent={orderCheck}
        orderData={useGetOrder()}
      />
    </>
  );
}

// Not in Core
export function CollectionPageComponent({ ...props }) {
  return <Collection settings={props.settings} />;
}

export function SearchResultsPageComponent({ ...props }) {
  return <SearchResults settings={props.settings} />;
}

export function HeaderSearchComponent({ ...props }) {
  return <HeaderSearch settings={props.settings} />;
}

export function CollectionFiltersComponent({ ...props }) {
  return <CollectionFilters settings={props.settings} />;
}

export function CollectionToolbarComponent({ ...props }) {
  return <CollectionToolbar settings={props.settings} />;
}

export function CartItemsComponent({ ...props }) {
  return <CartItems settings={props.settings} />;
}
export function CartSummaryComponent({ ...props }) {
  return <CartSummary settings={props.settings} />;
}
