import * as React from "react";
import ExtendedTile from "../../components/ExtendedTile/Tile";
import ExtendedHeadings from "../../elements/ExtendedHeadings/ExtendedHeadings";
import useMobileBreakpoint from "@src/hooks/useMobileBreakpoint";
import { mobileBreakpoint } from "../../../../entrypoints/theme";
import { useEffect, useLayoutEffect, useState } from "react";

const ProductFeatures = ({
  settings,
  title,
  description,
  columns,
  additional,
}) => {
  const isMobile = useMobileBreakpoint(mobileBreakpoint);
  const [isLoaded, setIsLoaded] = useState(false);
  const [additionalData, setAdditionalData] = useState(null);

  useEffect(() => {
    if (additional) {
      try {
        const parsedData = JSON.parse(additional);
        setAdditionalData(parsedData);
      } catch (error) {
        console.error("Error parsing additional data:", error);
      }
    }
  }, [additional]);

  useEffect(() => {
    if (isMobile !== undefined) {
      setIsLoaded(true);
    }
  }, [isMobile]);

  if (!isLoaded) {
    return null;
  }

  const { render_type } = settings.section;
  if (render_type !== "all") {
    if (isMobile && render_type !== "mobile-only") return null;
    if (!isMobile && render_type !== "desktop-only") return null;
  }

  const dataToRender = additionalData ? additionalData : columns;

  return (
    <div>
      {((additionalData && additionalData.length > 0) || columns.length > 0) &&
        (title || description) && (
          <div className="mb-8 text-center">
            {title && (
              <ExtendedHeadings
                text={title}
                type={"h2"}
                classes={"h2 mb-5 text-primary"}
              />
            )}
            {description && (
              <p
                className="mx-auto mt-3 text-sm text-center text-primary font-body lg:text-base"
                dangerouslySetInnerHTML={{ __html: description }}
              ></p>
            )}
          </div>
        )}

      <div className="grid grid-cols-1 gap-y-8 gap-x-8 md:grid-cols-2 lg:grid-cols-3 lg:gap-x-2">
        {dataToRender.map((column, index) => (
          <div key={column.key || index} className="box-border overflow-hidden">
            <ExtendedTile data={column} />
          </div>
        ))}
      </div>
    </div>
  );
};

export default ProductFeatures;
