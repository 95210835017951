import * as React from "react";
import ExtendedImage from "../../elements/ExtendedImage/ExtendedImage";
import useMobileBreakpoint from "@src/hooks/useMobileBreakpoint";

export const PromoBanner = ({ settings }) => {
  const hasValidImage = settings.promo_banner_image_desktop || settings.promo_banner_image_mobile;
  const isMobile = useMobileBreakpoint("1024px");

  if (!hasValidImage) {
    return null;
  }

  const imageUrl = isMobile && settings.promo_banner_image_mobile
  ? settings.promo_banner_image_mobile
  : settings.promo_banner_image_desktop;

  return (
    <div>
      {settings.promo_banner_url ? (
        <a href={settings.promo_banner_url}>
          <ExtendedImage
            imageUrl={imageUrl}
            alt={imageUrl.alt}
            isLazy={true}
            classes="w-full"
          />
        </a>
      ) : (
        <ExtendedImage
          imageUrl={imageUrl}
          alt={imageUrl.alt}
          isLazy={true}
          classes="w-full"
        />
      )}
    </div>
  );
};

export default PromoBanner;