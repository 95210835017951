import * as React from "react";

const MegamenuLinks = ({
  hasSubLinks = false,
  subItem,
  linksTitle,
  onLinkHover = (subItem) => {},
  onLinkLeave = () => {},
  onLinkClick = (subItem) => {}
}) => {
  if (!subItem || !Array.isArray(subItem)) return null;

  return (
    <div className="megamenu-nav__column">
      {linksTitle && (
        <span className="block mb-4 font-semibold b1 b1--lh28 text-primary">
          {linksTitle}
        </span>
      )}
      <ul role="list">
        {subItem.map((link, index) => {
          if (!link) return null;

          return (
            <li
              key={link.handle || index}
              className="pb-2"
              onMouseEnter={() => onLinkHover(link)}
              onMouseDown={() => onLinkHover(link)}
              onMouseLeave={onLinkLeave}
              onClick={() => onLinkClick(link)}
            >
              {link.url && (
                <a href={link.url} className="font-semibold text-base/7 text-primary hover:no-underline hover:text-highlight">
                  {link.title}
                </a>
              )}
            </li>
          );
        })}
      </ul>
    </div>
  );
};

export default MegamenuLinks;