import {BlogArticle} from "@mindarc/arctheme-components/frontend/src/stories/components/blog/BlogArticle/BlogArticle";
import {BlogList} from "@mindarc/arctheme-components/frontend/src/stories/components/blog/BlogList/BlogList";
import {FeaturedBlog} from "@mindarc/arctheme-components/frontend/src/stories/components/blog/FeaturedBlog/FeaturedBlog";
import {SocialShare} from "@mindarc/arctheme-components/frontend/src/stories/components/blog/SocialShare/SocialShare";
import {BlogArticleTile} from "@mindarc/arctheme-components/frontend/src/stories/components/blog/BlogArticleTile/BlogArticleTile";
import {ExtendedBlogArticleTile} from "@project-stories/blog/BlogArticleTile/ExtendedBlogArticleTile";
import {BlogArticlesList} from "@project-stories/blog/BlogArticlesList/BlogArticlesList";
import {BlogFilters} from "@project-stories/blog/BlogFilters/BlogFilters";

export function BlogArticlesListComponent({ ...props }) {
    const { articles } = props.settings;

    return (
        <BlogArticlesList initialArticles={ articles } settings={props.settings} additional={props.settings.additional} />
    )
}

export function BlogArticleComponent({ ...props }) {
    return (
        <BlogArticleTile reverselayout={ false } settings={props.settings.section} blocks={props.settings.blocks} />
    )
}

export function BlogListComponent({ ...props }) {
    return (
        <BlogList reverselayout={ false } settings={props.settings.section} blocks={props.settings.blocks} />
    )
}

export function FeaturedBlogComponent({ ...props }) {
    return (
        <FeaturedBlog reverselayout={ false } article={props.settings.article} settings={props.settings.section} blocks={props.settings.blocks} />
    )
}
export function BlogArticleTileComponent({ ...props }) {
    return (
        <ExtendedBlogArticleTile props={props.settings}/>
    )
}

export function SocialShareComponent({ ...props }) {

    return (
        <SocialShare props={props.settings} />
    )
}
export function BlogFiltersComponent({ ...props }) {

    return (
        <BlogFilters settings={props.settings} />
    )
}