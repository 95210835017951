import { StatusIndicator } from "@mindarc/arctheme-components/frontend/src/stories/components/elements/StatusIndicator/StatusIndicator";
import { EcommFindInStore } from "@mindarc/arctheme-components/frontend/src/stories/components/ecommerce/FindInstore/FindInstore";
import { useSyncExternalStore } from "react";
import { findinstore } from "../js/alpine/components/findinstore";
import { hasher } from "../js/alpine/stores/hasher";
import { Swatches } from "@mindarc/arctheme-components/frontend/src/stories/components/ecommerce/Swatches/Swatches";
import ExtendedProductTile from "../src/stories/components/ProductTile/ProductTile";
import ExtendedProductAccordion from "../src/stories/components/ProductAccordion/ExtendedProductAccordion";
import useMobileBreakpoint from "../src/hooks/useMobileBreakpoint";
import ExtendedProductGallery from "../src/stories/components/ProductGallery/ExtendedProductGallery";
import ExtendedProductSwatches from "../src/stories/components/ProductSwatches/ExtendedProductSwatches";
import QuickAdd from "../src/stories/components/ProductQuickAdd/ProductQuickAdd";
import ProductTOG from "../src/stories/components/ProductTOG/ProductTOG"
import ProductVariantSize from "../src/stories/components/ProductVariantSize/ProductVariantSize";

export function ProductTileComponent({ ...props }) {
  return (
    <ExtendedProductTile
      product={props.settings.product}
      badges={props.settings.promotional_badges}
      tog={props.settings.tog_metafields}
      product_swatches={props.settings.product_swatches}
    />
  );
}

export function ProductStockStatus({ ...props }) {
  return (
    <StatusIndicator
      message={"In Stock"}
      status={"success"}
      show_indicator={props.settings.show_indicator}
    />
  );
}

export function ProductTOGComponent({ ...props }) {
  return (
    <ProductTOG settings={props.settings} />
  )
}

export function ProductSwatchesComponent({ ...props }) {
  return (
    <ExtendedProductSwatches settings={props.settings} />
  );
}

export function ProductVariantSizeComponent({ ...props }) {
  return (
    <ProductVariantSize settings={props.settings} />
  );
}


export function ProductQuickAddComponent({ ...props }) {
  return (
    <div className={"product__quickadd-component"}>
      <QuickAdd
        settings={props.settings}
      />
    </div>
  );
}

export function ProductGalleryComponent({ ...props }) {
  const isMobile = useMobileBreakpoint("1024px");

  let productImages = [];

  props.settings.additional.map((image, index) =>
    productImages.push({ url: image.src, alt: image.alt })
  );

  // Custom code for MJ Bale to remove swatch images from rendering in gallery
  const filteredImages = productImages.filter(
    (image) => !image.url.match(/_s_|_s(\.|$)/)
  );

  const videoUrl = props.settings.video_url
    ? `https://player.vimeo.com/video/${props.settings.video_url}?autoplay=1&quality=720p&loop=1&autopause=0&background=1`
    : null;

  return (
    <>
      {!isMobile && (
        <div className={"product-gallery"}>
          <ExtendedProductGallery
            images={filteredImages}
            galleryType={props.settings.section.gallery_type}
            videoUrl={videoUrl}
            product={props.settings.product}
            badges={props.settings.promotional_badges}
          />
        </div>
      )}
      {isMobile && (
        <div className={"product-gallery"}>
          <ExtendedProductGallery
            images={filteredImages}
            galleryType={"carousel"}
            videoUrl={videoUrl}
            product={props.settings.product}
            badges={props.settings.promotional_badges}
          />
        </div>
      )}
    </>
  );
}

export function FindInStoreBlock({ ...props }) {
  console.log("Find in Store Block SETUP");
  async function storeStockCheck() {
    console.log("Finding Store Stock");
    findinstore.component().sku = props.settings.product_sku;

    await hasher.store().dispatchHash();
    await findinstore
      .component()
      .getStockForProduct(props.settings.product_sku);
    await findinstore.component().getDeliveryMethodsShopify();
  }

  function useInventoryLevels() {
    return useSyncExternalStore(
      findinstore.component().subscribe,
      findinstore.component().getInventoryLevels
    );
  }

  function useDeliveryMethods() {
    return useSyncExternalStore(
      findinstore.component().subscribe,
      findinstore.component().getDeliveryMethods
    );
  }

  return (
    <>
      <EcommFindInStore
        title={"Find in Store"}
        description={
          "Please enter your postcode to check the delivery and collection options. Make your selection as you proceed through the checkout."
        }
        buttonText={"Search"}
        clickEvent={storeStockCheck}
        inventoryLevels={useInventoryLevels()}
        deliveryMethods={useDeliveryMethods()}
      />
    </>
  );
}

export function Accordion({ ...props }) {
  const parseDetails = (details) => {
    // Function to strip HTML tags
    const stripHtmlTags = (html) => {
      const doc = new DOMParser().parseFromString(html, "text/html");
      return doc.body.textContent || "";
    };

    // Strip HTML tags and split by semicolon characters
    const cleanDetails = stripHtmlTags(details);
    return cleanDetails
      .split(";")
      .map((item, index) => <li key={index}>{item.trim()}</li>);
  };

  const sampleItems = [
    {
      title: props.settings.additional_info_1,
      content: props.settings.additional_content_1,
      image: props.settings.additional_image_1,
      layout_type: props.settings.layout_type_1,
    },
    {
      title: props.settings.additional_info_2,
      content: props.settings.additional_content_2,
      image: props.settings.additional_image_2,
      layout_type: props.settings.layout_type_2,
    },
    {
      title: props.settings.additional_info_3,
      content: props.settings.additional_content_3,
      image: props.settings.additional_image_3,
      layout_type: props.settings.layout_type_3,
    },
    {
      title: props.settings.additional_info_4,
      content: props.settings.additional_content_4,
      image: props.settings.additional_image_4,
      layout_type: props.settings.layout_type_4,
    },
  ];

  return <ExtendedProductAccordion items={sampleItems} show_header={false} />;
}
