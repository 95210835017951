export const getPadding = (data) => {
    return `px-[${data.settings.padding_left_right_mobile}px]
    lg:px-[${data.settings.padding_left_right_desktop}px]
    pt-[${data.settings.padding_top_mobile}px]
    lg:pt-[${data.settings.padding_top_desktop}px]
    pb-[${data.settings.padding_bottom_mobile}px]
    lg:pb-[${data.settings.padding_bottom_desktop}px]`;
}

export const getTileClasses = (data, isMobile, paddingContent) => {
    return `block-title
  
     self-${data.settings.content_position_mobile}
     lg:self-${data.settings.content_position_desktop}
     ${!isMobile && `color-${data.color_schema}`}
     ${isMobile && `color-${data.color_schema_mobile}`}
     ${data.settings.padding_area == "content_only" && paddingContent}`;
}

export const getImageUrl = (data, isMobile) => {
    let imageUrl = data.settings.image?.src ?? data.settings.image;
    if (isMobile) {
        imageUrl = data.settings.image_mobile
            ? data.settings.image_mobile
            : data.settings.image?.src ?? data.settings.image;
    }
    return imageUrl;
}
