import React, {useSyncExternalStore} from "react";
import {TagList} from "@mindarc/arctheme-components/frontend/src/stories/components/blocks/TagList/TagList";
import {PageHeader} from "@mindarc/arctheme-components/frontend/src/stories/components/sections/PageHeader/PageHeader";
import {Modal} from "@mindarc/arctheme-components/frontend/src/stories/components/blocks/Modal/Modal";
import { modal } from '../js/alpine/components/modal';
import SocialLinks from "../src/stories/components/SocialLinks/SocialLinks";
import Title from "../src/stories/components/Title/Title";
import Description from "../src/stories/components/Description/Description";
import Button from "../src/stories/components/Button/Button";
import Logo from "../src/stories/components/Logo/Logo";

export function TagListBlock({ ...props })
{
  return (
    <>
      <TagList tags={props.settings.tags} />
    </>
  )
}

export function SocialLinksComponent({ ...props })
{
  const social_links = props.settings.shop.social_links;
  const section_settings = props.settings.settings;

  const links = Object.keys(social_links).map(key => ({
    url: social_links[key],
    name: key
  }));

  return (
    <>
      <SocialLinks type={'default'} links={links} settings={section_settings} />
    </>
  )
}

export function PageHeaderComponent({ ...props }) {
  return (
    <PageHeader reverselayout={ false } settings={props.settings.section} blocks={props.settings.blocks} />
  )
}

export function ModalComponent({ ...props }) {
  function closeModal(){
    modal.component().hide()
  }

  function getIsOpen() {
    return useSyncExternalStore(modal.component().subscribe, modal.component().getIsOpen);
  }

  return (
    <Modal modal_id={props.settings.id} isOpen={getIsOpen()} title={props.settings.title} description={props.settings.content} position={'right'} onClose={closeModal} />
  )
}

//Not in Core
export function LogoComponent({ ...props }) {
  return (
    <Logo settings={props.settings} />
  )
}

export function TitleComponent({ ...props }) {
  return (
    <Title settings={props.settings} />
  )
}

export function DescriptionComponent({ ...props }) {
  return (
    <Description settings={props.settings} />
  )
}

export function ButtonComponent({ ...props }) {
  return (
    <Button settings={props.settings} />
  )
}