import * as React from "react";
import ExtendedHeadings from "../../elements/ExtendedHeadings/ExtendedHeadings";

export const Title = ({ settings, title, text_alignment }) => {
  return (
    <div className={`flex flex-col 
    text-${settings.section.text_alignment_mobile || 'center'}
    lg:text-${settings.section.text_alignment || 'center'}`}>
      { title &&
        <ExtendedHeadings
          text={title}
          type={`${settings.section.text_type || 'h2'}`}
          classes={``}
          // classes={`!text-2xl/7 lg:!text-[40px]/[48px] ${text_alignment ? `text-${text_alignment}` : ''}`}
        />
      }
    </div>
  );
};
<div className="text-2xl/7"></div>

export default Title;
