import AccountPopup from "../src/stories/components/Account/AccountPopup";
import CompleteTheLook from "../src/stories/components/CompleteTheLook/CompleteTheLook";
import shopify from "../js/shopify";
import {useEffect, useState} from "react";
import ProductInstructions from "@stories/client/ProductInstructions/ProductInstructions";
import ProductFeatures from "@stories/client/ProductFeatures/ProductFeatures";
import FAQProduct from "@stories/client/FAQProduct/FAQProduct";
import SubCategories from "@stories/client/SubCategories/SubCategories.tsx";

import { mobileBreakpoint } from "../entrypoints/theme";

export function AccountPopupComponent({ ...props }) {
    return (
        <AccountPopup settings={props.settings.section} />
    )
}

export function CompleteTheLookComponent({ ...props }) {
    const [products, setProducts] = useState([]);
    const { product } = props.settings;

    useEffect(() => {
        async function fetchRelatedProducts() {
            const relatedProducts = await shopify.getRelatedProductsByTag(product, 'related:');
            setProducts(relatedProducts);
        }

        fetchRelatedProducts();
    }, [product]);

    // Only render CompleteTheLook if products are found
    if (products.length === 0) {
        return null;
    }

    return (
        <CompleteTheLook products={products} settings={props.settings.section} title={'Complete the Look'}/>
    )
}

export function SectionProductFeatures({ ...props }) {
    return (
        <ProductFeatures
            settings={props.settings}
            title={props.settings.section.title}
            description={props.settings.section.description}
            columns={props.settings.blocks}
            additional={props.settings.additional}
        />
    );
}
export function SectionProductInstructions({ ...props }) {
    return (
        <ProductInstructions
            reverselayout={false}
            settings={props.settings.section}
            blocks={props.settings.blocks}
            product={props.settings.product}
            additional={props.settings.additional}
        />
    );
}

export function SectionProductFAQ({ ...props }) {
    props.settings.section.mobile_breakpoint = mobileBreakpoint;
    return (
      <FAQProduct
            settings={props.settings.section}
            blocks={props.settings.blocks}
            faqs={props.settings.faqs}
      />
    );
}

export function SectionSubCategories({ ...props }) {
    return (
        <SubCategories
            settings={props.settings.section}
            additional={props.settings.additional}
        />
    )
}
