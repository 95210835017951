import * as React from "react";

const ExtendedMegamenuProductList = ({ block, index }) => {
  if (!Array.isArray(block.settings.product_list)) {
    console.error(`Expected block.settings.product_list to be an array, but got ${typeof block.settings.product_list}`);
    return null;
  }

  return (
    <div key={index} className="megamenu-nav__column">
      <span className="block mb-4 font-semibold b1 b1--lh28 text-primary">
        {block.settings.product_list_heading}
      </span>
      <ul
        className="megamenu-nav__image-wrapper megamenu-nav__image-wrapper--products"
        role="list"
      >
        {block.settings.product_list.map((product, productIndex) => {
          const imageUrl = product?.featured_image || '';

          return (
            <li key={productIndex} className="pb-2 group">
              <a
                href={`/products/${product.handle}`}
                className="text-sm/[18px] text-primary font-semibold cursor-pointer hover:no-underline group-hover:text-highlight"
              >
                {imageUrl && (
                  <img
                    src={imageUrl}
                    alt={product.title}
                    className="mb-3"
                  />
                )}
                {product.title}
              </a>
            </li>
          );
        })}
      </ul>
    </div>
  );
};

export default ExtendedMegamenuProductList;