import * as React from "react";
import Image from "@mindarc/arctheme-components/frontend/src/stories/components/elements/Image/Image";
import useMobileBreakpoint from "@src/hooks/useMobileBreakpoint";

const LogoBlock = ({ settings, className = "" }) => {
  const isMobile = useMobileBreakpoint("1024px");
  const logo = isMobile ? settings.additional.mobile_logo : settings.additional.logo;
  const logoWidth = isMobile ? settings.additional.mobile_logo_width : settings.additional.logo_width;

  return (
    <div className={`header__logo  ${className}`}>
      {settings.section.show_logo && (
        <a
          href="/"
          className="header__logo-image"
          style={{ width: `${logoWidth}px` }}
        >
          <Image imageUrl={logo} />
        </a>
      )}

      <a
        href="/"
        className="header-logo"
      >
        {settings.additional.shop_title}
      </a>
    </div>
  );
};

export default LogoBlock;
