import * as React from "react";
import ExtendedHeadings from "../../elements/ExtendedHeadings/ExtendedHeadings";
import { Paragraph } from "@mindarc/arctheme-components/frontend/src/stories/components/elements/Paragraph/Paragraph";
import './TemperatureGuide.scss';


export const TemperatureGuide = ({ settings, tog_block }) => {
  return (
    <div className="flex flex-col lg:flex-row">
      <div
        className="w-full px-3 py-11 lg:py-44 lg:px-32 lg:w-1/2"
        style={{ backgroundColor: settings.left_column_background_color }}
      >
        <span className="block mb-3 italic font-medium font-display text-base/4 lg:text-xl/4">
          {settings.subheading}
        </span>
        <ExtendedHeadings
          text={settings.title}
          type={"h2"}
          classes={"h2 mb-3"}
        />
        <Paragraph
          text={settings.description}
          type={"b3 text-primary !font-normal mb-3 lg:mb-[35px]"}
        />
        <a 
          href={settings.button_link}
          className="flex items-center gap-3 px-8 py-5 font-semibold uppercase transition-all duration-200 border cursor-pointer w-max text-base/5 font-body border-primary text-primary hover:bg-primary hover:text-darker hover:no-underline"
        >
          {settings.button_label}
          <i className="icon-arrow-long-right text-[12px]"></i>
        </a>
      </div>

      <div
        className="w-full py-8 px-3 lg:w-1/2 lg:py-[203px] lg:px-16"
        style={{ backgroundColor: settings.right_column_background_color }}
      >
        <Paragraph
          text={settings.section_block_heading}
          type={"mb-2 font-semibold text-2xl/7 font-body lg:mb-6"}
        />
        {tog_block.map((tog, index) => {
          const [ratingValue, ratingUnit] = tog.settings.rating.split(" ");
          return (
            <a
              key={tog.key || index}
              href={tog.settings.link}
              className="flex items-center pr-4 mb-3 border-2 last:mb-0 hover:no-underline"
              style={{ borderColor: tog.settings.colour }}
            >
              <div
                className="flex flex-col items-center justify-center w-[68px] py-[14px] lg:py-2.5"
                style={{ backgroundColor: tog.settings.colour }}
              >
                <span className="font-semibold text-xl/6 text-secondary font-body">
                  {ratingValue}
                </span>
                <span className="text-xs font-semibold text-secondary font-body">
                  {ratingUnit}
                </span>
              </div>
              <div className="flex flex-col items-start justify-between flex-grow px-6 lg:pl-4 lg:pr-7 lg:items-center lg:flex-row">
                <Paragraph
                  text={tog.settings.title}
                  type={"text-xl font-semibold text-black font-body lg:text-2xl/7"}
                />
                <Paragraph
                  text={tog.settings.description}
                  type={"text-base text-black font-body"}
                />
              </div>
              {tog.settings.link && (
                <span
                  className="flex items-center flex-none text-primary hover:no-underline"
                  style={{ "--icon-color": tog.settings.colour }}
                >
                  <i className="icon-rounded-right-arrow text-[24px] custom-arrow-icon"></i>
                </span>
              )}
            </a>
          );
        })}
      </div>
    </div>
  );
};

export default TemperatureGuide;