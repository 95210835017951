import React from "react";
import { CartProductOptions } from "./CartProductOptions";
import ExtendedImage from "../../../elements/ExtendedImage/ExtendedImage";

export const CartTableProduct = ({ item }) => {
  console.log(item);
  console.log("error:",item.error);
  console.log("message:",item.message);
  if (!item) {
    return false;
  }
  return (
    <>
      <div className="cart-table__line-item-image">
        <a href={item.url}>
          <ExtendedImage
            classes={"item__image"}
            imageUrl={item.image}
            alt={item.title}
            isLazy={true}
            />
        </a>
      </div>
      <div className="cart-table__line-item-details">
        <div className="flex flex-col product-details">
          <div className="text-sm font-semibold font-body text-primary">
            {item.product_title}
          </div>

          <div className="text-sm font-semibold font-body text-tertiary">
            {item.price / 100}
          </div>

          {item.selling_plan_allocation && (
            <div className="product-selling-plan b3">
              {item.selling_plan_allocation.selling_plan.name}
            </div>
          )}

          <div className="text-sm product-options font-body text-tertiary">
            {CartProductOptions(item.options_with_values)}
          </div>

          <div
            className="text-xs font-body text-[#F8624E] mt-1 lg:mt-0"
            data-ajax-cart-errors={`${item.key}`}
          ></div>

        </div>
      </div>
    </>
  );
};
