import * as React from "react";

export const Select = ({
  placeholder = 'Select one',
  label,
  id,
  options
}) => {
  return <>
      {label && <label htmlFor={id}>{ label }</label>}
      <div className="relative">
        <select className={'input__select b1 b1--lh28'} required id={id}>
          <option value="">{ placeholder }</option>
          {options.map((option) => (
            <option value={option.value} key={option.value}>{option.name}</option>
          ))}
        </select>
        <span className="absolute pointer-events-none right-1 top-2 icon-chevron-down"></span>
      </div>
  </>;
};

export default Select;
