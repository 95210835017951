import * as React from "react";

export const Paragraph = ({ text, type  }) => {
    return (
        <>
            {text && <p className={type} dangerouslySetInnerHTML={{__html: text.replace(/\n/g, '<br />')}}></p>}
        </>
    );
};

export default Paragraph;