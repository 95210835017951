import React, { useState } from "react";
import Image from "@mindarc/arctheme-components/frontend/src/stories/components/elements/Image/Image";
import ExtendedHeadings from "../../elements/ExtendedHeadings/ExtendedHeadings";
import { Paragraph } from "@mindarc/arctheme-components/frontend/src/stories/components/elements/Paragraph/Paragraph";
import { Button } from "@mindarc/arctheme-components/frontend/src/stories/components/elements/Button/Button";
import useMobileBreakpoint from "@src/hooks/useMobileBreakpoint";
import { mobileBreakpoint } from "../../../../entrypoints/theme";
import { clsx } from "clsx";
import ExtendedImageComponent from "../../elements/ExtendedImage/ExtendedImage";
import './ProductInstructions.scss';

const sampleData = {
  image: "https://generated.vusercontent.net/placeholder.svg",
  heading: "The complete platform for building the Web",
};

interface MetaObject {
  subheading: string;
  title: string;
  description: string;
  featured_media: string;
  video_cover_image: string;
  reverse_layout: boolean | string;
}

interface ProductMetafield {
  product_metafields: {
    text_with_media: MetaObject[];
  };
}

export const ProductInstructions = ({
  reverselayout,
  description,
  settings,
  blocks,
  product,
  additional
}: {
  reverselayout: boolean;
  description: string;
  settings: any;
  blocks: any[];
  product: any;
  additional: string;
}) => {
  const isMobile = useMobileBreakpoint(mobileBreakpoint);
  const [isVideoPlaying, setIsVideoPlaying] = useState(false);
  let productMetafield: ProductMetafield | null = null;

  try {
    productMetafield = JSON.parse(additional) as ProductMetafield;
  } catch (error) {
    console.error('Error parsing additional data:', error);
  }

  let image = sampleData.image;
  let heading = sampleData.heading;
  let type = "twi-twocolumn";
  let subheading = '';
  let videocoverimage = '';
  const imageWidth = isMobile ? settings.width_mobile : settings.width;

  const renderComponents = (metaobject?: MetaObject) => {
    if (metaobject) {
      description = metaobject.description;
      reverselayout = metaobject.reverse_layout === 'true' || metaobject.reverse_layout === true;
      image = metaobject.featured_media;
      videocoverimage = metaobject.video_cover_image;
      heading = metaobject.title;
      subheading = metaobject.subheading;
    } else {
      if (settings) {
        if (settings.content) {
          description = settings.content;
        }
        if (settings.reverse_layout) {
          reverselayout = settings.reverse_layout;
        }
        if (settings.image) {
          image = settings.image.src ?? settings.image;
        }
        if (settings.title) {
          heading = settings.title;
        }
        if (settings.classes) {
          type = settings.classes;
        }
        if (settings.tagline) {
          subheading = settings.tagline;
        }
      }
    }

    if (settings.render_type !== "all") {
      if (isMobile && settings.render_type !== "mobile-only") return null;
      if (!isMobile && settings.render_type !== "desktop-only") return null;
    }

    switch (type) {
      case "twi-twocolumn":
        return (
          <section className={`w-full ${type}`}>
            <div
              className={`twi-twocolumn__container ${clsx({
                "lg:h-[${settings.container_height}px] overflow-hidden": !productMetafield
              })}`}
            >
              <div
                className={`twi-twocolumn__content ${clsx({
                  "flex-col": isMobile,
                  "flex-row-reverse": reverselayout,
                  "gap-x-[20px]": !productMetafield
                })}`}
              >
                <div
                  style={{
                    flexBasis: `calc(${100 - parseFloat(imageWidth)}%)`,
                  }}
                  className={clsx("twi-container", {
                    "py-4 lg:p-0": !productMetafield,
                    "md:px-6": productMetafield
                  })}
                >
                  <div
                    className={`twi-content items-center text-center  ${clsx({
                      "lg:text-center": !productMetafield,
                      "lg:text-left": productMetafield
                    })}`}
                  >
                    <div className={clsx("w-full", {
                      "lg:w-[381px]": !productMetafield,
                      "py-6 px-7 lg:p-0 lg:max-w-[550px]": productMetafield
                    })}>
                      <Paragraph
                        text={subheading}
                        type={`text-base/6 text-primary font-body ${clsx({
                          "mb-5 lg:mb-8": productMetafield
                        })}`}
                      />
                      <ExtendedHeadings
                        text={heading}
                        type={"h2"}
                        classes={`${clsx({
                          "mb-5 lg:mb-8 !text-xl/9 lg:!text-[32px]/9": productMetafield,
                          "settings.heading_type": !productMetafield
                        })}`}
                      />
                      <Paragraph
                        text={description}
                        type={`text-base/6 text-primary font-body ${clsx({
                          "py-[16px]": !productMetafield
                        })}`}
                      />
                      {blocks.map((block, index) => (
                        <div
                          key={block.key || index}
                          className={`${clsx({
                            "mt-5 lg:mt-8": productMetafield
                          })}`}
                        >
                          {block.settings.button_text && (
                            <Button
                              label={block.settings.button_text}
                              type={block.settings.button_style}
                              link={block.settings.url}
                              onClick={undefined}
                            />
                          )}
                        </div>
                      ))}
                    </div>
                  </div>
                </div>

                <div
                  className="flex flex-col justify-center space-y-4 lg:basis-1/2"
                  style={{ flexBasis: imageWidth + "%" }}
                >
                  <div
                    className={`flex h-full w-full relative ${clsx({
                      "pb-[18.75rem] lg:pb-[35rem]": productMetafield && metaobject && metaobject.featured_media.includes("video")
                    })}`}
                  >
                    {metaobject && metaobject.featured_media.includes("video") ? (
                      <>
                        <video
                          src={metaobject.featured_media}
                          className="absolute top-0 left-0 object-cover w-full h-full cursor-pointer"
                          controls
                          style={{ display: isVideoPlaying ? "block" : "none" }}
                          id={`video-${metaobject.featured_media}`}
                          onClick={() => {
                            setIsVideoPlaying(false);
                          }}
                        />
                        <img
                          src={videocoverimage}
                          className="absolute top-0 left-0 object-cover w-full h-full"
                          alt="Video cover"
                          style={{ display: isVideoPlaying ? "none" : "block" }}
                        />
                        {!isVideoPlaying && (
                          <i
                            className="icon-play text-[50px] absolute inset-0 flex items-center justify-center cursor-pointer"
                            onClick={() => {
                              const videoElement = document.getElementById(
                                `video-${metaobject.featured_media}`
                              ) as HTMLVideoElement | null;
                              if (videoElement) {
                                videoElement.style.display = "block";
                                videoElement.play();
                                setIsVideoPlaying(true);
                              }
                            }}
                          ></i>
                        )}
                      </>
                    ) : (
                      <ExtendedImageComponent
                        imageUrl={image}
                        classes={"object-cover h-full w-full"}
                      />
                    )}
                  </div>
                </div>
              </div>
            </div>
          </section>
        );
      case "twi-overlayed":
        return (
          <div className={`${type}`}>
            <div className={`image-container overlay content-over-media`}>
              {image.length > 0 && <Image imageUrl={image} />}
              <div className={`twi-container`}>
                <div className="twi-content">
                  <ExtendedHeadings
                    text={heading}
                    type={settings.heading_type}
                    classes={"!text-xl/9 lg:!text-[32px]/9"}
                  />
                  <Paragraph
                    text={description}
                    type={"text-base/6"}
                  />
                  <div className={"flex"}>
                    {blocks.map((block, index) => (
                      <div key={block.key || index}>
                        {block.settings.button_text && (
                          <Button
                            type={block.settings.button_style}
                            label={block.settings.button_text}
                            link={block.settings.url}
                            onClick={undefined}
                          />
                        )}
                      </div>
                    ))}
                  </div>
                </div>
              </div>
            </div>
          </div>
        );
    }
  };

  return (
    <>
      {productMetafield
        ? productMetafield.product_metafields.text_with_media.map((metaobject, index) => (
            <React.Fragment key={index}>
              {renderComponents(metaobject)}
            </React.Fragment>
          ))
        : renderComponents()}
    </>
  );
};

export default ProductInstructions;
