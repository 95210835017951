import * as React from "react";
import useMobileBreakpoint from "@src/hooks/useMobileBreakpoint";
import { mobileBreakpoint } from "@src/../entrypoints/theme";
import { useEffect, useLayoutEffect, useState } from "react";
import Grid from "@src/stories/sections/Multicolumn/Layouts/Grid";
import Scrollable from "@src/stories/sections/Multicolumn/Layouts/Scrollable";
import Carousel from "@src/stories/sections/Multicolumn/Layouts/Carousel";
import './Multicolumn.scss';

const layouts = {
  'grid': Grid,
  'scrollable': Scrollable,
  'carousel': Carousel,
}

export const MultiColumn = ({
  settings,
  columns,
  headerText,
  subtext,
  tagline,
  columns_count,
}) => {
  let isMobile = useMobileBreakpoint(mobileBreakpoint);
  const [isLoaded, setIsLoaded] = useState(false);
  const layout = isMobile ?settings?.section.layout_mobile : settings?.section.layout;

  const LayoutComponent = layouts[layout] || Grid;

  useEffect(() => {
    if (isMobile !== undefined) {
      setIsLoaded(true);
    }
  }, [isMobile]);

  if (!isLoaded) {
    return null;
  }

  if (settings.section.render_type !== "all") {
    if (isMobile && settings.section.render_type !== "mobile-only") return;
    if (!isMobile && settings.section.render_type !== "desktop-only") return;
  }

  return (
    <>
      <LayoutComponent
          settings={settings}
           columns={columns}
           headerText={headerText}
           subtext={subtext}
           tagline={tagline}
           columns_count={columns_count}
      />
    </>
  );
};

export default MultiColumn;
