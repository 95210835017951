import React from "react";
import GridLayout from "./Layouts/Grid";
import CarouselLayout from "./Layouts/Carousel";
import { Paragraph } from "@mindarc/arctheme-components/frontend/src/stories/components/elements/Paragraph/Paragraph";
import useMobileBreakpoint from "@src/hooks/useMobileBreakpoint";
import "./BlogArticlesList.scss";

const layouts = {
  "grid": GridLayout,
  "carousel": CarouselLayout
};

export const BlogArticlesList = ({ initialArticles, settings, additional }) => {
  const productArticles = JSON.parse(additional);

  const isMobile = useMobileBreakpoint("1024px");
  let Layout = layouts[settings.section.layout_type] || GridLayout;

  const { blocks } = settings;
  if (isMobile) {
    Layout = layouts[settings.section.layout_type_mobile] || GridLayout;
  }

  let articlesToDisplay = productArticles && productArticles.length > 0 ? productArticles : initialArticles;

  if (!articlesToDisplay) {
    articlesToDisplay = [];
    if (blocks) {
      blocks.forEach((block) => {
        if (block.article) {
          articlesToDisplay.push(block.article);
        }
      });
    }
  }

  return (
    <div
      className="grid grid-cols-1 flex-col justify-center w-full lg:flex-row lg:pl-[5vw] lg:grid-cols-[320px_1fr]"
      style={{
        paddingTop: `${settings.section.padding_top}px`,
        paddingBottom: `${settings.section.padding_bottom}px`,
      }}
    >
      <div className="text-center slide-fade-in-element lg:text-left lg:mt-12">
        {settings.section.title && (
          <p
            className="mb-4 font-semibold text-2xl/7 text-primary font-body lg:text-4xl"
            dangerouslySetInnerHTML={{ __html: settings.section.title }}
          ></p>
        )}
        {settings.section.description && (
          <Paragraph
            text={settings.section.description}
            type={`text-sm/5 font-body text-primary mb-4 max-w-52 mx-auto lg:max-w-max lg:text-base`}
          />
        )}
        {settings.section.button_link && (
          <div className={`hidden items-center lg:flex`}>
            <a
              href={settings.section.button_link}
              className={`${settings.section.button_style !== 'link' && settings.section.button_style !== 'link_button' ? 'px-6 py-3 text-base font-body uppercase hover:border-transparent hover:no-underline lg:w-max lg:min-w-[134px] lg:text-center lg:py-[17px]' : ''}
                ${settings.section.button_style} ${settings.section.button_style === 'link_button' ? 'link normal-case font-display hover:!underline font-bold lg:text-xl/9': ''}`
              }
            >
              {settings.section.button_label}
              {settings.section.button_style !== 'link' && (
                <i className="icon-arrow-long-right text-[12px] pl-3"></i>
              )}
            </a>
          </div>
        )}
      </div>

      <div className="flex justify-center -mr-3 lg:justify-end lg:mr-0 lg:pl-10">
        <div className="relative items-end justify-end w-full h-full">
          <Layout initialArticles={articlesToDisplay} settings={settings}/>
        </div>
      </div>

      <a
        href={`${settings.section.button_link}`}
        className={`inline-block w-full text-center px-6 py-3 mt-12 text-base font-body uppercase ${
          settings.section.button_style === 'link_button' ? 'button btn-outline -mx-3' : ''
        } ${settings.section.button_style} hover:no-underline lg:hidden`}
      >
        {settings.section.button_label}
        <i className={`icon-arrow-long-right text-[12px] ${settings.section.button_style === 'link_button' ? 'pl-0': 'pl-3'}`}></i>
      </a>
    </div>
  );
};