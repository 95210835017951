import React, { useState, useEffect } from "react";
import ButtonDropdown from "@src/stories/elements/ButtonDropdown";

const ExtendedPromoBar = ({ settings }) => {
  const [currentIndex, setCurrentIndex] = useState(0);
  const [nextIndex, setNextIndex] = useState(null);
  const [animating, setAnimating] = useState(false);
  const { blocks } = settings;
  const numberOfBlocks = blocks.length;
  let rotationTime = settings.section.animation_speed * 1000; // Convert to milliseconds
  const animationTime = 500; // Animation duration in milliseconds

  useEffect(() => {
    if (settings.section.autoplay) {
      const interval = setInterval(() => {
        handleNext();
      }, rotationTime);
      return () => clearInterval(interval);
    }
  }, [currentIndex, rotationTime, settings.section.autoplay]);

  const handlePrev = () => {
    if (!animating) {
      setNextIndex((currentIndex - 1 + numberOfBlocks) % numberOfBlocks);
      setAnimating(true);
      setTimeout(() => {
        setCurrentIndex((prevIndex) => (prevIndex - 1 + numberOfBlocks) % numberOfBlocks);
        setNextIndex(null);
        setAnimating(false);
      }, animationTime);
    }
  };

  const handleNext = () => {
    if (!animating) {
      setNextIndex((currentIndex + 1) % numberOfBlocks);
      setAnimating(true);
      setTimeout(() => {
        setCurrentIndex((prevIndex) => (prevIndex + 1) % numberOfBlocks);
        setNextIndex(null);
        setAnimating(false);
      }, animationTime);
    }
  };

  return (
    <div className="container">
      <div
        id="site-promo-bar"
        className="promobar"
      >
        <div className="promobar-items-container overflow-hidden h-[30px] lg:h-8">
            {settings.section.show_arrows && numberOfBlocks > 1 && (
              <span onClick={handlePrev} className="promo-button promo-button-left">
                <i className="icon-chevron-left"></i>
              </span>
            )}
            <div className="flex justify-center promo-container place-items-center">
                <div className={`text-xs/[25px] font-normal font-body promo-content ${animating ? 'slide-out-left' : ''}`}>
                  <a href={blocks[currentIndex].settings.promotion_url}>
                    <div
                      dangerouslySetInnerHTML={{
                        __html: blocks[currentIndex].settings.promo_bar_content.replace(/\n/g, '<br />'),
                      }}
                    />
                  </a>
                </div>

                {nextIndex !== null && (
                  <div className={`text-xs/[25px] font-normal font-body promo-content ${animating ? 'slide-in-from-right' : ''}`}>
                    <a href={blocks[nextIndex].settings.promotion_url}>
                      <div
                        dangerouslySetInnerHTML={{
                          __html: blocks[nextIndex].settings.promo_bar_content.replace(/\n/g, '<br />'),
                        }}
                      />
                    </a>
                  </div>
                )}
            </div>
            {settings.section.show_arrows && numberOfBlocks > 1 && (
              <span
                className="promo-button promo-button-right"
                onClick={handleNext}
              >
                <i className="icon-chevron-right"></i>
              </span>
            )}
        </div>

        <div>
          <ButtonDropdown
            type="link"
            text={ settings.globals.country_links.default_country }
            options={[
              { label: "AUD", url: settings.globals.country_links.australia },
              { label: "UK", url: settings.globals.country_links.united_kingdom },
              { label: "USA", url: settings.globals.country_links.united_states }
            ].filter(option => option.label !== settings.globals.country_links.default_country)}
          />
        </div>
      </div>
    </div>
  );
};

export default ExtendedPromoBar;