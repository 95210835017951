import React from "react";

export const CartProductOptions = (options) => {
  if (!options || options.length === 0) return null;
  return (
    <ul>
      {options.map((option, index) => (
        <li key={index}>
          {option.name}: {option.value}
        </li>
      ))}
    </ul>
  );
};
