import "liquid-ajax-cart";
import React from "react";
import ReactDOM from "react-dom/client";
import Alpine from "alpinejs";
import persist from "@alpinejs/persist";
import AlpineCollapse from "@alpinejs/collapse";
import AlpineFocus from "@alpinejs/focus";
import AlpineMorph from "@alpinejs/morph";
import AlpineWire from "../js/alpine/plugins/wire";
import AlpineGlobals from "../js/alpine/index.js";
import helpers, { hasBodyClass } from "../js/helpers.js";
import {
  Accordion,
  FindInStoreBlock,
  ProductGalleryComponent,
  ProductStockStatus,
  ProductSwatchesComponent,
  ProductQuickAddComponent,
  ProductTileComponent,
  ProductTOGComponent,
  ProductVariantSizeComponent,
} from "../components/Product";
import {
  RecentlyViewedProductsList,
  RecommendedProductList,
} from "../components/List";
import {
  QuickLinksSection,
  TemperatureGuideSection,
  LogoListSection,
  ImageHotSpotSection,
  TabbedWithImageSection,
  FeaturedCollectionsSection,
  ImageCompareSection,
  TitleSection,
  PromoBannerSection,
  CollectionFilters,
  SectionCategoryBanner,
  SectionCollectionHeader,
  SectionCollectionBanner,
  MarqueeSection,
  FAQSection,
  MulticolumnBlockSection,
  SectionBanner,
  SectionFeaturedCompanies,
  SectionNewsletterSubscribe,
  SectionTestimonials,
  VideoContainerSection, SectionTextWithMedia, SectionTable, SectionGallery,
} from "../components/Sections";
import {
  FooterComponent,
  HeaderComponent,
  MegamenuBlock,
  PromobarComponent,
} from "../components/Navigation";
import { NostoComponent } from "../components/Addons";
import { StoreLocatorPage } from "../components/Pages";
import { StyleguidePage } from "../components/Styleguide";
import {
  CollectionPageComponent,
  CollectionFiltersComponent,
  CollectionToolbarComponent,
  HeaderSearchComponent,
  SearchResultsPageComponent,
  MinicartComponent,
  ReviewsListComponent,
  TrackYourOrder,
  CartItemsComponent,
  CartSummaryComponent,
} from "../components/Ecommerce";
import {
  SocialLinksComponent,
  TagListBlock,
  LogoComponent,
  TitleComponent,
  DescriptionComponent,
  ButtonComponent,
} from "../components/Blocks";
import {
  BlogArticleTileComponent,
  BlogArticlesListComponent,
  BlogFiltersComponent,
} from "../components/Blogs";
import { ProductsList } from "../components/List";
import {
  AccountPopupComponent,
  CompleteTheLookComponent,
  SectionProductFeatures,
  SectionProductInstructions,
  SectionProductFAQ,
  SectionSubCategories,
} from "../components/Client";

import("../js/facets.js");

hasBodyClass("product-template") && import("../js/prodify.js");

export const mobileBreakpoint = "1024px";

const ns = "arctheme";

window.arcthemeNamespace = ns;
window[ns] = window[ns] || {};
window[ns].helpers = helpers;

for (const [key, value] of Object.entries(helpers)) {
  window[ns].helpers[key] = value;
}

if (!window.Alpine) {
  // Register and initialize AlpineJS
  window.Alpine = Alpine;

  Alpine.plugin([AlpineCollapse, AlpineFocus, AlpineWire, AlpineMorph]);
  Alpine.plugin(persist);
  AlpineGlobals.register(Alpine);
  Alpine.start();
}

export const getAlpineStore = (storeName) => {
  if (window.Alpine && window.Alpine.store) {
    const store = window.Alpine.store(storeName);
    if (store) {
      return store;
    }
  }
  return null;
};

const component_list = [

  { name: "theme__textwithmedia", element: SectionTextWithMedia },
  { name: "theme__testimonials", element: SectionTestimonials },
  { name: "theme__banner", element: SectionBanner },
  { name: "theme__elements-product-status", element: ProductStockStatus },
  { name: "theme__newsletter-subscribe", element: SectionNewsletterSubscribe },
  { name: "theme__recommendedproducts", element: RecommendedProductList },
  { name: "theme__ecommerce-product-gallery", element: ProductGalleryComponent },
  { name: "theme__elements-accordion", element: Accordion },
  { name: "theme__multicolumn", element: MulticolumnBlockSection },
  { name: "theme__collectionfilters", element: CollectionFilters },
  { name: "theme__quicklinks", element: QuickLinksSection },
  { name: "theme__temperature-guide", element: TemperatureGuideSection },
  { name: "theme__logo-list", element: LogoListSection },
  { name: "theme__image-hot-spot", element: ImageHotSpotSection },
  { name: "theme__tabbed-with-image", element: TabbedWithImageSection },
  { name: "theme__featured_collections", element: FeaturedCollectionsSection },
  { name: "theme__image-compare", element: ImageCompareSection },
  { name: "theme__title", element: TitleSection },
  { name: "theme__promo-banner", element: PromoBannerSection },
  { name: "theme__navigation-megamenu", element: MegamenuBlock },
  { name: "theme__ecommerce-find-in-store", element: FindInStoreBlock },
  { name: "theme__ecommerce-track-your-order", element: TrackYourOrder },
  { name: "theme__nosto", element: NostoComponent },
  { name: "theme__videocontainer", element: VideoContainerSection },
  { name: "theme__storelocator", element: StoreLocatorPage },
  { name: "theme__styleguide", element: StyleguidePage },
  { name: "theme__navigation-header", element: HeaderComponent },
  { name: "theme__ecommerce-minicart", element: MinicartComponent },
  { name: "theme__navigation-promobar", element: PromobarComponent },
  { name: "theme__navigation-footer", element: FooterComponent },
  { name: "theme__section-featured-companies", element: SectionFeaturedCompanies },
  { name: "theme__section-marquee", element: MarqueeSection },
  { name: "theme__faq", element: FAQSection },
  { name: "theme__block-tags", element: TagListBlock },
  { name: "theme__list-products", element: ProductsList },
  { name: "theme__ecommerce-reviews-list", element: ReviewsListComponent },
  { name: "theme__list-recently-viewed-products", element: RecentlyViewedProductsList },
  { name: "theme__cart-items",  element: CartItemsComponent },
  { name: "theme__cart-summary", element: CartSummaryComponent },
  { name: "theme__product-quickadd", element: ProductQuickAddComponent },
  { name: "theme__product-swatches", element: ProductSwatchesComponent },
  { name: "theme__collection-main", element: CollectionPageComponent },
  { name: "theme__collection-header", element: SectionCollectionHeader },
  { name: "theme__collection-banner", element: SectionCollectionBanner },
  { name: "theme__block-logo", element: LogoComponent },
  { name: "theme__block-title", element: TitleComponent },
  { name: "theme__block-description", element: DescriptionComponent },
  { name: "theme__block-button", element: ButtonComponent },
  { name: "theme__product-tile", element: ProductTileComponent },
  { name: "theme__category-banner", element: SectionCategoryBanner },
  { name: "theme__header-account-popup", element: AccountPopupComponent },
  { name: "theme__search-header", element: HeaderSearchComponent },
  { name: "theme__search-main", element: SearchResultsPageComponent },
  { name: "theme__collection-filters", element: CollectionFiltersComponent },
  { name: "theme__collection-toolbar", element: CollectionToolbarComponent },
  { name: "theme__product-tog", element: ProductTOGComponent },
  { name: "theme__product-variant-size", element: ProductVariantSizeComponent },
  { name: "theme__blog-tile", element: BlogArticleTileComponent },
  { name: "theme__blog-articles-list", element: BlogArticlesListComponent },
  { name: "theme__blog-filters", element: BlogFiltersComponent },
  { name: "theme__section-table", element: SectionTable },
  { name: "theme__section-gallery", element: SectionGallery },


  { name: "theme__productinstructions", element: SectionProductInstructions },
  { name: "theme__productfeatures", element: SectionProductFeatures },
  { name: "theme__productfaq", element: SectionProductFAQ },
  { name: "theme__subcategories", element: SectionSubCategories },

];

if (Shopify.designMode) {
  document.addEventListener("shopify:section:load", function () {
    initObserverAnimation();
    renderReactBlocks();
  });
  document.addEventListener("shopify:section:unload", function () {
    initObserverAnimation();
    renderReactBlocks();
  });

  document.addEventListener("shopify:block:select", function () {
    initObserverAnimation();
    renderReactBlocks();
  });
}

document.addEventListener("DOMContentLoaded", function () {
  initObserverAnimation();
  renderReactBlocks();
});

document.addEventListener("liquid-ajax-cart:request-end", (event) => {
  renderReactBlocks();
});

function renderReactBlocks() {
  let node = "";

  function decodeHTMLEntities(text) {
    const entities = {
      "&amp;": "&",
      "&quot;": '"',
      "&#39;": "'",
      "&lt;": "<",
      "&gt;": ">",
      "&nbsp;": " ",
      // Add more entities if necessary
    };
    return text.replace(/&[^\s;]+;/g, (match) => entities[match] || match);
  }

  function stripHTMLTags(text) {
    const doc = new DOMParser().parseFromString(text, "text/html");
    return doc.body.textContent || "";
  }

  function sanitizeJSON(jsonString) {
    try {
      // Remove line breaks, tabs, and extra spaces
      let sanitizedString = jsonString
        .replace(/\\n/g, "")
        .replace(/\\t/g, "")
        .trim();
      // Decode HTML entities
      sanitizedString = decodeHTMLEntities(sanitizedString);
      // Strip HTML tags
      sanitizedString = stripHTMLTags(sanitizedString);
      // Remove invalid trailing commas
      sanitizedString = sanitizedString.replace(/,\s*([\]}])/g, "$1");
      return sanitizedString;
    } catch (error) {
      console.error(`Error sanitizing JSON string: ${error.message}`);
      return null;
    }
  }

  try {
    let propsDataJSON = null;
    if (component_list.length > 0) {
      for (let i = 0; i < component_list.length; i++) {
        const nodes = document.querySelectorAll(
          "[data-theme-id=" + component_list[i].name + "]",
        );

        if (nodes.length > 0) {
          for (let j = 0; j < nodes.length; j++) {
            node = component_list[i].name;

            let node_data_props = null;
            var injected = nodes[j].getAttribute("injected");

            if (!injected) {
              let propsNode =
                nodes[j].parentElement.querySelector("#react-data");

              if (propsNode) {
                propsDataJSON = propsNode.getAttribute("data-injected-props");
                const sanitizedPropsDataJSON = sanitizeJSON(propsDataJSON);

                if (sanitizedPropsDataJSON) {
                  try {
                    node_data_props = JSON.parse(sanitizedPropsDataJSON);
                  } catch (parseError) {
                    console.log(sanitizedPropsDataJSON);
                    console.error(
                      `JSON parse error on node ${node}: ${parseError.message}`,
                    );
                    continue;
                  }
                } else {
                  console.error(`Sanitization failed for node ${node}`);
                  continue;
                }
              } else {
                if (nodes[j].getAttribute("data-block-props")) {
                  propsDataJSON = nodes[j].getAttribute("data-block-props");
                  const sanitizedPropsDataJSON = sanitizeJSON(propsDataJSON);

                  if (sanitizedPropsDataJSON) {
                    try {
                      node_data_props = JSON.parse(sanitizedPropsDataJSON);
                    } catch (parseError) {
                      console.error(
                        `JSON parse error on node ${node}: ${parseError.message}`,
                      );
                      continue;
                    }
                  } else {
                    console.error(`Sanitization failed for node ${node}`);
                    continue;
                  }
                }
              }

              const Element = component_list[i].element;

              ReactDOM.createRoot(nodes[j]).render(
                <React.StrictMode>
                  <Element settings={node_data_props} />
                </React.StrictMode>,
              );

              nodes[j].setAttribute("id", component_list[i].name + "-injected");
              nodes[j].setAttribute("injected", true);
              nodes[j].removeAttribute(
                "data-block-props",
                component_list[i].name + "-injected",
              );
            }
          }
        }
      }
    }
  } catch (e) {
    console.error(`Error on node ${node}`);
    console.error(e.message);
    console.error("Error Injecting React Components");
  }
}

function initObserverAnimation() {
  var observer = new IntersectionObserver(
    function (entries, observer) {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          entry.target.style.animationDelay =
            entry.target.dataset.animationDelay || "0s";
          entry.target.classList.add("start-animation");
          observer.unobserve(entry.target);
        }
      });
    },
    {
      threshold: 0.1,
    },
  );

  // Target the elements to animate
  var elementsToAnimate = document.querySelectorAll(".animated-element");
  elementsToAnimate.forEach(function (element) {
    observer.observe(element);
  });
}
