import React from "react";
import { CartGridProduct } from "../CartGridProduct";

export const GridLayout = ({ settings }) => {
  const { cart, section, routes } = settings;

  const cartItems = cart.items;
  const productLayout = section.product_layout;

  const subscriptionProducts = cartItems.filter(
    (item) => item.selling_plan_allocation,
  );
  const oneTimeProducts = cartItems.filter(
    (item) => !item.selling_plan_allocation,
  );

  return (
    <div className={`flex flex-col gap-y-[48px]`}>
      {oneTimeProducts.length > 0 && (
        <div className="grid-container flex-col flex gap-[24px]">
          <h3 className={`sh3`}>One time purchase</h3>
          <div className={`grid grid-cols-1 md:grid-cols-2 gap-6`}>
            {oneTimeProducts.map((item, index) => (
              <div key={index} className="flex">
                <CartGridProduct item={item} settings={settings} />
              </div>
            ))}
          </div>
        </div>
      )}
      {subscriptionProducts.length > 0 && (
        <div className="grid-container flex-col flex gap-[24px]">
          <h3 className={`sh3`}>Subscription products</h3>
          <div className={`grid grid-cols-1 md:grid-cols-2 gap-6`}>
            {subscriptionProducts.map((item, index) => (
              <div key={index} className="flex">
                <CartGridProduct item={item} settings={settings} />
              </div>
            ))}
          </div>
        </div>
      )}
    </div>
  );
};
