import * as React from "react";

export const ExtendedTextField = ({
                              id,
                              inputType = 'text',
                              placeholder,
                              value,
                              required = false,
                              onChange = (event) => {},
                              disabled = false,
                              type = 'default-input'
                          }) => {
    return (
        <div className={`field ${type}`}>
            <input
                type={inputType}
                id={id}
                data-testid={id}
                className="input_inputfield"
                value={value}
                placeholder={type === 'default-input' ? placeholder : ''}
                required={required}
                onChange={onChange}
                disabled={disabled}
            />
            {type === 'floating-input' && (
                <label htmlFor={id}>{placeholder}</label>
            )}
        </div>
    );
};
