import React, { useState } from 'react';
import './Select.scss';

type SelectOption = {
    value: any,
    label: string
}

type SelectProps = {
    id?: string,
    placeholder?: string,
    name?: string,
    value?: string | number,
    options?: SelectOption[] | undefined,
    required?: boolean,
    disabled?: boolean,
    readonly?: boolean,
    hasError?: boolean,
    errorMessage?: string,
    onChange?: (value: any) => void,
    onFocus?: () => void,
    takeover?: boolean
}

export function SelectField ({
                                         id,
                                         placeholder,
                                         name,
                                         value,
                                         options = [],
                                         required,
                                         errorMessage,
                                         disabled,
                                         readonly,
                                         hasError,
                                         onChange = () => {},
                                         onFocus = () => {},
                                         takeover = false
                                     }: SelectProps) {
    const [menuIsOpen, setMenuIsOpen] = useState(false);

    const handleMenuOpen = () => {
        setMenuIsOpen(true);
    };

    const handleMenuClose = () => {
        setMenuIsOpen(false);
    };

    const handleChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
        const selectedValue = event.target.value;
        onChange(selectedValue);
    };

    return (
        <div className={`form-field__select-container ${menuIsOpen ? 'is-open' : ''}`}>
            <select
                id={id}
                name={name}
                value={value}
                required={required}
                disabled={disabled}
                className={`form-field form-field__select ${hasError ? 'has-error' : ''}`}
                onChange={handleChange}
                onFocus={onFocus}
                onBlur={handleMenuClose}
                onClick={handleMenuOpen}
            >
                {placeholder && (
                    <option value="" disabled>
                        {placeholder}
                    </option>
                )}
                {options.map((option) => (
                    <option key={option.value} value={option.value}>
                        {option.label}
                    </option>
                ))}
            </select>
            {hasError && errorMessage && (
                <div className="form-field__error-message">{errorMessage}</div>
            )}
        </div>
    );
}
