import * as React from "react";
import useMobileBreakpoint from "@src/hooks/useMobileBreakpoint";
import useSearchSpring from "@src/hooks/useSearchSpring";
import { mobileBreakpoint } from "../../../../entrypoints/theme";
import ExtendedProductTile from "@stories/components/ProductTile/ProductTile";
import PromotionTile from "@stories/ecommerce/PromotionalTile/PromotionTile";
import CollectionToolbar from "@stories/ecommerce/Collection/CollectionToolbar";
import CollectionFilters from "@stories/ecommerce/Collection/CollectionFilters";
import ExtendedHeadings from "../../elements/ExtendedHeadings/ExtendedHeadings";
import { Paragraph } from "@mindarc/arctheme-components/frontend/src/stories/components/elements/Paragraph/Paragraph";
import { useState, useEffect, useRef } from "react";

export const Collection = ({ settings }) => {
  const isMobile = useMobileBreakpoint(mobileBreakpoint);
  const [sortLoading, setSortLoading] = useState(false);
  const [filterLoading, setFilterLoading] = useState(false);
  const [currentView, setCurrentView] = useState("product");
  const prevViewRef = useRef(currentView);

  const {
    products,
    filters,
    loading,
    error,
    totalResults,
    loadMoreProducts,
    sortingOptions,
    changeSort,
    changeFilter
  } = useSearchSpring(settings?.collection);

  const allProductsLoaded = products.length >= totalResults;

  const pageType = window.location.pathname.includes('/collections/') ? 'collection' : window.location.pathname.includes('/search') ? 'search' : 'other';

  // console.log('filters', filters)

  if (error) {
    return <div>Error fetching products: {error.message}</div>;
  }

  const {
    columns_mobile,
    columns_desktop,
    column_gap_mobile,
    column_gap_desktop,
    row_gap_mobile,
    row_gap_desktop,
    id,
  } = settings.section;

  let promo_blocks = settings.promo_blocks;

  if (typeof promo_blocks === 'string') {
    try {
      promo_blocks = JSON.parse(`[${promo_blocks}]`);
      promo_blocks.forEach(block => {
        if (block.description) {
          try {
            block.description = JSON.parse(block.description);
          } catch (e) {
            console.error('Failed to parse block description:', e);
          }
        }
      });

    } catch (e) {
      console.error('Failed to parse promo_blocks:', e);
      promo_blocks = [];
    }
  }

  const renderPromotionBlock = (index) => {
    const promoBlock = promo_blocks?.find(block => block.position_number === index + 1);
    if (promoBlock) {
      return <PromotionTile promotion={promoBlock} />;
    }
    return null;
  };

  useEffect(() => {
    if (currentView !== prevViewRef.current || sortLoading) {
      const productElements = document.querySelectorAll('.product-grid__inner');
      productElements.forEach((el) => {
        el.classList.add('slide-fade-in-element');
        el.addEventListener('animationend', () => {
          el.classList.remove('slide-fade-in-element');
        });
      });
    }

    setSortLoading(false);
    setFilterLoading(false);
    prevViewRef.current = currentView;
  }, [products, currentView, sortLoading]);

  const handleSortChange = (newSortOption) => {
    setSortLoading(true);
    changeSort(newSortOption);
  };

  const handleFilterChange = (filterOption) => {
    setFilterLoading(true);
    changeFilter(filterOption);
  };

  const handleViewChange = (view) => {
    setCurrentView(view);
    setSortLoading(true);
  };

  return (
    <>
      {pageType === 'collection' && (
        <div>
          <ExtendedHeadings
            text={'Shop All'}
            type={"h2"}
            classes="text-primary !font-display !text-2xl/[40px] mb-2 lg:mb-3 lg:!text-[32px]/[40px]"
          />
          {settings.additional && (
            <Paragraph
              text={settings.additional}
              type="font-body font-normal text-primary text-sm mb-5 lg:text-base lg:mb-[30px]"
            />
          )}
        </div>
      )}
      <div className="flex flex-col-reverse justify-between lg:mb-6 xl:flex-row">
        <CollectionFilters
          changeFilter={handleFilterChange}
          filters={filters}
        />
        <CollectionToolbar
          sortingOptions={sortingOptions}
          changeSort={handleSortChange}
          initialSortOption={{ field: "updated_at", direction: "asc" }}
          currentView={currentView}
          onViewChange={handleViewChange}
          totalResults={totalResults}
        />
      </div>

      {(sortLoading || filterLoading || (loading && products.length === 0)) && (
        <div className="flex items-center justify-center my-4">
          <div className="w-6 h-6 border-t-4 border-b-4 rounded-full border-primary animate-spin"></div>
        </div>
      )}

      <div className={`${sortLoading || filterLoading && loading ? 'hidden' : ''}`}>
        <div
          id="product-grid"
          className={`grid grid-cols-${columns_mobile} lg:grid-cols-${columns_desktop} gap-x-[${column_gap_mobile}px] lg:gap-x-[${column_gap_desktop}px] gap-y-[${row_gap_mobile}px] lg:gap-y-[${row_gap_desktop}px] auto-cols-max`}
          data-id={id}
        >
          {products.map((product, index) => (
            <React.Fragment key={product.id}>
              {renderPromotionBlock(index)}
              <div className="product-grid__inner slide-fade-in-element">
                <ExtendedProductTile
                  product={product}
                  badges={product.tags}
                  tog={product.productTogs}
                  product_swatches={product.colorPatterns}
                  source={'searchspring'}
                  image_placeholder={settings.globals.product_img_placeholder}
                  view={currentView}
                />
              </div>
            </React.Fragment>
          ))}
        </div>

        {!allProductsLoaded && products.length > 0 && (
          <div className="flex justify-center mt-8">
            <button
              onClick={loadMoreProducts}
              disabled={loading}
              className="w-auto px-8 py-4 transition bg-white border border-primary text-primary hover:bg-primary hover:text-white"
            >
              {loading ? 'Loading...' : 'Load More'}
            </button>
          </div>
        )}
      </div>
    </>
  );
};

export default Collection;