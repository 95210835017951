import * as React from "react";
import ExtendedHeadings from "../../elements/ExtendedHeadings/ExtendedHeadings";

export const QuickLinks = ({ title, description, quicklinks }) => {
  return (
    <div className="flex flex-col px-[30px] lg:px-0 sm:items-center sm:justify-center">
      { title &&
        <ExtendedHeadings
          text={title}
          type={"h2"}
          classes={"text-secondary h1 text-left mb-3 lg:text-center lg:mb-10"}
        />
      }
      { description && 
        <p className="text-xl/9 lg:text-[28px]/9 text-white text-left lg:text-center font-semibold font-body mb-[30px] lg:mb-10"
          dangerouslySetInnerHTML={{ __html: description }}>
        </p>
      }
      <div className="grid grid-cols-[144px_144px] gap-x-3 gap-y-4 overflow-hidden justify-center lg:gap-3 md:grid-cols-[222px_222px_222px]">
        {quicklinks.map((quicklink, index) => (
            quicklink.settings.url && (
              <a
                key={`quicklink-${index}`}
                href={quicklink.settings.url}
                className="px-6 py-4 text-base font-normal text-center transition-all duration-200 bg-transparent border cursor-pointer font-body border-accent hover:bg-accent hover:text-primary hover:no-underline"
              >
                {quicklink.settings.title}
              </a>
            )
          ))}
      </div>
    </div>
  );
};

export default QuickLinks;