import React from "react";
import useMobileBreakpoint from "@src/hooks/useMobileBreakpoint";
import { mobileBreakpoint } from "../../../../entrypoints/theme";
import OverlayedLayout from "./layouts/Overlayed";
import TwoColumnLayout from "./layouts/TwoColumn";
import TwoColumnGridLayout from "./layouts/TwoColumnGrid";

const layouts = {
  overlayed: OverlayedLayout,
  "2column": TwoColumnLayout,
  "twocolumn-grid": TwoColumnGridLayout
};

export const TextWithMedia = ({
  reverselayout,
  description,
  settings,
  blocks,
}) => {
  const isMobile = useMobileBreakpoint(mobileBreakpoint);
  let Layout = layouts[settings.layout_type] || TwoColumnLayout;

  if (isMobile) {
    Layout = layouts[settings.layout_type_mobile] || TwoColumnLayout;
  }

  return (
    <div
      className={`${settings.reveal_animation_class} relative`}
      style={{
        paddingTop: `${settings.padding_top}px`,
        paddingBottom: `${settings.padding_bottom}px`,
      }}
    >
      <div className="relative w-full h-full justify-end items-end">
        <Layout
          blocks={blocks}
          description={description}
          reverselayout={reverselayout}
          settings={settings}
        />
      </div>
    </div>
  );
};

