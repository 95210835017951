export default {
    name: 'animations',
    store() {
        return {
            enabled: true,
            init() {
                console.log('Init Animations for Page');
            }
        }
    }
}
