import { useState, useEffect } from 'react';

// This hook returns true if the current viewport width is less than 768px
function useMobileBreakpoint(maxWidth) {
    const [isMobile, setIsMobile] = useState(false);

    useEffect(() => {
        // Define the media query
        const mediaQuery = window.matchMedia(`(max-width: ${maxWidth})`);

        // Function to update state based on the media query result
        function handleBreakpointChange(e) {
            setIsMobile(e.matches);
        }

        // Add event listener
        mediaQuery.addEventListener('change', handleBreakpointChange);

        // Check immediately on mount (important for SSR and initial render)
        handleBreakpointChange(mediaQuery);

        // Clean up
        return () => mediaQuery.removeEventListener('change', handleBreakpointChange);
    }, []);

    return isMobile;
}

export default useMobileBreakpoint;