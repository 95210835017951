import React, { useState, useEffect } from "react";
import useMobileBreakpoint from "@src/hooks/useMobileBreakpoint";
import { Paragraph } from "@arctheme-components/elements/Paragraph/Paragraph";
import { BlogArticleTile } from "@project-stories/blog/BlogArticleTile/BlogArticleTile";
import { Button } from "@arctheme-components/elements/Button/Button";
import { Headings } from "@mindarc/arctheme-components/frontend/src/stories/components/elements/Headings/Headings";

const GridLayout = ({ initialArticles, settings }) => {
  const initialPerPage = 6;
  const isMobile = useMobileBreakpoint("1024px");
  const [currentPage, setCurrentPage] = useState(1);
  const [articlesPerPage] = useState(initialPerPage);
  const [articles, setArticles] = useState(initialArticles || []);
  const [currentArticles, setCurrentArticles] = useState(
    initialArticles?.slice(0, articlesPerPage) || [],
  );

  const { section, blocks } = settings;
  const contentBlock = blocks?.find((block) => block.type === "content_block");

  const showPagination = false;

  useEffect(() => {
    const handleArticlesComplete = (event) => {
      setCurrentPage(1);
      setArticles(event.detail.articles);
    };

    const handleNextPage = (event) => {
      if (event.detail.type === "pagination") {
        setCurrentPage((prevPage) => prevPage + 1);
      }
    };

    window.addEventListener("blog-articles-complete", handleArticlesComplete);
    window.addEventListener("nextpage", handleNextPage);

    return () => {
      window.removeEventListener(
        "blog-articles-complete",
        handleArticlesComplete,
      );
      window.removeEventListener("nextpage", handleNextPage);
    };
  }, []);

  useEffect(() => {
    const indexOfLastArticle = currentPage * articlesPerPage;
    const newArticles = articles.slice(0, indexOfLastArticle);
    setCurrentArticles(newArticles);
  }, [currentPage, articles, articlesPerPage]);

  const contentBlockRender = () => (
    <div className="flex flex-col space-y-[16px]">
      <Headings
        text={contentBlock?.settings.title}
        type={contentBlock?.settings.heading_type}
      />
      <Paragraph
        text={contentBlock?.settings.subtext}
        type={contentBlock?.settings.subtext_type}
      />
    </div>
  );

  return (
    <div className={`w-full color-${section.color_schema}`}>
      <div className={"flex justify-between"}>
        <div className={"flex-item flex"}>
          <div className={"section-toprow__details flex"}>
            {contentBlockRender()}
          </div>
        </div>
        <div className={"flex-item flex"}>
          {contentBlock?.settings.cta_text && (
            <Button
              link={contentBlock?.settings.cta_url}
              label={contentBlock?.settings.cta_text}
              style={contentBlock?.settings.cta_style}
            />
          )}
        </div>
      </div>

      <div
        className="flex flex-col py-4 blog-articles"
        x-data="pagination"
        x-init={`init(${articles.length}, ${articlesPerPage}, true, 'articles')`}
      >
        {currentArticles.length > 0 && (
          <>
            <div
              className={`article-items grid grid-cols-1 lg:grid-cols-3 lg:grid-cols-${section.columns_count} gap-[8px] lg:gap-[20px]`}
            >
              {currentArticles.map((article, index) => (
                <div
                  className="my-4 blog-articles__article article"
                  key={index}
                >
                  <BlogArticleTile
                    article={article}
                    show_excerpt={false}
                    settings={settings}
                  />
                </div>
              ))}
            </div>
            {showPagination && (
              <div
                x-ref="paginationContainer"
                className="flex justify-center items-center space-x-4 pt-[64px] lg:pt-[87px]"
              ></div>
            )}
          </>
        )}
        {currentArticles.length <= 0 && (
          <div className={"blog-articles-empty"}>
            <Paragraph
              text={"There are no articles found with that filter."}
              type={"b2"}
            />
          </div>
        )}
      </div>
    </div>
  );
};

export default GridLayout;
