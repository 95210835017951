import React, { ReactNode, useState } from "react";
import { clsx } from 'clsx';

type CollapsibleProps = {
  title: string,
  openIcon?: string,
  closeIcon?: string,
  children: ReactNode,
  active?: boolean,
  disabled?: boolean,
  desktopDisabled?: boolean
};

export default function Collapsible(props: CollapsibleProps) {
  const {
    title,
    active = false,
    openIcon = 'icon-at-plus',
    closeIcon = 'icon-at-minus',
    children,
    disabled = false,
    desktopDisabled = false
  } = props;

  const [isActive, setIsActive] = useState(active);

  function toggle() {
    if (disabled) return;

    setIsActive(!isActive);
  }

  return (
    <div
      className={clsx({
        'collapsible': true,
        'collapsible--active': isActive,
        'collapsible--disabled': disabled,
        'collapsible--desktop-disabled': desktopDisabled
      })}
    >
      <div
        className="collapsible__title"
        onClick={() => toggle()}
      >
        {title}

        {!disabled &&
          <>
            <span className={'collapsible__expand-icon ' + openIcon}></span>
            <span className={'collapsible__collapse-icon ' + closeIcon}></span>
          </>
        }
      </div>

      <div className="collapsible__content">
        {children}
      </div>
    </div>
  )
};
