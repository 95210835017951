import * as React from "react";
import "alpinejs";
import { TextField } from "@mindarc/arctheme-components/frontend/src/stories/components/elements/TextField/TextField";
import HeaderMenu from "@stories/components/Header/ExtendedHeaderMenu";
import HeaderActions from "@stories/components/Header/ExtendedHeaderActions";
import LogoBlock from "@stories/components/Header/ExtendedHeaderLogo";
import MobileMenu from "@stories/components/Header/ExtendedHeaderMobileMenu";

const ExtendedHeader = ({
  colorSchema,
  links,
  item_count = "",
  settings,
  show_search_bar = false,
  featured_collection_title,
  featured_collection_list,
  featured_product_title,
  featured_product_list,
}) => {
  return (
    <div
      className="header__wrapper"
      x-init="$nextTick(() => { $store.navigation.init() })"
    >
      <HeaderLeftLayout
        settings={settings}
        links={links}
        item_count={item_count}
        show_search_bar={show_search_bar}
        colorSchema={colorSchema}
        featuredCollectionTitle={featured_collection_title}
        featuredCollectionList={featured_collection_list}
        featuredProductTitle={featured_product_title}
        featuredProductList={featured_product_list}
      />
    </div>
  );
};

const HeaderLeftLayout = ({
  colorSchema,
  links,
  item_count = "",
  settings,
  show_search_bar = false
}) => {
  return (
    <div className="relative flex items-center">
      <LogoBlock settings={settings} />
      <HeaderMenu link_list={links} />
      {show_search_bar && (
        <div className="header-search">
          <TextField id="search" placeholder="Search..." value="" />
        </div>
      )}
      <HeaderActions item_count={item_count} settings={settings} />
      <MobileMenu
        settings={settings}
        linkList={links}
      />
    </div>
  );
};

export default ExtendedHeader;