import React, { useState, useEffect } from "react";

const HeaderMenu = ({ link_list }) => {
  const [hoveredLink, setHoveredLink] = useState(null);
  const menuLinks = link_list[0].links;
  const menuElements = ['.header__menu', '.megamenu-nav'];

  useEffect(() => {
    window.addEventListener("megamenu-closed", handleMenuClose);

    if (hoveredLink !== null) {
      const event = new CustomEvent("megamenu-hover", {
        detail: { message: `header-${hoveredLink}` },
      });
      window.dispatchEvent(event);
    }

    return () => {
      window.removeEventListener("megamenu-closed", handleMenuClose);
    };
  }, [hoveredLink]);

  useEffect(() => {
    const headerLogo = document.querySelector('.header__logo');
    if (headerLogo) {
      headerLogo.addEventListener("megamenu-closed", handleMenuClose);
    }

    return () => {
      if (headerLogo) {
        headerLogo.removeEventListener('megamenu-closed', handleMenuClose);
      }
    };
  }, []);

  const handleMenuClose = () => {
    setHoveredLink(null);
  };

  const handleMouseEnter = (dataId, link) => {
    setHoveredLink(dataId);
  };

  const handleMouseLeave = (event) => {
    let isInsideContainer = false;

    menuElements.forEach(selector => {
      const container = document.querySelector(selector);
      // console.log('container', container)
      if (container && container.contains && container.contains(event.relatedTarget)) {
        isInsideContainer = true;
      }
    });

    if (!isInsideContainer) {
      setHoveredLink(null);
    }
  };

  return (
    <div className="header__links">
      <div className="block">
        <ul className="header__menu">
          {menuLinks.map((link, id) => {
            const titleLowerCase = link.title.toLowerCase();
            const dataId = titleLowerCase.replace(" ", "-");
            const hoverClass = hoveredLink === dataId ? "hovered" : "";

            return (
              <li
                key={id}
                data-id={`header-${dataId}`}
                className="header__menu-item"
              >
                <a
                  href={link.url}
                  className={`${hoverClass} header__menu-link`}
                  onMouseEnter={() => handleMouseEnter(dataId, link)}
                  onMouseLeave={(event) => handleMouseLeave(event)}
                >
                  {link.title}
                </a>
              </li>
            );
          })}
        </ul>
      </div>
    </div>
  );
};

export default HeaderMenu;