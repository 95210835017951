import algolia from "./algolia";

export const shopify_search = {
    name: 'shopify_search',
    component() {
        return {
            open: false,
            init() {
                console.log('Shopify Search Component Initialized.')
            },
            toggle() {
                this.open = !this.open
            },
            close() {
                this.open = false
            },
            openDropdown() {
                this.open = true
            },
        }
    }
}
export default shopify_search;
