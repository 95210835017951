import React from "react";
import { Paragraph } from "@arctheme-components/elements/Paragraph/Paragraph";

export const CartTotals = ({ cart_subtotal, cart_items }) => {
  // Calculate total savings for items with a selling plan
  const totalSavings = cart_items.reduce((acc, item) => {
    if (item.selling_plan_allocation) {
      const originalPrice = item.selling_plan_allocation.compare_at_price; // Original price before discount
      const discountedPrice = item.selling_plan_allocation.price; // Discounted price (current price)
      const itemSavings =
        ((originalPrice - discountedPrice) / 100) * item.quantity;
      return acc + itemSavings;
    }
    return acc;
  }, 0);

  const totalBeforeSavings = totalSavings + cart_subtotal;

  return (
    <div className={"py-[8px]"}>
      <div className="minicart__totals">
        <Paragraph text={"Subtotal"} type={"text-sm font-body text-primary font-semibold"} />
        <Paragraph text={`$${totalBeforeSavings.toFixed(2)}`} type="font-body text-sm text-primary" />
      </div>
      {totalSavings > 0 && (
        <div className="minicart__totals">
          <Paragraph
            text={"Total Savings"}
            type={"text-sm font-body text-primary font-semibold"}
          />
          <div
            className={
              "flex rounded-xl border-solid border border-tertiary/50 px-2 py-1"
            }
          >
            -${totalSavings.toFixed(2)}
          </div>
        </div>
      )}
      <div className="minicart__totals">
        <Paragraph text={"Shipping"} type={"text-sm font-body text-primary font-semibold"} />
        <div className={"flex flex-col"}>
          <Paragraph
            text={`Calculated at checkout`}
            type={"text-primary text-sm font-body"}
          />
        </div>
      </div>
      <div className="minicart__totals">
        <Paragraph text={"Total"} type={"text-sm font-body text-primary font-semibold"} />
        <Paragraph text={`$${cart_subtotal.toFixed(2)}`} type="font-body text-sm text-primary" />
      </div>
    </div>
  );
};
