import * as React from "react";

import {Paragraph} from "@mindarc/arctheme-components/frontend/src/stories/components/elements/Paragraph/Paragraph";
import useMobileBreakpoint from "@src/hooks/useMobileBreakpoint";
import {mobileBreakpoint} from "../../../../entrypoints/theme"

export const Description = ({ settings }) => {
    const isMobile = useMobileBreakpoint(mobileBreakpoint);
    const renderComponents = () => {
        return (
            <div className={`block-description mb-[${settings.block_settings.margin_bottom_mobile}px] lg:mb-[${settings.block_settings.margin_bottom_desktop}px] text-${settings.section_settings.content_alignment_mobile} lg:text-${settings.section_settings.content_alignment_desktop}`}>
                <Paragraph text={settings.block_settings.content} type={settings.block_settings.body_type} />
            </div>
        )
    }

    if (settings.block_settings.render_type !== 'all') {
        if (isMobile && settings.block_settings.render_type !== 'mobile-only') return;
        if (!isMobile && settings.block_settings.render_type !== 'desktop-only') return;
    }

    return (
        <>
            {renderComponents()}
        </>
    );
}

export default Description;