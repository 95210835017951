import React, { useState, useEffect } from "react";
import MegamenuLinks from "./ExtendedMegamenuLinks";
import ExtendedMegamenuProductList from "./ExtendedMegemenuProductList";
import ExtendedMegamenuCollectionList from "./ExtendedMegamenuCollectionList";
import ExtendedHeadings from "../../elements/ExtendedHeadings/ExtendedHeadings";
import useMobileBreakpoint from "@src/hooks/useMobileBreakpoint";
import { clsx } from "clsx";
import { mobileBreakpoint } from "../../../../entrypoints/theme";

const ExtendedMegamenu = ({ settings, menuItems, id, state }) => {
  const [anim, setAnim] = useState(false);
  const [isAnimating, setIsAnimating] = useState(false);
  const [hoveredLink, setHoveredLink] = useState(null);
  const [mobileMenuVisible, setMobileMenuVisible] = useState(false);
  const [isMainMenu, setIsMainMenu] = useState(true);
  const animClass = state === id ? "slide-in-element" : "slide-out-element";
  const isMobile = useMobileBreakpoint(mobileBreakpoint);

  const getDefaultActiveMenuItem = () => {
    const firstItem = settings.mobileLinks[0]?.links?.[0]?.title;
    return { [firstItem]: true };
  };

  const [activeMenuItem, setActiveMenuItem] = useState(getDefaultActiveMenuItem);

  useEffect(() => {
    setIsAnimating(true);
  }, [state, id]);

  const onAnimationEnd = () => {
    if (state !== id) {
      setAnim(false);
      setIsAnimating(false);
    } else {
      setAnim(true);
      setIsAnimating(false);
    }
  };

  const handleLinkHover = (link) => {
    if (link?.level <= 2) {
      setHoveredLink(link);
      setActiveLink(link.handle);
    }
  };

  const setActiveLink = (handle) => {
    const activeLink = document.querySelector(
      ".megamenu__link-container.active"
    );
    if (activeLink) {
      activeLink.classList.remove("active");
    }

    const linkDiv = document.querySelector(`div[data-link-id='${handle}']`);
    if (linkDiv) {
      linkDiv.classList.add("active");
    }
  };

  const handleMobileMenuClick = (item) => {
    setActiveMenuItem((prevState) => ({
      ...prevState,
      [item.title]: !prevState[item.title],
    }));
    setMobileMenuVisible(true);
    setIsMainMenu(false);
  };

  const dispatchCustomEvent = (eventName, detail = { message: "" }) => {
    window.dispatchEvent(new CustomEvent(eventName, { detail }));
  };
  
  const handleBackClick = () => {
    if (!isMainMenu) {
      setIsMainMenu(true);
      setMobileMenuVisible(false);
      setActiveMenuItem(getDefaultActiveMenuItem());
      dispatchCustomEvent("megamenu-menu-close");
    }
    dispatchCustomEvent("megamenu-menu-close");
  };

  const renderSublinks = (links, level = 0) => {
    return (
      <ul className={`mobile-submenu-list px-6 level-${level}`}>
        {Array.isArray(links) &&
          links.map((subItem, subIndex) => (
            <li key={subIndex}>
              {subItem.links && subItem.links.length > 0 ? (
                <div
                  onClick={() => handleMobileMenuClick(subItem)}
                  className="relative flex items-center text-base font-semibold font-body py-2.5 cursor-pointer"
                >
                  <span>{subItem.title}</span>
                  <i
                    className={`absolute text-xs ${
                      activeMenuItem?.[subItem.title] ? "rotate-0" : "rotate-180"
                    } right-3 icon-chevron-up`}
                  ></i>
                </div>
              ) : (
                <a
                  href={subItem.url}
                  className="relative flex items-center text-base font-semibold font-body py-2.5 hover:no-underline"
                >
                  <span>{subItem.title}</span>
                </a>
              )}
              {activeMenuItem?.[subItem.title] &&
                subItem.links &&
                subItem.links.length > 0 && (
                  <div className="mobile-submenu">
                    {renderSublinks(subItem.links, level + 1)}
                  </div>
                )}
            </li>
          ))}
      </ul>
    );
  };

  const renderBlocks = () => {
    return menuItems.blocks.map((block, index) => {
      switch (block.type) {
        case "links":
          if (Array.isArray(block.links)) {
            return (
              <MegamenuLinks
                key={index}
                linksTitle={block.links_title}
                subItem={block.links}
                onLinkHover={handleLinkHover}
              />
            );
          } else {
            console.error(
              `Expected block.settings.links to be an array, but got ${typeof block.links}`
            );
            return null;
          }
        case "product_list":
          return (
            <ExtendedMegamenuProductList
              key={index}
              block={block}
              index={index}
            />
          );
        case "collection_list":
          return (
            <ExtendedMegamenuCollectionList
              key={index}
              block={block}
              index={index}
            />
          );
        default:
          return null;
      }
    });
  };

  if (state !== id && !anim) {
    return null;
  }

  return (
    <MegamenuLayout
      settings={settings}
      menuItems={menuItems}
      id={id}
      isMobile={isMobile}
      state={state}
      anim={anim}
      animClass={animClass}
      onAnimationEnd={onAnimationEnd}
      hoveredLink={hoveredLink}
      onLinkHover={handleLinkHover}
      renderBlocks={renderBlocks}
      mobileMenuVisible={mobileMenuVisible}
      handleMobileMenuClick={handleMobileMenuClick}
      handleBackClick={handleBackClick}
      activeMenuItem={activeMenuItem}
      renderSublinks={renderSublinks}
      isMainMenu={isMainMenu}
    />
  );
};

const MegamenuLayout = ({
  settings,
  menuItems,
  id,
  isMobile,
  state,
  anim,
  animClass,
  onAnimationEnd,
  hoveredLink,
  onLinkHover,
  renderBlocks,
  mobileMenuVisible,
  handleMobileMenuClick,
  handleBackClick,
  activeMenuItem,
  renderSublinks,
  isMainMenu,
}) => {
  const [menuState, setMenuState] = useState({
    menuAnimClass: "",
    firstLevelActive: false,
    secondLevelActive: false,
    navigationTitle: "",
    firstMenuTitle: "",
    transitionClass: "",
    primaryLinksAnim: "",
    isSecondLevelVisible: false,
  });

  const updateMenuState = (updates) =>
    setMenuState((prevState) => ({ ...prevState, ...updates }));

  const handleMenuClose = () => {
    updateMenuState({
      primaryLinksAnim: "slide-out-to-right",
      menuAnimClass: "slide-out-left",
      firstLevelActive: false,
      secondLevelActive: false,
      isSecondLevelVisible: false,
    });
  };

  useEffect(() => {
    const handleMobileMenuClosed = () => handleMenuClose();
    window.addEventListener("mobile-menu-closed", handleMobileMenuClosed);

    if (isMobile && state === id) {
      onLinkHover(null);
      const newTitle = id.replace("header-", "");
      updateMenuState({
        navigationTitle: newTitle,
        firstMenuTitle: newTitle,
        firstLevelActive: true,
        menuAnimClass: "slide-in-from-right",
        primaryLinksAnim: "slide-in-from-right",
        transitionClass: "",
      });
    }

    return () => {
      window.removeEventListener("mobile-menu-closed", handleMobileMenuClosed);
    };
  }, [isMobile, state, id]);

  useEffect(() => {
    if (!isMobile) {
      onLinkHover(menuItems.subMenuItems[0]?.links[0], true);
    }
  }, [menuState]);

  return (
    <nav className={`megamenu-nav ${isMobile ? 'megamenu-nav--mobile' : ''}`} onAnimationEnd={onAnimationEnd}>
      <div className={`${clsx({ container: !isMobile })}`}>
        <div className={`megamenu-nav__inner ${isMobile ? '!block px-3 h-screen overflow-y-auto pb-20' : ''}`}>
          {isMobile ? (
            <div>
              <button
                className="justify-start gap-4 px-0 text-base font-semibold normal-case font-body text-primary"
                onClick={handleBackClick}
              >
                <i className="text-xs icon-chevron-left"></i>
                Back to Menu
              </button>
              <ExtendedHeadings
                text={settings.section_settings.attach_to}
                type="h4"
                classes="!text-xl/6 font-semibold mb-3"
              />
              <ul className="flex-grow -mx-3 mobile-menu">
                {Array.isArray(settings.mobileLinks[0].links) &&
                  settings.mobileLinks[0].links.map((item, index) => (
                    <li
                      key={index}
                      className="px-3 mobile-menu__item"
                      style={{
                        backgroundColor: activeMenuItem?.[item.title] ? settings.section_settings.mobile_nav_color : 'transparent',
                      }}
                    >
                      <div
                        onClick={() => handleMobileMenuClick(item)}
                        className="flex items-center justify-between w-full py-3"
                      >
                        <span>{item.title}</span>
                        {item.links && item.links.length > 0 && (
                          <i
                            className={`text-xs ${
                              activeMenuItem?.[item.title] ? "rotate-0" : "rotate-180"
                            } icon-chevron-up`}
                          ></i>
                        )}
                      </div>
                      {activeMenuItem?.[item.title] && renderSublinks(item.links, 1)}
                    </li>
                  ))}
              </ul>
            </div>
          ) : (
            <div className={`megamenu-nav__columns-wrapper ${anim ? animClass : ''}`}>
              {renderBlocks()}
            </div>
          )}
        </div>
      </div>
    </nav>
  );
};

export default ExtendedMegamenu;