import * as React from "react";
import { useEffect, useRef } from "react";
import Flickity from 'flickity';
import useMobileBreakpoint from "@src/hooks/useMobileBreakpoint";
import { mobileBreakpoint } from "../../../../entrypoints/theme";
import "flickity/css/flickity.css";
import "./SubCategories.scss";

const SubCategories = ({ additional }) => {
  const isMobile = useMobileBreakpoint(mobileBreakpoint);
  const carouselRef = useRef([]);

  let parsedAdditional;
  try {
    parsedAdditional = additional && typeof additional === "string" && additional.trim() !== "" ? JSON.parse(additional) : additional;
  } catch (error) {
    console.error("Error parsing additional data:", error);
    parsedAdditional = null;
  }

  useEffect(() => {
    if (carouselRef.current && parsedAdditional?.sub_categories?.length > 0) {
      const flkty = new Flickity(carouselRef.current, {
        wrapAround: true,
        pageDots: false,
        prevNextButtons: !isMobile,
        contain: true,
        cellAlign: 'left',
        adaptiveHeight: false,
        freeScroll: true,
      });

      flkty.resize();

      return () => {
        flkty.destroy();
      };
    }
  }, [isMobile, parsedAdditional]);

  if (!parsedAdditional || !parsedAdditional.sub_categories || parsedAdditional.sub_categories.length === 0) {
    return null;
  }

  return (
    <div className="sub-categories">
      <div
        ref={carouselRef}
        className="w-full max-w-full carousel slide-fade-in-element"
      >
        {parsedAdditional.sub_categories.map((subcategory, index) => (
          <div
            className="carousel-cell flex flex-col items-center mr-3 w-7/12 md:w-5/12 xl:w-[28%]" 
            key={index}
          >
            <div>
              <a
                href={subcategory.url}
                aria-label={subcategory.title}
              >
                {subcategory.image ? (
                  <img
                    src={`${subcategory.image}`}
                    alt={subcategory.title}
                    className="object-cover w-full h-full"
                  />
                ) : (
                  <div className="image-placeholder"></div>
                )}
              </a>
              <p className="py-3 text-base font-semibold font-body text-primary">
                <a href={subcategory.url}>
                  {subcategory.title}
                </a>
              </p>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default SubCategories;