import React, { useState } from "react";
import StackedLayout from "./Layouts/Stacked";
import TwoColumnLayout from "./Layouts/TwoColumn";
import useMobileBreakpoint from "@src/hooks/useMobileBreakpoint";

const layouts = {
  stacked: StackedLayout,
  "2column": TwoColumnLayout,
};

const FAQProduct = ({ settings, blocks, props, faqs }) => {
  const isMobile = useMobileBreakpoint(settings.mobile_breakpoint);
  let Layout = layouts[settings.layout_type] || StackedLayout;

  let sortedBlocks = blocks;

  if (settings.metaobject_entries) {
    const faqMetaobjectItems = props.faqs;
    const transformedBlocks = faqMetaobjectItems.map((item) => ({
      type: "column_block",
      settings: {
        title: item.title,
        filter_tag: item.filter_tag,
        content: item.content,
        image: item.image,
      },
    }));
    sortedBlocks = transformedBlocks;
  } else if (faqs && faqs.length > 0) {
    sortedBlocks = faqs.map((faq) => ({
      type: "column_block",
      settings: {
        title: faq.question,
        content: faq.content,
      },
    }));
  } else {
    if (isMobile) {
      // Sort blocks based on the sort_order setting
      sortedBlocks = [...blocks].sort(
        (a, b) => a.settings.sort_order_mobile - b.settings.sort_order_mobile,
      );
    }
  }

  if (isMobile) {
    Layout = layouts[settings.layout_type_mobile] || StackedLayout;
  }

  return (
    <div
      className={`${settings.reveal_animation_class} relative`}
      style={{
        paddingTop: `${settings.padding_top}px`,
        paddingBottom: `${settings.padding_bottom}px`,
      }}
    >
      <div className="relative items-end justify-end w-full h-full">
        <Layout blocks={sortedBlocks} settings={settings} isMobile={isMobile} />
      </div>
    </div>
  );
};

export default FAQProduct;