import * as React from "react";
import { Button } from "../../elements/Button";
import { Paragraph } from "@project-stories/elements/Paragraph";

const TileContent = ({ data, isMobile }) => {
    const buttonWidth = isMobile ? data.settings.button_width_mobile : data.settings.button_width;

    return (
        <>
            {data.settings?.tagline && (
                <Paragraph
                    text={data.settings?.tagline}
                    type={`flex justify-${data.settings.title_alignment_mobile}
                     lg:justify-${data.settings.title_alignment_desktop}
                     ${isMobile ? `b4__regular` : `b3__regular`}`}
                />
            )}

            {data.settings?.title && !data.settings?.use_counter && (
                <Paragraph
                    text={data.settings?.title}
                    type={
                        `flex justify-${data.settings.title_alignment_mobile}
                        lg:justify-${data.settings.title_alignment_desktop}
                        text-${data.settings.content_alignment_mobile}
                        lg:text-${data.settings.content_alignment_desktop}
                ${isMobile
                            ? data.settings.title_font_type_mobile
                            : data.settings.title_font_type}
                `
                    }
                />
            )}

            {data.settings?.title && data.settings?.use_counter && (
                <>
                    <div
                        x-data={`textcounter({ targetNumber: ${parseFloat(
                            data.settings?.title
                                .replace(/,/g, "")
                                .replace(/<\/?[^>]+(>|$)/g, ""),
                        )}, duration: 5000 })`}
                        x-ref="counterElement"
                    >
                        <p
                            x-text="formattedNumber"
                            className={
                                isMobile
                                    ? data.settings.title_font_type_mobile
                                    : data.settings.title_font_type
                            }
                        ></p>
                    </div>
                </>
            )}

            {data.settings?.content && (
                <Paragraph
                    text={data.settings?.content}
                    type={`
                flex
                justify-${data.settings.text_content_alignment_mobile}
                lg:justify-${data.settings.text_content_alignment_desktop}
                ${isMobile ? data.settings.content_font_type_mobile : data.settings.content_font_type} 
                space-y-[${data.settings.content_gap}px]
              `}
                />
            )}
            {data.settings.show_button && (
                <div className={`flex
                    justify-${data.settings.button_alignment_mobile}
                    lg:justify-${data.settings.button_alignment_desktop}`}
                >

                    <Button
                        className={`${data.settings.button_class}`}
                        text={true}
                        label={data.settings.button_text}
                        icon={`${data.settings.button_icon}`}
                        link={data.settings.button_url}
                        customStyle={buttonWidth > 0 ? { width: buttonWidth } : undefined}

                        onClick={undefined}
                    />
                </div>
            )}
        </>
    );
};

export default TileContent;
