import * as React from "react";
import TileImage from "@src/stories/components/Tile/TileImage";
import TileContent from "@src/stories/components/Tile/TileContent";
import {getPadding, getTileClasses} from "@src/stories/components/Tile/TileMethods";

const Overlayed = ({ data, height, settings,  isMobile }) => {
    const paddingContent = getPadding(data);
    const tileClasses = getTileClasses(data, isMobile, paddingContent);

    const tileContent = (
        <div className={`tileitem color-${data.color_schema} ${data.settings.padding_area == "outside" && paddingContent}`}>
            <TileImage data={data} height={height} isMobile={isMobile} />
            <div className={`tile__info ${data.settings.image ? "flex absolute w-full z-[5]" : ""} ${tileClasses}`}>
                <TileContent data={data} isMobile={isMobile} />
            </div>
        </div>
    );

    return (
        <>
            {data.settings.make_tile_clickable ? (
                <div
                    onClick={() => (window.location.href = data.settings.button_url)}
                    className="custom-class"
                    style={{ cursor: "pointer" }}
                >
                    {tileContent}
                </div>
            ) : (
                tileContent
            )}
        </>
    );
};

export default Overlayed;
