import * as React from "react";

export const AccountPopup = ({ settings }) => {


    const renderComponents = () => {
        return (
            <div className="account-popup relative container">
                <div className="account-popup-details absolute right-0 w-full lg:w-auto" x-data="toggledisplay"
                     x-init="$nextTick(() => { init('header-account') })">
                    <div
                        className="flex flex-col w-full lg:w-[371px] h-[288px] p-[24px] justify-center items-center bg-white z-50 gap-[16px] text-center"
                        x-show="is_open">
                        <div className="flex w-full">
                            <a href="/account/login" className="button btn-outline w-full">SIGN IN</a>
                        </div>
                        <h5 className="">Create your account today</h5>
                        <p className="b3">Sign up now and you'll save $20.00 on your first month</p>
                        <div className="flex w-full">
                            <a href="/account/register" className="button btn-primary w-full">CREATE ACCOUNT</a>
                        </div>
                    </div>
                </div>
            </div>
        )
    }

    return (
        <>
            {renderComponents()}
        </>
    );
}

export default AccountPopup;