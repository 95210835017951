import React, { useState } from "react";

const SingleColumnLayout = ({ settings }) => {
  const { blocks, url } = settings;

  const [selectedTags, setSelectedTags] = useState([]);
  const show_all = false;
  const handleTagClick = (title) => {
    if (selectedTags.includes(title)) {
      setSelectedTags(selectedTags.filter((tag) => tag !== title));
    } else {
      setSelectedTags([title]);
    }
  };

  return (
    <div x-data="blogfilters">
      <div className="blog-filters overflow-hidden overflow-x-auto no-scrollbar">
        <ul className="flex lg:justify-center mt-4">
          {show_all && (
            <li
              className={`blog-filter b2 px-[32px] py-[8px] cursor-pointer ${selectedTags.length === 0 && "selected"}`}
              onClick={() => setSelectedTags([])}
            >
              <div data-id={url}>All</div>
            </li>
          )}
          {blocks.map((block, index) => (
            <li
              key={index}
              className={`blog-filter b2 px-[32px] py-[8px] cursor-pointer ${selectedTags.includes(block.settings.title) && "selected"}`}
              onClick={() => handleTagClick(block.settings.title)}
            >
              <div data-id={block.settings.url}>{block.settings.title}</div>
            </li>
          ))}
        </ul>
      </div>
    </div>
  );
};

export default SingleColumnLayout;
