import React from "react";
import "./FAQTabs.scss";
import {SelectField} from "@project-stories/elements/Select/SelectField";
import useMobileBreakpoint from "@src/hooks/useMobileBreakpoint";

export const FAQTabs = ({ tabs, activeTab, onTabChange, settings }) => {
  const isMobile = useMobileBreakpoint(settings.mobile_breakpoint);

  if (isMobile) {
    return (
      <div className="w-full faq-tabs">
        <SelectField options={tabs} placeholder="SelectField one..." />
      </div>
    );
  }

  return (
    <div className="flex w-full faq-tabs">
      {tabs.map((tab) => (
        <div
          key={tab.value}
          className={`tab-button basis-[30%] ${activeTab === tab.value ? "active" : ""}`}
          onClick={() => onTabChange(tab.value)}
        >
          {tab.label}
        </div>
      ))}
    </div>
  );
};

