import React from "react";

export const CartTitle = ({ item_count }) => {
  const showAccount = false;

  return (
    <div className="relative px-4 pt-4 title-row lg:px-8 lg:pt-3 lg:pb-0">
      <div
        id="minicart-label"
        className="text-xl font-semibold text-primary font-body border-[#748b8533] border-b w-full pb-3 lg:text-2xl">
          Your Bag ( {item_count} )
      </div>

      <div className="absolute right-8 top-4 ">
        {showAccount && (
          <a href="/account" className="antialiased [&>svg]:h-[28px]">
            <i className="icon icon-account"></i>
          </a>
        )}

        <div
          className="cursor-pointer"
          x-on:click="$store.global.toggleMinicart()"
        >
          <i className="icon icon-close hover:text-highlight"></i>
        </div>
      </div>
    </div>
  );
};
