import * as React from "react";
import { Button } from "@mindarc/arctheme-components/frontend/src/stories/components/elements/Button/Button";
import useMobileBreakpoint from "@src/hooks/useMobileBreakpoint";
import { mobileBreakpoint } from "../../../../entrypoints/theme";
import ExtendedImageComponent from "../../elements/ExtendedImage/ExtendedImage";

const ExtendedTile = ({ data }) => {
  const isMobile = useMobileBreakpoint(mobileBreakpoint);

  const isAdditionalData = data.hasOwnProperty('image') && data.hasOwnProperty('title');
  let imageUrl = isAdditionalData ? data.image : data.settings.image?.src ?? data.settings.image;

  if (isMobile) {
    imageUrl = isAdditionalData
      ? data.image
      : data.settings.image_mobile
      ? data.settings.image_mobile
      : data.settings.image?.src ?? data.settings.image;
  }

  return (
    <div>
      {imageUrl && (
        <div className="mb-3 overflow-hidden">
          <ExtendedImageComponent
            imageUrl={imageUrl}
            alt={isAdditionalData ? data.title : data.settings.title}
            classes={"object-cover w-full"}
          />
        </div>
      )}

      <div className="text-left">
        {isAdditionalData ? (
          <label className="block mb-3 text-xl font-semibold font-body text-primary lg:text-xl">
            {data.title}
          </label>
        ) : (
          <>
            {data.settings?.title && data.settings.button_url ? (
              <a
                href={data.settings.button_url}
                className="hover:no-underline"
              >
                <label className="block mb-3 text-xl font-semibold cursor-pointer font-body text-primary lg:text-xl">
                  {data.settings.title}
                </label>
              </a>
            ) : (
              <label className="block mb-3 text-xl font-semibold font-body text-primary lg:text-xl">
                {data.settings.title}
              </label>
            )}
          </>
        )}

        {isAdditionalData ? (
          <p
            className="text-base text-primary font-body lg:pr-[26px]"
            dangerouslySetInnerHTML={{ __html: data.details }}
          ></p>
        ) : (
          data.settings?.content && (
            <p
              className="text-base text-primary font-body lg:pr-[26px]"
              dangerouslySetInnerHTML={{ __html: data.settings.content }}
            ></p>
          )
        )}

        {!isAdditionalData && data.settings.show_button && (
          <a 
            href={data.settings.button_url} 
            className={`${data.settings.button_style} mt-3 inline-flex items-center justify-center px-4 py-2 border border-transparent text-base/5 uppercase font-body font-semibold hover:no-underline`}
          >
            {data.settings.button_text}
          </a>
        )}
      </div>
    </div>
  );
};

export default ExtendedTile;