import React, {useState} from "react";
import {Video} from "@project-stories/elements/Video/Video";
import ExtendedImage from "../../elements/ExtendedImage/ExtendedImage";

export const Media = ({videoItem, imageItem , index}) => {
    if (videoItem) {
        return (<Video key={`accordion__content-column-${index}`} videoUrl={videoItem.sources[0].url} autoplay={false}/>)
    }

    else if (imageItem) {
        return ( <ExtendedImage imageUrl={imageItem} />)
    }
    return null
}
