import * as React from "react";
import useMobileBreakpoint from "@src/hooks/useMobileBreakpoint";
import AlpineWidget from "@mindarc/arctheme-components/frontend/src/stories/components/AlpineWidget";

const HeaderActions = ({ item_count, settings }) => {
  const isMobile = useMobileBreakpoint("1024px");
  const isCustomerLoggedIn = settings.globals.is_customer_logged_in;

  return (
    <div className="header__toolbar">
      <AlpineWidget
        template={`
          <div
              class="antialiased cursor-pointer [&>svg]:h-[28px] md:block"
              @click="$store.global.isPredictiveSearchVisible = true; $nextTick(() => { document.querySelector('[data-search-input]').focus(); })"
              :aria-expanded="$store.global.isPredictiveSearchVisible ? 'true' : 'false'"
          >
            <i class="icon-search text-3xl"></i>
          </div>
        `}
      />

      {settings.globals.enable_wishlist === "true" && (
        <a
          href="/pages/wishlist"
          className="antialiased [&>svg]:h-[28px]"
        >
          <i className="text-3xl icon-wishlist"></i>
        </a>
      )}

      {isCustomerLoggedIn && (
        <a
          href="/account"
          className="account-icon antialiased [&>svg]:h-[28px] no-underline"
        >
          <i className="text-3xl icon-account"></i>
        </a>
      )}

      {!isCustomerLoggedIn &&
        settings.section.use_account_popup &&
        settings.section.account_popup_trigger == "hover" && (
          <AlpineWidget
            template={`
              <div href="#" class="account-icon antialiased [&>svg]:h-[28px]" @mouseenter="$nextTick(() => { $dispatch('toggle-display-open',{ message: 'header-account' }) })" @mouseleave="$nextTick(() => { $dispatch('toggle-display-close',{ message: 'header-account' }) })">
                <i class="icon-account text-3xl"></i>
              </div>`}
          />
        )}

      {!isCustomerLoggedIn &&
        settings.section.use_account_popup &&
        settings.section.account_popup_trigger == "click" && (
          <AlpineWidget
            template={`
              <div href="#" class="account-icon antialiased [&>svg]:h-[28px]" @mousedown="$nextTick(() => { $dispatch('toggle-display',{ message: 'header-account' }) })">
                <i class="icon-account text-3xl"></i>
              </div>`}
          />
        )}

      {!isCustomerLoggedIn &&
        !settings.section.use_account_popup && (
          <AlpineWidget
            template={`
              <a href="/account" class="account-icon antialiased [&>svg]:h-[28px]">
                <i class="icon-account text-3xl"></i>
              </a>`}
          />
        )}

      <AlpineWidget
        template={`
          <div class="relative [&>svg]:h-[28px] md:block cursor-pointer" @click="$store.global.isMinicartVisible = !$store.global.isMinicartVisible">
            <i class="icon-bag text-3xl"></i>
            ${item_count > 0 ? `
              <div
                class="absolute top-0 w-5 h-5 px-1 text-center rounded-full header-item-count bg-highlight text-secondary -right-1 cursor-pointer"
                @click.stop="$store.global.isMinicartVisible = !$store.global.isMinicartVisible"
              >
                <span class="flex justify-center text-sm text-secondary font-body" data-ajax-cart-bind="item_count">
                  ${item_count}
                </span>
              </div>
            ` : ''}
          </div>
        `}
      />
    </div>
  );
};

export default HeaderActions;