import * as React from "react";

import ImageComponent from "@mindarc/arctheme-components/frontend/src/stories/components/elements/Image/Image";
import useMobileBreakpoint from "@src/hooks/useMobileBreakpoint";
import {mobileBreakpoint} from "../../../../entrypoints/theme";

export const Logo = ({ settings }) => {
    const isMobile = useMobileBreakpoint(mobileBreakpoint);

    const renderComponents = () => {
        return (
            <a
                className={`mx-auto block mb-[${settings.block_settings.margin_bottom_mobile}px] lg:mb-[${settings.block_settings.margin_bottom_desktop}px]`}
                href={settings.routes.root_url}
                title={settings.shop.name}
                style={{width: `${settings.block_settings.image_width}px`}}
            >
                <ImageComponent imageUrl={settings.block_settings.image} />
            </a>
        )
    }

    if (settings.block_settings.render_type !== 'all') {
        if (isMobile && settings.block_settings.render_type !== 'mobile-only') return;
        if (!isMobile && settings.block_settings.render_type !== 'desktop-only') return;
    }

    return (
        <>
            {renderComponents()}
        </>
    );
}

export default Logo;