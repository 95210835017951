let filters = {};

export const searchproductlist = {
    name: 'searchproductlist',
    component() {
        return {
            collection: null,
            isCollection: null,
            filters: {},
            filterString: null,
            products: [],
            totalResults: null,
            currentResults: null,
            page: 0,
            isInit: false,
            init(collectionHandle = null, isCollection) {
                if (!this.isInit) {
                    if (isCollection) {
                        console.log('Arctheme PLP/Search Component Initialized.');
                        this.collection = collectionHandle;
                        this.isCollection = isCollection;
                        // console.log('Sending request to get products from search');
                        window.dispatchEvent(new CustomEvent('searchrequest', {
                            detail: {
                                type: 'plp',
                                filters: {
                                    collection: this.collection
                                }
                            }
                        }));

                        window.addEventListener('searchrequest-products-complete', this.handleSearchComplete.bind(this));

                        window.addEventListener('nextpage', this.handleNextPage.bind(this));

                        this.isInit = true;
                    }
                }
            },
            subscribe(callback) {
                window.addEventListener('products-results', callback);
                return () => {
                    window.removeEventListener('products-results', callback);
                };
            },
            getSearchedProducts(event) {
                // console.log('event', event);
                // return searchedProducts;
            },
            query(input) {
                const lastQuery = localStorage.getItem('queryInput');

                if (lastQuery) {
                    // console.log(`Last Query ${lastQuery}`)
                    const queryObj = {
                        'query': lastQuery,
                        'type': 'search'
                    }
                    dispatchEvent(new CustomEvent('searchrequest', { detail: queryObj }))
                } else {
                    const queryObj = {
                        'query': input,
                        'type': 'search'
                    }
                    dispatchEvent(new CustomEvent('searchrequest', { detail: queryObj }))
                }
            },
            filter(filterKey, valueKey) {
                if (filters[filterKey]) {
                    if (filters[filterKey].includes(valueKey)) {
                        // Remove the filter if it already exists
                        filters[filterKey] = filters[filterKey].filter(value => value !== valueKey);
                    } else {
                        // Add the filter if it doesn't exist
                        filters[filterKey].push(valueKey);
                    }
                } else {
                    // Initialize the filter if it doesn't exist
                    filters[filterKey] = [valueKey];
                }

                this.filterString = Object.keys(filters)
                    .map(key => filters[key].map(value => `${key}:${value}`).join(' AND '))
                    .filter(Boolean)
                    .join(' AND ');

                window.dispatchEvent(new CustomEvent('searchrequest-filter-applied', {
                    detail: {
                        type: 'plp',
                        filters: this.filterString ? `collections:${this.collection} AND ${this.filterString}` : `collections:${this.collection}`
                    }
                }));

                this.currentResults = null;
                this.page = 0;
            },
            handleSearchComplete(event) {
                if (this.page > 0) {
                    this.products = [
                        ...this.currentResults,
                        ...event.detail.products
                    ]
                    this.currentResults = this.products;
                } else {
                    this.products = event.detail.products;
                    this.currentResults = event.detail.products;
                }
                this.filters = event.detail.filters.reduce((acc, filter) => {
                    acc[filter.key] = [];
                    return acc;
                }, {});
                window.dispatchEvent(new CustomEvent('product-list', {
                    detail: {
                        type: 'plp',
                        products: this.products,
                        filters: this.filters
                    }
                }));
                this.totalResults = event.detail.totalResults;
            },
            handleNextPage(event) {
                this.page = event.detail.page;
                if (this.isCollection) {
                    window.dispatchEvent(new CustomEvent('searchrequest-nextpage', {
                        detail: {
                            type: 'plp',
                            filters: this.filterString ? `collections:${this.collection} AND ${this.filterString}` : `collections:${this.collection}`,
                            page: this.page
                        }
                    }));
                } else {
                    const lastQuery = localStorage.getItem('queryInput');

                    if (lastQuery) {
                        const queryObj = {
                            'query': lastQuery,
                            'type': 'search',
                            'page': this.page
                        }
                        dispatchEvent(new CustomEvent('searchrequest-nextpage', { detail: queryObj }))
                    }
                }
            },
            setCollection() {

            },
            setFilters() {

            },
            setSort() {

            },
            clear() {
                filters = {};
                let filterString = '';

                this.currentResults = null;
                this.page = 0;

                window.dispatchEvent(new CustomEvent('searchrequest-filter-applied', {
                    detail: {
                        type: 'plp',
                        filters: filterString ? `collections:${this.collection} AND ${filterString}` : `collections:${this.collection}`
                    }
                }));

                const checkboxes = document.querySelectorAll('.collection-sidebar input[type="checkbox"]');
                checkboxes.forEach(checkbox => {
                    checkbox.checked = false;
                });
            }
        }
    }
}

export default searchproductlist;
