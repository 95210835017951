import React, { useState } from "react";
import { Paragraph } from "@arctheme-components/elements/Paragraph/Paragraph";
import { Button } from "@project-stories/elements/Button";
import { Headings } from "@arctheme-components/elements/Headings/Headings";
import useMobileBreakpoint from "@src/hooks/useMobileBreakpoint";
import { mobileBreakpoint } from "../../../../../entrypoints/theme";
import { clsx } from "clsx";
import { default as Image } from "@arctheme-components/elements/Image/Image";

const OverlayedLayout = ({ reverselayout, description, settings, blocks }) => {
  const isMobile = useMobileBreakpoint(mobileBreakpoint);

  let image = isMobile
    ? settings.image_mobile?.src ||
      settings.image_mobile ||
      settings.image?.src ||
      settings.image
    : settings.image?.src || settings.image;

  let video = isMobile
    ? settings.video_mobile?.sources?.[0]?.url ??
      settings.video_mobile ??
      settings.video?.sources?.[0]?.url ??
      settings.video
    : settings.video?.sources?.[0]?.url ?? settings.video;

  let videoPoster = settings.video?.preview_image.src;
  let heading = settings.title;
  let type = "twi-twocolumn";
  const imageWidth = isMobile ? settings.width_mobile : settings.width;

  if (settings) {
    if (settings.content) {
      description = settings.content;
    }
    if (settings.reverse_layout) {
      reverselayout = settings.reverse_layout;
    }

    if (settings.classes) {
      type = settings.classes;
    }
  }

  if (settings.render_type !== "all") {
    if (isMobile && settings.render_type !== "mobile-only") return null;
    if (!isMobile && settings.render_type !== "desktop-only") return null;
  }

  return (
    <div className={`${type}`}>
      <div className={`image-container overlay content-over-media`}>
        {image ? (
          <Image imageUrl={image} classes={"object-cover h-full w-full"} />
        ) : video ? (
          <video
            className={"object-cover h-full w-full"}
            autoPlay
            loop
            muted
            poster={videoPoster}
            preload="metadata"
            playsInline
          >
            <source src={video} type="video/mp4"></source>
          </video>
        ) : null}
        <div className={`twi-container`}>
          <div className="twi-content">
            {settings.tagline && (
              <Paragraph text={settings.tagline} type={"bc"} />
            )}
            {heading && (
              <Headings
                text={heading}
                type={
                  isMobile
                    ? settings.heading_type_mobile
                    : settings.heading_type
                }
              />
            )}
            <div>
              {settings.subheading && (
                <Paragraph
                  text={settings.subheading}
                  type={`${isMobile ? settings.subheading_style_mobile : settings.subheading_style}`}
                />
              )}
              {description && (
                <Paragraph
                  text={description}
                  type={`${isMobile ? settings.content_style_mobile : settings.content_style} py-[16px]`}
                />
              )}
            </div>
            <div className={"flex"}>
              {blocks.map((block, index) => (
                <div key={block.key || index} className={`pt-[${block.settings.padding_top_mobile}px] lg:pt-[${block.settings.padding_top_desktop}px]`}>
                  {block.settings.button_text && (
                    <Button
                      style={block.settings.button_style}
                      label={block.settings.button_text}
                      link={block.settings.button_url}
                      icon={block.settings.button_icon}
                      onClick={undefined}
                    />
                  )}
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default OverlayedLayout;
