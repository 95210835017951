import React from "react";
import useMobileBreakpoint from "@src/hooks/useMobileBreakpoint";

export const Table = ({ settings }) => {
  const { section, blocks } = settings;
  const isMobile = useMobileBreakpoint(section.mobile_breakpoint);

  const headerLabelClass = isMobile ? "sh5" : "sh3";
  const headerLinkClass = isMobile ? "b2" : "b1";
  const rowHeaderClass = isMobile ? "sh5" : "sh3";
  const rowLinkClass = isMobile ? "b2" : "b1";

  return (
    <div>
      {section.show_table_headers && (
        <div className="table__header flex justify-between items-center py-[20px] border-b border-black">
          <div className={`table__header-label ${headerLabelClass}`}>
            {section.table_header_label}
          </div>
          <div className={`table__header-link ${headerLinkClass}`}>
            {section.table_header_link}
          </div>
        </div>
      )}

      {blocks.map((block, index) => (
        <div
          key={index}
          className="flex justify-between items-center py-[20px] border-b border-black"
        >
          <div className={`table__row-header ${rowHeaderClass}`}>
            {block.settings.row_label}
          </div>
          <div className={`table__row-link ${rowLinkClass}`}>
            <a href={block.settings.row_link_url}>
              {block.settings.row_link_label}
            </a>
          </div>
        </div>
      ))}
    </div>
  );
};
