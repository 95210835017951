import helpers from "./helpers";

export default {
  getPublicAccessToken() {
    return "<Enter Access Token Here>";
  },
  getStorefrontToken() {
    if (Shopify.shop === "ergopouchau-dev.myshopify.com") {
      return "e22d5792936ced243cea68a0586b2599";
    }
    if (Shopify.shop === "ergopouch-us.myshopify.com") {
      return "3e682584bd8859c469cbb987be55ec19";
    }
    if (Shopify.shop === "ergopouch-uk.myshopify.com") {
      return "4993ae9cfebd2ba2f4ea9ee8aa63917a";
    }
    return 'e7591b64db1c504bcfc0930e3887bf97';
  },
};
