import React, { useEffect, useState } from "react";
import { EcommProductPrice } from "@mindarc/arctheme-components/frontend/src/stories/components/ecommerce/Price/Price";
import ExtendedImage from "../../elements/ExtendedImage/ExtendedImage";
import helpers from '../../../../js/helpers';
import './ProductTile.scss';

declare const window: Window &
  typeof globalThis & {
    theme: {
      productBadges: any;
    };
  };

interface Swatch {
  color: string;
  image?: string;
  url: string;
}

export const ExtendedProductTile = ({
  product,
  badges,
  tog,
  product_swatches,
  show_price_inline = false,
  source = 'shopify',
  image_placeholder = null,
  view = 'product'
}) => {

  const [isHovered, setIsHovered] = useState(false);
  let imageIndex = 0;
  let hoverIndex = 1;

  const secondImageIsSwatch =
    product?.images?.[1]?.src?.includes("_s") ||
    product?.images?.[1]?.src?.includes("_s_");

  if (view === 'outfit') {
    imageIndex = 1;
    hoverIndex = 0;
  }  

  if (product.media) {
    product.images = product.media;
  }

  const getImageUrl = (image) => {
    if (typeof image === "object" && image !== null && image.src) {
      return image.src;
    }
    return image;
  };

  // Product tags
  const createKeyValuePairs = (tags) => {
    const tagsArray = Array.isArray(tags) ? tags : tags ? [tags] : [];
    return tagsArray.reduce((acc, tag) => {
      if (typeof tag === 'string' && tag.includes('|')) {
        const [key, value] = tag.split("|");
        if (value) {
          acc[key] = acc[key] ? [...(Array.isArray(acc[key]) ? acc[key] : [acc[key]]), value] : value;
        }
      }
      return acc;
    }, {});
  };

  const productTags = createKeyValuePairs(product.tags);
  const promotionalBadge = productTags.promotional_badge ? [].concat(productTags.promotional_badge) : [];
  
  const hasBabyBountyAward = product.tags?.includes('2023 bounty baby awards') || false;

  const getBadgeColor = (badgeText) => {
    if (typeof badgeText === 'string') {
      let normalizedBadgeText = badgeText.toLowerCase().replace(/[^a-z0-9]/g, '_');

      if (normalizedBadgeText.includes('save')) {
        normalizedBadgeText = 'save';
      }

      const colorKey = `${normalizedBadgeText}_badge_color`;
      const color = window.theme.productBadges[colorKey];
      return color || '#323D3E';
    }
    return '#323D3E';
  };

  // Product Swatches
  let siblingSwatches: Swatch[] = [];

  if (source === 'shopify') {
    siblingSwatches = JSON.parse(product_swatches) as Swatch[];
  } else {
    siblingSwatches = product_swatches as Swatch[];
  }

  let additionalSwatchesCount = 0
  const maxVisibleSwatches = 4;

  if (siblingSwatches) {
    additionalSwatchesCount = siblingSwatches.length > maxVisibleSwatches ? siblingSwatches.length - maxVisibleSwatches : 0;
  }

  // Product Price
  const price = product.price;
  const compareAtPrice = product.compare_at_price;
  const formattedPrice = source === 'shopify' ? `$${price}` : helpers.formatPrice(price);
  const formattedCompareAtPrice = source === 'shopify' ? `$${compareAtPrice}` : helpers.formatPrice(compareAtPrice);

  const renderBadges = () => (
    <div className="product-card__badge-list">
      {promotionalBadge.length > 0 && promotionalBadge.map((badgeText, index) => {
        const badgeColor = getBadgeColor(badgeText);
        return (
          <span
            key={index}
            className="badge"
            style={{ backgroundColor: badgeColor }}
          >
            {badgeText}
          </span>
        );
      })}
    </div>
  );

  const renderImage = () => {
    const images = product.images || [];
    const imageToRender = isHovered && !secondImageIsSwatch 
      ? images[hoverIndex] 
      : images[imageIndex];
  
    return (
      <div className="product-card__figure">
        <a
          href={`/products/${product.handle}`}
          className="relative block pb-[120%]"
          onMouseEnter={() => setIsHovered(true)}
          onMouseLeave={() => setIsHovered(false)}
        >
          {imageToRender ? (
              <ExtendedImage
                classes="product-card__image"
                imageUrl={getImageUrl(imageToRender)}
                alt={product.title}
                isLazy={true}
              />
            ) : (
              image_placeholder && (
                <div className="absolute top-0 left-0 w-full h-full" dangerouslySetInnerHTML={{ __html: image_placeholder }} />
              )
            )
          }
          {hasBabyBountyAward && badges.bounty_baby_awards && (
            <ExtendedImage
              imageUrl={badges.bounty_baby_awards}
              alt="2023 Baby Bounty Awards"
              isLazy={true}
              classes="absolute bottom-1 right-1 w-11 lg:w-[57px] lg:bottom-2.5 lg:right-2.5"
            />
          )}
        </a>
      </div>
    );
  };

  const renderTogBadges = () => {
    return (
      <div className="flex gap-1 lg:gap-2 min-h-[24px]">
        {tog && tog.length > 0 ? (
          tog.map((item, index) => (
            <span
              key={index}
              className="badge badge--tog"
              style={{ backgroundColor: item.tog_colour }}
            >
              {item.tog_title}
            </span>
          ))
        ) : (
          <span className="invisible badge badge--tog"> </span>
        )}
      </div>
    );
  };

  const renderProductTitle = () => (
    <span className="product-card__title min-h-[32px] lg:min-h-[48px]">
      <a
        href={`/products/${product?.handle}`}
        className="overflow-hidden hover:no-underline hover:text-highlight line-clamp-2"
      >
        {product?.title}
      </a>
    </span>
  )

  const renderPrice = () => (
    <div className="price">
      <span className="price price--compare">
        {!show_price_inline && (
          <div className="flex gap-2.5">
            <span className="font-normal current-price">
              {formattedPrice}
            </span>
            {product?.compare_at_price > price && (
              <span className="line-through compare-at-price price price--highlight">
                {formattedCompareAtPrice}
              </span>
            )}
          </div>
        )}
      </span>
      {/* <span className="price price--highlight">
        {product.compare_at_price > 0 && product.price < product.compare_at_price && (
          <div data-prodify-price-container="">
            {formattedCompareAtPrice}
          </div>
        )}
      </span> */}
    </div>
  )

  const renderSwatches = () => {
    return (
      <div className="product-card__swatches min-h-[20px] lg:min-h-[28px]">
        <ul className="flex items-center p-0 m-0 list-none">
          {siblingSwatches.slice(0, maxVisibleSwatches).map((swatch, index) => (
            <li
              key={index}
              className="inline-block mr-[2.5px] lg:mr-1.5"
            >
              <a
                href={swatch.url}
                className={`block overflow-hidden rounded-full w-5 h-5 lg:w-7 lg:h-7 ${index === 0 ? 'border border-black' : ''}`}
                style={{ backgroundColor: swatch.color }}
              >
                {swatch.image && (
                  <ExtendedImage
                    imageUrl={swatch.image}
                    alt="Swatch Image"
                    isLazy={true}
                    classes="w-full h-full object-cover"
                  />
                )}
              </a>
            </li>
          ))}
          {additionalSwatchesCount > 0 && (
            <li className="inline-block">
              <div className="flex items-center justify-center text-center text-primary font-body font-normal text-[10px]/[10px] ml-[2px] lg:text-base">
                + {additionalSwatchesCount}
              </div>
            </li>
          )}
        </ul>
      </div>
    );
  };

  return (
    <div className="product-card w-full flex flex-col !p-0 hover:!transform-none hover:!shadow-none">
      <div className="flex flex-col justify-between flex-grow w-full">
        {renderBadges()}
        {renderImage()}
        <div className="flex flex-col justify-between flex-grow gap-2 product-card__info">
          {renderTogBadges()}
          {renderProductTitle()}
          {renderPrice()}
          {renderSwatches()}
        </div>
      </div>
    </div>
  );
}

export default ExtendedProductTile;