import * as React from "react";
import { ElementLink } from "@mindarc/arctheme-components/frontend/src/stories/components/elements/Link/Link";
import ImageComponent from "@mindarc/arctheme-components/frontend/src/stories/components/elements/Image/Image";
import ExtendedCollapsible from "../ExtendedCollapsible/ExtendedCollapsible";
import Paragraph from "@mindarc/arctheme-components/frontend/src/stories/components/elements/Paragraph/Paragraph";

const ExtendedFooter = ({ shop, globals, settings, links, type }) => {
  console.log('shop', shop)
  const renderComponents = () => {
    switch (type) {
      default:
        return (
          <div className={type}>
            <div className="footer-links grid-container">
              <div className="footer__company-details">
                <a href="/" className="footer__company-logo">
                  <ImageComponent imageUrl={settings.company_icon} />
                </a>
                <div className="footer-links-company">
                  {settings.company_name}
                  <p className="sm-text">Developed by MindArc</p>
                </div>
              </div>
              {links.map((list_links, id) => (
                <div key={id} className="footer-links-list">
                  <h3 className="b3">{list_links.links_title}</h3>
                  <nav>
                    <ul aria-label="footermenu" role="menu">
                      {list_links.links.map((link, linkId) => (
                        <li key={linkId} role="none" className="my-2">
                          <ElementLink text={link.title} link={link.url} />
                        </li>
                      ))}
                    </ul>
                  </nav>
                </div>
              ))}
            </div>
          </div>
        );

      case "footer-layout-links-only":
        return (
          <div className={type}>
            <div
              className={`footer-links lg:max-w-[${settings.links_container_width}]`}
            >
              {links.map((list_links, id) => {
                switch (list_links.type) {
                  case "list":
                    return (
                      <div
                        key={id}
                        className={`footer-links-list lg:max-w-[${settings.column_width}]`}
                      >
                        <ExtendedCollapsible
                          title={list_links.links_title}
                          openIcon="icon-chevron-down"
                          desktopDisabled
                        >
                          <nav>
                            <ul aria-label="footermenu" role="menu">
                              {list_links.links.map((link, id) => {
                                return (
                                  <li key={id} role="none">
                                    <ElementLink
                                      text={link.title}
                                      link={link.url}
                                    />
                                  </li>
                                );
                              })}
                            </ul>
                          </nav>
                        </ExtendedCollapsible>
                      </div>
                    );
                  case "text":
                    return (
                      <div
                        key={id}
                        className={`footer-links-list lg:max-w-[${list_links.settings.text_column_width}]`}
                      >
                        <div className="mt-3 mb-6 font-semibold text-xl/6 lg:mt-0 lg:mb-4 lg:b4--lh20 lg:font-bold lg:text-sm lg:uppercase">
                          {list_links.settings.title}
                        </div>

                        <Paragraph
                          text={list_links.settings.contact_info}
                          type={"footer__need-help b2 b2--lh28 lg:b2--lh25 hover:no-underline"}
                        />

                        <div className="flex gap-x-2.5 my-5 lg:my-4">
                          {shop.social_links.facebook && (
                            <a
                              href={shop.social_links.facebook}
                              className="inline-block text-[1.5rem] px-[0.58em] hover:no-underline"
                              target="__blank"
                            >
                              <span className="icon-facebook"></span>
                            </a>
                          )}

                          {shop.social_links.instagram && (
                            <a
                              href={shop.social_links.instagram}
                              className="inline-block text-[1.5rem] px-[0.58em] hover:no-underline"
                              target="__blank"
                            >
                              <span className="icon-instagram-sm"></span>
                            </a>
                          )}

                          {shop.social_links.youtube && (
                            <a
                              href={shop.social_links.youtube}
                              className="inline-block text-[1.5rem] px-[0.58em] hover:no-underline"
                              target="__blank"
                            >
                              <span className="icon-youtube"></span>
                            </a>
                          )}

                          {shop.social_links.tiktok && (
                            <a
                              href={shop.social_links.tiktok}
                              className="inline-block text-[1.5rem] px-[0.58em] hover:no-underline"
                              target="__blank"
                            >
                              <span className="icon-tiktok"></span>
                            </a>
                          )}
                        </div>

                        <Paragraph
                          text={list_links.settings.content}
                          type={"b4 b4--lh20"}
                        />
                      </div>
                    );
                }
              })}
            </div>
          </div>
        );
    }
  };

  return <footer>{renderComponents()}</footer>;
};

export default ExtendedFooter;
