import React from "react";
import { GridLayout } from "./Layouts/Grid";
import { TableLayout } from "./Layouts/Table";
import "./CartItems.scss";

export const CartItems = ({ settings }) => {
  const { section } = settings;
  const productLayout = section.product_layout;

  return (
    <div className={`items-table items-layout__${productLayout}`}>
      {productLayout === "table" ? (
        <TableLayout settings={settings} />
      ) : (
        <GridLayout settings={settings} />
      )}
    </div>
  );
};
