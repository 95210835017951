import * as React from "react";

interface Metafield {
  handle: string;
  value: string;
  image: string;
}

const ExtendedMegamenuCollectionList = ({ block, index }) => {
  if (!Array.isArray(block.settings.collection_list)) {
    console.error(`Expected block.settings.collection_list to be an array, but got ${typeof block.settings.collection_list}`);
    return null;
  }

  const formattedCollectionList = React.useMemo(() => {
    if (!Array.isArray(block.metafields?.collection_list)) {
      console.error(`Expected block.metafields.collection_list to be an array, but got ${typeof block.metafields?.collection_list}`);
      return block.settings.collection_list;
    }

    const metafieldMap = new Map<string, Metafield>(
      block.metafields.collection_list.map(item => [item.handle, item])
    );

    return block.settings.collection_list.map(collection => {
      const metafield = metafieldMap.get(collection.handle);
      const image = metafield?.image || '';
  
      return {
        ...collection,
        stage: metafield?.value || '',
        image: image,
      };
    });
  }, [block.settings.collection_list, block.metafields?.collection_list]);

  return (
    <div key={index} className="megamenu-nav__column">
      <span className="block mb-4 font-semibold b1 b1--lh28 text-primary">
        {block.settings.collection_list_heading}
      </span>
      <ul
        className="megamenu-nav__image-wrapper megamenu-nav__image-wrapper--collections"
        role="list"
      >
        {formattedCollectionList.map((collection, collectionIndex) => {
          const stageValue = collection.stage;
          const imageUrl = collection.image;

          return (
            <li
              key={collectionIndex}
              className="group"
            >
              <a
                href={`/collections/${collection.handle}`}
                className="no-underline cursor-pointer hover:no-underline"
              >
                {imageUrl && (
                  <img
                    src={imageUrl}
                    alt={collection.title}
                    className="mb-3"
                  />
                )}
                <div>
                  <span className="megamenu-nav__collection-title group-hover:text-highlight">
                    {collection.title}
                  </span>
                  <div className="megamenu-nav__collection-stage">
                    {stageValue}
                  </div>
                </div>
              </a>
            </li>
          );
        })}
      </ul>
    </div>
  );
};

export default ExtendedMegamenuCollectionList;