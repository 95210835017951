import React from 'react';

const IconCurvedArrowLeft = ({ size = 10, onClick }) => {
  return (
    <button className="absolute left-6 transform -translate-y-1/2 w-8 h-8 rounded-none bg-black/30 p-0 min-h-0 focus:outline-none focus:outline-offset-0 focus:shadow-none top-[40%]">
      <div
        className="icon-arrow-long-left-white"
        style={{ fontSize: size }}
        onClick={onClick}
      >
      </div>
    </button>
  );
};

export default IconCurvedArrowLeft;
