import React, { useState, useEffect } from "react";

export const CartActions = ({ show_cart }) => {
  const buttonText = `Checkout`;
  return (
    <div className={"minicart__actions"}>
      <form id="checkout" action="/checkout" method="post" className=""></form>
      <form id="cart" action="/cart" method="get" className=""></form>

      {show_cart && (
        <button className="button btn-outline-light" type="submit" form={"cart"}>
          View Shopping Bag
        </button>
      )}

      <div className={"minicart__buttons"}>
        <button
          className="btn-primary"
          type="submit"
          name="checkout"
          form="checkout"
        >
          {buttonText}
        </button>
      </div>
    </div>
  );
};
