export default {
  name: "quantity",
  component() {
    return {
      init() {
        console.log("Shopify Product Quantity Handler");
      },
      increment() {
        console.log("Incrementing quantity");
        const quantityInput = document.querySelector(
          "[data-prodify-quantity-presentation]",
        );
        const newVal = parseInt(quantityInput.value) + 1;
        quantityInput.value = newVal;
        this.updateFormInputValue(quantityInput.value);
      },
      decrement() {
        console.log("Decrementing quantity");
        const quantityInput = document.querySelector(
          "[data-prodify-quantity-presentation]",
        );
        const newVal = parseInt(quantityInput.value) - 1;

        if (newVal > 0) {
          quantityInput.value = newVal;
          this.updateFormInputValue(newVal);
        }
      },
      updateFormInputValue(quantity) {
        const productForms = document.querySelectorAll(
          ".pdp-details .product-form input[name='quantity']",
        );

        productForms.forEach((productForm) => {
          productForm.value = quantity;
        });
      },
    };
  },
};
