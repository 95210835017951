import * as React from "react";

export const ProductVariantSize = ({ settings }) => {
  return (
    <div>
      size options here
    </div>
  );
};

export default ProductVariantSize;