import React from "react";
import { Button } from "@mindarc/arctheme-components/frontend/src/stories/components/elements/Button/Button";
import { Headings } from "@mindarc/arctheme-components/frontend/src/stories/components/elements/Headings/Headings";
import { ElementLink } from "@mindarc/arctheme-components/frontend/src/stories/components/elements/Link/Link";
import { Paragraph } from "@mindarc/arctheme-components/frontend/src/stories/components/elements/Paragraph/Paragraph";
import { TextArea } from "@mindarc/arctheme-components/frontend/src/stories/components/elements/TextArea/TextArea";
import Checkbox from "@mindarc/arctheme-components/frontend/src/stories/components/elements/Checkbox/Checkbox";
import Radio from "@mindarc/arctheme-components/frontend/src/stories/components/elements/Radio/Radio";
import { ElementTable } from "@mindarc/arctheme-components/frontend/src/stories/components/elements/Table/Table";
import { ExtendedTextField } from "../src/stories/elements/ExtendedTextField/ExtendedTextField";
import ExtendedBanner from "../src/stories/sections/ExtendedBanner/Banner";
import { Select } from "../src/stories/elements/Select";
import { ButtonDropdown } from "../src/stories/elements/ButtonDropdown";

export function StyleguidePage({ ...props }) {
  return (
    <div className={"p-8"} style={{ background: "#F5F5F5" }}>
      <div className={"text-center py-4"}>
        <Paragraph
          text={
            "To view the latest Arctheme components, please check the link below"
          }
        />
        <ElementLink
          text={"Arctheme Components"}
          link={"https://65bc451225e432e3bc38ad47-egwfldqysf.chromatic.com/"}
          new_tab={true}
        />
      </div>

      <div className={"styleguide-components"}>
        <div className={"component-header"}>
          <Headings text={"Colours"} type={"h2"} />
        </div>
        <div className={"color-grid"}>
          <div
            className={
              "bg-primary flex text-white h-20 w-20 text-center justify-center items-center p-8 border"
            }
          ></div>
          <div
            className={
              "bg-secondary flex text-black h-20 w-20 text-center justify-center items-center p-8 border"
            }
          ></div>
          <div
            className={
              "bg-accent flex text-white h-20 w-20 text-center justify-center items-center p-8 border"
            }
          ></div>
        </div>
      </div>

      <div className={"styleguide-components"}>
        <div className={"typography"}>
          <div className={"component-header"}>
            <Headings text={"Typography"} type={"h2"} />
          </div>

          <div className={"component d1"}>
            <Headings text={"Display Text"} type={"h1"} />
          </div>
          <div className={"component"}>
            <Headings text={"Heading 1"} type={"h1"} classes="h1--alt" />
          </div>
          <div className={"component"}>
            <Headings text={"Heading 2"} type={"h2"} />
          </div>
          <div className={"component"}>
            <Headings text={"Heading 3"} type={"h3"} />
          </div>
          <div className={"component"}>
            <Headings text={"Heading 4"} type={"h4"} />
          </div>
          <div className={"component"}>
            <Headings text={"Heading 5"} type={"h5"} />
          </div>
          <div className={"component"}>
            <Headings text={"Heading 6"} type={"h6"} />
          </div>
          <div className={"component"}>
            <Paragraph text={"Heading 7"} type={"h7"} />
          </div>

          <div className={"component"}>
            <ElementLink text={"Link Text"} />
          </div>
          <div className={"component"}>
            <Paragraph
              text={
                "Paragraph Text: Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in repre"
              }
            />
            <Paragraph
              text={
                "Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum."
              }
            />
          </div>

          <div className={"component"}>
            <ul className="list">
              <li>List item</li>
              <li>List item</li>
              <li>List item</li>
            </ul>
          </div>

          <div className={"component"}>
            <small>Small</small>
          </div>

          <div className={"component"}>
            <s>Strikethrough</s>
          </div>

          <div className={"component"}>
            <em>Italics</em>
          </div>

          <div className={"component"}>
            <div>
              <strong>Strong</strong>
            </div>
            <div>
              <b>Bold</b>
            </div>
          </div>
        </div>
      </div>

      <div className={"styleguide-components"}>
        <div className={"component-header"}>
          <Headings text={"Buttons"} type={"h2"} />
        </div>

        <div className={"flex flex-row justify-between"}>
          <div className={"col-span-2"}>
            <h5>Buttons & Icons</h5>
          </div>

          <div className={"flex flex-row space-x-2 col-span-8"}>
            <div className={"component"}>
              <Button icon={true} label={"Before"} reverse={false} />
            </div>
            <div className={"component"}>
              <Button
                icon={true}
                text={true}
                fill={"white"}
                label={"After"}
                reverse={true}
              />
            </div>
          </div>
        </div>

        <div className={"flex flex-row justify-between"}>
          <div className={"col-span-2"}>
            <h5>Primary</h5>
          </div>

          <div className={"flex flex-row space-x-2 col-span-8"}>
            <div className={"component"}>
              <Button type={"btn-primary"} label={"Shop Now"} />
            </div>
            <div className={"component"}>
              <Button disabled={true} label={"Disabled"} reverse={true} />
            </div>
            <div className={"component"}>
              <Button
                icon={false}
                type={"btn-primary"}
                label={"Pressed"}
                reverse={false}
              />
            </div>
            <div className={"component"}>
              <Button
                icon={false}
                text={true}
                type={"btn-primary"}
                ill={"white"}
                label={"Hover"}
                reverse={false}
              />
            </div>
          </div>
        </div>

        <div className={"flex flex-row justify-between"}>
          <div className={"col-span-2"}>
            <h5>Secondary</h5>
          </div>

          <div className={"flex flex-row space-x-2 col-span-8"}>
            <div className={"component"}>
              <Button type={"btn-secondary"} label={"Shop Now"} />
            </div>
            <div className={"component"}>
              <Button
                type={"btn-secondary"}
                fill={"black"}
                label={"Disabled"}
                reverse={true}
                disabled={true}
              />
            </div>
            <div className={"component"}>
              <Button
                type={"btn-secondary"}
                icon={false}
                fill={"black"}
                label={"Pressed"}
                reverse={false}
              />
            </div>
            <div className={"component"}>
              <Button
                type={"btn-secondary"}
                icon={false}
                text={true}
                fill={"black"}
                label={"Hover"}
                reverse={false}
              />
            </div>
          </div>
        </div>

        <div className={"flex flex-row justify-between"}>
          <div className={"col-span-2"}>
            <h5>Outlined (Dark)</h5>
          </div>

          <div className={"flex flex-row space-x-2 "}>
            <div className={"component"}>
              <Button type={"btn-outline"} label={"Shop Now"} />
            </div>
            <div className={"component"}>
              <Button
                type={"btn-outline"}
                fill={"black"}
                label={"Disabled"}
                reverse={true}
                disabled={true}
              />
            </div>
            <div className={"component"}>
              <Button
                type={"btn-outline"}
                icon={false}
                fill={"black"}
                label={"Pressed"}
                reverse={false}
              />
            </div>
            <div className={"component"}>
              <Button
                type={"btn-outline"}
                icon={false}
                text={true}
                fill={"black"}
                label={"Hover"}
                reverse={false}
              />
            </div>
          </div>
        </div>

        <div className={"flex flex-row justify-between"}>
          <div className={"col-span-2"}>
            <h5>Outlined (Light) </h5>
          </div>

          <div className={"flex flex-row space-x-2 "}>
            <div className={"component"}>
              <Button type={"btn-outline-light"} label={"Shop Now"} />
            </div>
            <div className={"component"}>
              <Button
                type={"btn-outline-light"}
                fill={"black"}
                label={"Disabled"}
                reverse={true}
                disabled={true}
              />
            </div>
            <div className={"component"}>
              <Button
                type={"btn-outline-light"}
                icon={false}
                fill={"black"}
                label={"Pressed"}
                reverse={false}
              />
            </div>
            <div className={"component"}>
              <Button
                type={"btn-outline-light"}
                icon={false}
                text={true}
                fill={"black"}
                label={"Hover"}
                reverse={false}
              />
            </div>
          </div>
        </div>

        <div className={"flex flex-row justify-between"}>
          <div className={"col-span-2"}>
            <h5>Dark</h5>
          </div>

          <div className={"flex flex-row space-x-2 col-span-8"}>
            <div className={"component"}>
              <Button type={"btn-dark"} label={"Shop Now"} />
            </div>
            <div className={"component"}>
              <Button
                type={"btn-dark"}
                icon={false}
                fill={"black"}
                label={"Disabled"}
                reverse={true}
                disabled={true}
              />
            </div>
            <div className={"component"}>
              <Button
                type={"btn-dark"}
                icon={false}
                fill={"black"}
                label={"Pressed"}
                reverse={false}
              />
            </div>
            <div className={"component"}>
              <Button
                type={"btn-dark"}
                icon={false}
                text={true}
                fill={"black"}
                label={"Hover"}
                reverse={false}
              />
            </div>
          </div>
        </div>

        <div className={"flex flex-row justify-between"}>
          <div className={"col-span-2"}>
            <h5>Light</h5>
          </div>

          <div className={"flex flex-row space-x-2 col-span-8"}>
            <div className={"component"}>
              <Button type={"btn-light"} label={"Shop Now"} />
            </div>
            <div className={"component"}>
              <Button
                type={"btn-light"}
                icon={false}
                fill={"black"}
                label={"Disabled"}
                reverse={true}
                disabled={true}
              />
            </div>
            <div className={"component"}>
              <Button
                type={"btn-light"}
                icon={false}
                fill={"black"}
                label={"Pressed"}
                reverse={false}
              />
            </div>
            <div className={"component"}>
              <Button
                type={"btn-light"}
                icon={false}
                text={true}
                fill={"black"}
                label={"Hover"}
                reverse={false}
              />
            </div>
          </div>
        </div>

        <div className={"flex flex-row justify-between"}>
          <div className={"col-span-2"}>
            <h5>Naked</h5>
          </div>

          <div className={"flex flex-row space-x-2 col-span-8"}>
            <div className={"component"}>
              <ElementLink type={"btn-link"} label={"Button"} link={"/"} />
            </div>
            <div className={"component"}>
              <ElementLink
                type={"btn-link"}
                icon={true}
                fill={"black"}
                label={"Button"}
                link={"/"}
              />
            </div>
          </div>
        </div>
      </div>

      <div className={"styleguide-components"}>
        <div className={"component-header"}>
          <Headings text={"Inputs & Controls"} type={"h2"} />
        </div>

        <div className={"flex flex-row justify-between"}>
          <div className={"col-span-2"}>
            <h5>Default</h5>
          </div>

          <div className={"flex flex-row space-x-2 col-span-8"}>
            <div className={"component"}>
              <ExtendedTextField id={"text"} placeholder={"Text"} />
            </div>
            <div className={"component"}>
              <ExtendedTextField id={"text"} placeholder={"Text"} />
            </div>
            <div className={"component"}>
              <ExtendedTextField
                id={"text"}
                placeholder={"Text"}
                value={"Finished"}
              />
            </div>
            <div className={"component"}>
              <ExtendedTextField
                id={"text"}
                placeholder={"Text"}
                disabled={true}
              />
            </div>
          </div>
        </div>

        <div className={"flex flex-row justify-between"}>
          <div className={"col-span-2"}>
            <h5>With Icon</h5>
          </div>

          <div className={"flex flex-row space-x-2 col-span-8"}>
            <div className={"component"}>
              <ExtendedTextField id={"text"} placeholder={"Text"} />
            </div>
            <div className={"component"}>
              <ExtendedTextField id={"text"} placeholder={"Text"} />
            </div>
            <div className={"component"}>
              <ExtendedTextField
                id={"text"}
                placeholder={"Text"}
                value={"Finished"}
              />
            </div>
            <div className={"component"}>
              <ExtendedTextField id={"text"} placeholder={"Text"} />
            </div>
          </div>
        </div>

        <div className={"flex flex-row justify-between"}>
          <div className={"col-span-2"}>
            <h5>Validation</h5>
          </div>

          <div className={"flex flex-row space-x-2 col-span-8"}>
            <div className={"component"}>
              <ExtendedTextField id={"text"} placeholder={"Text"} />
            </div>
            <div className={"component"}>
              <ExtendedTextField id={"text"} placeholder={"Text"} />
            </div>
            <div className={"component"}>
              <ExtendedTextField
                id={"text"}
                placeholder={"Text"}
                value={"Finished"}
              />
            </div>
            <div className={"component"}>
              <ExtendedTextField id={"text"} placeholder={"Text"} />
            </div>
          </div>
        </div>

        <div className={"flex flex-row justify-between"}>
          <div className={"col-span-2"}>
            <h5>Dropdown</h5>
          </div>

          <div className={"flex flex-row space-x-2 col-span-8"}>
            <div className={"component"}>
              <Select
                placeholder="Dropdown"
                id="dropdown-1"
                options={[
                  { name: "Item 1", value: "value" },
                  { name: "Item 2", value: "value2" },
                ]}
              />
              </div>

              <div className={"component"}>
                <ButtonDropdown
                  text="Age"
                  options={[
                    { id: 1, label: "Newborn 0-3M (20)" },
                    { id: 2, label: "Infant 0-3M (20)" },
                    { id: 3, label: "Toddler 0-3M (20)" },
                    { id: 4, label: "Preschooler 0-3M (20)" }
                  ]}
                />
            </div>
          </div>
        </div>

        <div className={"flex flex-row justify-between"}>
          <div className={"col-span-2"}>
            <h5>Text Area</h5>
          </div>

          <div className={"flex flex-row space-x-2 col-span-8"}>
            <div className={"component"}>
              <div className={"component"}>
                <TextArea placeholder={""} />
              </div>
            </div>
          </div>
        </div>

        <div className={"component"}>
          <Checkbox text={"Checkbox"} checked={true} useSvg={true} />
        </div>

        <div className={"component"}>
          <Checkbox text={"Checkbox checked"} checked={false} useSvg={true} />
        </div>

        <div className={"component"}>
          <Radio
            name={"Radio"}
            options={[{ value: "value", label: "Radio" }]}
          />
        </div>
      </div>

      <div className={"styleguide-components"}>
        <div className={"component-header"}>
          <Headings text={"Notifications"} type={"h2"} />
        </div>
        <div className={"component"}>
          <div className="errors">
            <ul>
              <li>No account found with that email.</li>
              <li>Sorry, this product is out of stock right now.</li>
            </ul>
          </div>
        </div>
        <div className={"component"}>
          <p className="form-success">
            Thanks for contacting us. We'll get back to you as soon as possible.
          </p>
        </div>
        <div className={"component"}>
          <p className="info">Some helpful information.</p>
        </div>
      </div>

      <div className={"styleguide-components"}>
        <div className={"component-header"}>
          <Headings text={"Tags"} type={"h2"} />
        </div>
      </div>

      <div className={"styleguide-components"}>
        <div className={"component-header"}>
          <Headings text={"Cards"} type={"h2"} />
        </div>
      </div>

      {/* <div className={"styleguide-components"}>
        <div className={"component-header"}>
          <Headings text={"Banners"} type={"h2"} />
        </div>
        <div className={"section-banner component w-full h-full"}>
          <ExtendedBanner
            banners={[
              {
                settings: {
                  button_text: "",
                  button_url: "",
                  url: "/",
                  image:
                    "//cdn.shopify.com/s/files/1/0599/0930/8569/files/wp2350502-lego-hd-wallpapers.jpg?v=1703126036",
                },
                type: "block_image",
              },
              {
                settings: {
                  button_text: "",
                  button_url: "",
                  url: "/",
                  image:
                    "//cdn.shopify.com/s/files/1/0599/0930/8569/files/wp2350502-lego-hd-wallpapers.jpg?v=1703126036",
                },
                type: "block_image",
              },
            ]}
          />
        </div>
      </div> */}

      <div className={"styleguide-components"}>
        <div className={"component-header"}>
          <Headings text={"Table"} type={"h2"} />
        </div>
        <div className={"component"}>
          <ElementTable
            data={[
              { Name: "John Doe", Age: 30, Country: "USA" },
              { Name: "Jane Doe", Age: 25, Country: "Canada" },
            ]}
          />
        </div>
      </div>
    </div>
  );
}
