import React, { useState } from "react";
import { Paragraph } from "@arctheme-components/elements/Paragraph/Paragraph";
import { default as ExtendedButton } from "@project-stories/elements/Button";
import { Headings } from "@arctheme-components/elements/Headings/Headings";
import useMobileBreakpoint from "@src/hooks/useMobileBreakpoint";
import { mobileBreakpoint } from "../../../../../entrypoints/theme";
import { clsx } from "clsx";
import { default as Image } from "@arctheme-components/elements/Image/Image";

const TwoColumnGridLayout = ({
  reverselayout,
  description,
  settings,
  blocks,
}) => {
  const isMobile = useMobileBreakpoint(mobileBreakpoint);

  let image = isMobile
    ? settings.image_mobile?.src ||
      settings.image_mobile ||
      settings.image?.src ||
      settings.image
    : settings.image?.src || settings.image;

  let video = isMobile
    ? settings.video_mobile?.sources?.[0]?.url ??
      settings.video_mobile ??
      settings.video?.sources?.[0]?.url ??
      settings.video
    : settings.video?.sources?.[0]?.url ?? settings.video;

  let videoPoster = settings.video?.preview_image.src;
  let heading = settings.title;
  let type = "twi-twocolumn";
  const imageWidth = isMobile ? settings.width_mobile : settings.width;

  if (settings) {
    if (settings.content) {
      description = settings.content;
    }
    if (settings.reverse_layout) {
      reverselayout = settings.reverse_layout;
    }

    if (settings.classes) {
      type = settings.classes;
    }
  }

  if (settings.render_type !== "all") {
    if (isMobile && settings.render_type !== "mobile-only") return null;
    if (!isMobile && settings.render_type !== "desktop-only") return null;
  }

  return (
    <section className={`w-full ${type}`}>
      <div
        className={`twi-twocolumn__container lg:h-[${settings.container_height}px] overflow-hidden relative`}
      >
        <div
          className={`twi-twocolumn__content ${clsx({
            "flex-col": isMobile,
            "flex-col-reverse": isMobile && settings.reverse_layout_mobile,
            "flex-row-reverse": reverselayout && settings.reverse_layout,
          })}`}
        >
          <div
            className="flex flex-col justify-center space-y-4 lg:basis-1/2"
            style={{ flexBasis: imageWidth + "%" }}
          >
            <div className={`flex h-full w-full`}>
              {image ? (
                <Image
                  imageUrl={image}
                  classes={"object-cover h-full w-full"}
                />
              ) : video ? (
                <video
                  className={"object-cover h-full w-full"}
                  autoPlay
                  loop
                  muted
                  poster={videoPoster}
                  preload="metadata"
                  playsInline
                >
                  <source src={video} type="video/mp4"></source>
                </video>
              ) : null}
            </div>
            <div className="flex space-x-4">
              {blocks.map((block, index) => (
                <div key={block.key || index} className="flex-1">
                  {block.settings.image ? (
                    <Image
                      imageUrl={block.settings.image}
                      classes={"object-cover w-full"}
                    />
                  ) : block.settings.video ? (
                    <video
                      className={"object-cover w-full"}
                      autoPlay
                      loop
                      muted
                      poster={block.settings.videoPoster}
                      preload="metadata"
                      playsInline
                    >
                      <source
                        src={block.settings.video}
                        type="video/mp4"
                      ></source>
                    </video>
                  ) : null}
                </div>
              ))}
            </div>
          </div>
          <div
            className="twi-container"
            style={{ flexBasis: `calc(${100 - parseFloat(imageWidth)}%)` }}
          >
            <div
              className={`twi-content h-full justify-${settings.content_position_desktop}`}
            >
              <div
                className={`w-full flex flex-col items-${settings.content_alignment_mobile} lg:items-${settings.content_alignment_desktop}`}
              >
                {settings.tagline && (
                  <Paragraph text={settings.tagline} type={"bc"} />
                )}
                {heading && (
                  <Headings
                    text={heading}
                    type={
                      isMobile
                        ? settings.heading_type_mobile
                        : settings.heading_type
                    }
                  />
                )}
                <div>
                  {settings.subheading && (
                    <Paragraph
                      text={settings.subheading}
                      type={`${isMobile ? settings.subheading_style_mobile : settings.subheading_style}`}
                    />
                  )}
                  {description && (
                    <Paragraph
                      text={description}
                      type={`${isMobile ? settings.content_style_mobile : settings.content_style} py-[16px]`}
                    />
                  )}
                </div>
                <div
                  className={`buttons-container flex gap-x-[8px] self-${settings.content_alignment_mobile} lg:self-${settings.content_alignment_desktop}`}
                >
                  {blocks.map((block, index) => (
                    <div key={block.key || index} className={`pt-[${block.settings.padding_top_mobile}px] lg:pt-[${block.settings.padding_top_desktop}px]`}>
                      {block.settings.button_text && (
                        <ExtendedButton
                          label={block.settings.button_text}
                          style={block.settings.button_style}
                          link={block.settings.button_url}
                          icon={block.settings.button_icon}
                          onClick={undefined}
                        />
                      )}
                    </div>
                  ))}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default TwoColumnGridLayout;
