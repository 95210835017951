import React, { useState, useRef, useEffect } from "react";
import { default as Image } from "@project-stories/elements/ExtendedImage/ExtendedImage";
import useMobileBreakpoint from "@src/hooks/useMobileBreakpoint";
import { Paragraph } from "@src/stories/elements/Paragraph";
import ExtendedHeadings from "../../elements/ExtendedHeadings/ExtendedHeadings";
import './Gallery.scss';

export const Gallery = ({ settings }) => {
  const { section, blocks } = settings;
  const galleryRef = useRef(null);
  const [fullscreen, setFullscreen] = useState(null);
  const [isAnimating, setIsAnimating] = useState(false);
  const [isVideoPlaying, setIsVideoPlaying] = useState(false);
  const isMobile = useMobileBreakpoint("1024px");
  const [columnBlocks, setColumnBlocks] = useState({});

  let gapBetweenBlocks = isMobile ? section.mobile_gap : section.desktop_gap;

  useEffect(() => {
    const groupedBlocks = {};

    blocks.forEach((block) => {
      const columnKey = isMobile
        ? block.settings.column_mobile
        : block.settings.column;
      if (!groupedBlocks[columnKey]) {
        groupedBlocks[columnKey] = [];
      }
      groupedBlocks[columnKey].push(block);
    });

    setColumnBlocks(groupedBlocks);
  }, [isMobile, blocks]);

  const handleItemClick = (mediaUrl, mediaType) => {
    setFullscreen({ url: mediaUrl, type: mediaType });
    setTimeout(() => setIsAnimating(true), 10); // Delay to trigger the fade-in after rendering
  };

  const closeFullscreen = () => {
    setIsAnimating(false);
    setTimeout(() => setFullscreen(null), 300); // Delay to match the animation duration
  };

  const handleKeyDown = (e) => {
    if (e.key === "Escape") {
      closeFullscreen();
    }
  };

  const handleVideoPlay = (videoId) => {
    const videoElement = document.getElementById(videoId) as HTMLVideoElement | null;
    if (videoElement) {
      if (videoElement.paused) {
        videoElement.play();
        setIsVideoPlaying(true);
      } else {
        videoElement.pause();
        setIsVideoPlaying(false);
      }
    }
  };

  return (
    <div className={section.container_type}>
      <div
        className={`flex text-center mb-[24px] lg:mb-[48px] gap-[20px] lg:gap-[24px] flex-col`}
      >
        <ExtendedHeadings
          text={section.title}
          type={"h2"}
          classes={isMobile ? section.title_style_mobile : section.title_style}
        />
        <Paragraph
          text={section.subtext}
          type={
            isMobile ? section.subtitle_style_mobile : section.subtitle_style
          }
        />
      </div>
      <div
        className={`grid gap-[${gapBetweenBlocks}px] grid-cols-1 lg:grid-cols-${section.grid_columns}
          ${section.gallery_direction === "horizontal" ? "grid-flow-col" : "grid-flow-row"}`}
        ref={galleryRef}
      >
        {Array.from({ length: section.grid_columns }, (_, i) => i + 1).map(
          (column) => {
            const colspan_key = `colspan_${column}`;
            const countOfItems = columnBlocks[column]?.length;
            return (
              <div
                key={column}
                className={`col-span-${isMobile ? countOfItems : section[colspan_key]} grid grid-cols-${countOfItems} lg:grid-cols-1 gap-[${gapBetweenBlocks}px]`}
              >
                {columnBlocks[column]?.map((block) => {
                  const video = block.settings.video?.sources[1].url;
                  const image = block.settings.image;
                  const videoId = `video-${block.id}`; // Unique ID for each video

                  return (
                    <div
                      key={block.id}
                      className={`gallery-item cursor-pointer ${isMobile ? `col-span-${block.settings.col_span_mobile}` : `col-span-${block.settings.col_span}`} relative`}
                    >
                      {video ? (
                        <div className="relative w-full h-full">
                         <video
                          id={videoId}
                          src={video}
                          controls
                          poster={image}
                          className="object-cover w-full h-full"
                          onClick={() => handleVideoPlay(videoId)}
                        ></video>

                        {!isVideoPlaying && (
                          <img
                            src={settings.globals.gallery_play_icon}
                            alt="Play Video"
                            className="absolute w-20 h-20 transform -translate-x-1/2 -translate-y-1/2 cursor-pointer top-1/2 left-1/2"
                            onClick={() => handleVideoPlay(videoId)}
                          />
                        )}
                        </div>
                      ) : (
                        block.settings.image && (
                          <Image
                            imageUrl={image}
                            classes={`w-full h-full object-cover`}
                          />
                        )
                      )}
                    </div>
                  );
                })}
              </div>
            );
          },
        )}
      </div>

      {fullscreen && (
        <div
          className={`fixed inset-0 bg-black bg-opacity-80 flex items-center justify-center z-50 transition-opacity duration-300 ${
            isAnimating ? "opacity-100" : "opacity-0"
          }`}
          onClick={closeFullscreen}
          onKeyDown={handleKeyDown}
          tabIndex={-1} // Make div focusable to listen for key events
        >
          {fullscreen.type === "image" ? (
            <img
              src={fullscreen.url}
              className="max-w-[90%] max-h-[90%]"
              alt="Fullscreen"
            />
          ) : (
            <video
              src={fullscreen.url}
              controls
              autoPlay
              className="max-w-[90%] max-h-[90%]"
            ></video>
          )}
        </div>
      )}
    </div>
  );
};