export default {
  name: "global",
  store() {
    return {
      isMobileMenuVisible: false,
      isMinicartVisible: false,
      isPredictiveSearchVisible: false,
      isWindowScrolled: false,
      cart: null,
      init() {
        console.log("Arctheme Global Store Initialized.");

        window.addEventListener(
          "close-header-search",
          this.closeHeaderSearch.bind(this),
        );

        window.addEventListener(
          "scroll",
          this.onWindowScrollHandler.bind(this),
        );
        window.addEventListener("keydown", this.onKeyDownHandler.bind(this));
        window.addEventListener(
          "mobile-menu-open",
          this.openMobileMenu.bind(this),
        );
        window.addEventListener(
          "mobile-menu-closed",
          this.closeMobileMenu.bind(this),
        );

        this.initLiquidAJaxCart();

        this.updateHeaderHeight();

        window.addEventListener("scroll", this.updateHeaderHeight.bind(this));

        const observer = new MutationObserver(
          this.updateHeaderHeight.bind(this),
        );
        observer.observe(document.body, {
          childList: true,
          subtree: true,
        });

        Object.defineProperty(this, "isPredictiveSearchVisible", {
          get: () => this._isPredictiveSearchVisible,
          set: (value) => {
            this._isPredictiveSearchVisible = value;
            // console.log('isPredictiveSearchVisible changed:', value);

            document.body.classList.toggle("overflow-hidden", value);

            if (value) {
              this.lockScrollPosition();
            } else {
              this.unlockScrollPosition();
            }
          },
        });
      },
      get bodyClasses() {
        let classes = [];

        if (this.isMobileMenuVisible) {
          classes.push("mobile-menu-visible");
        }
        if (this.isMinicartVisible) {
          classes.push("minicart-open");
        }
        if (this.isPredictiveSearchVisible) {
          classes.push("search-open");
        }

        return classes.join(" ") || "";
      },
      openMobileMenu() {
        this.isMobileMenuVisible = true;
        document.body.classList.add("mobile-menu-visible");
      },
      closeMobileMenu() {
        this.isMobileMenuVisible = false;
        document.body.classList.remove("mobile-menu-visible");
      },
      toggleMobileMenu() {
        this.isMobileMenuVisible = !this.isMobileMenuVisible;
      },
      toggleMinicart() {
        this.isMinicartVisible = !this.isMinicartVisible;
        // console.log("Toggle Minicart", this.isMinicartVisible);
        document.body.classList[this.isMinicartVisible ? "add" : "remove"](
          "no-scroll",
        );
      },
      initLiquidAJaxCart() {
        document.addEventListener("liquid-ajax-cart:request-end", (event) => {
          const { requestState, cart } = event.detail;

          if (
            requestState.requestType === "add" &&
            requestState.responseData?.ok
          ) {
            this.isMinicartVisible = true;
          }

          this.cart = cart;
        });
      },
      onWindowScrollHandler() {
        const isScrolled = window.scrollY > 0;

        this.isWindowScrolled = isScrolled;
        document.body.classList[isScrolled ? "add" : "remove"]("scrolled");

        this.updateHeaderHeight();
      },
      onKeyDownHandler(event) {
        if (event.key === "Escape") {
          this.isMinicartVisible = false;
          this.isPredictiveSearchVisible = false;
        }
      },
      updateHeaderHeight() {
        requestAnimationFrame(() => {
          const header = document.querySelector(".header");
          const promoBar = document.querySelector(".promobar");

          let headerHeight = header ? header.offsetHeight : 0;

          if (
            promoBar &&
            promoBar.style.display !== "none" &&
            window.scrollY === 0
          ) {
            headerHeight += promoBar.offsetHeight;
          }

          document.documentElement.style.setProperty(
            "--header-height",
            `${headerHeight}px`,
          );
        });
      },
      lockScrollPosition() {
        const scrollPosition = window.scrollY;
        document.documentElement.style.setProperty(
          "--scroll-position",
          `-${scrollPosition}px`,
        );
        document.body.style.top = `-${scrollPosition}px`;
      },
      unlockScrollPosition() {
        const scrollPosition = Math.abs(
          parseInt(document.body.style.top || "0", 10),
        );
        document.body.style.top = "";
        window.scrollTo(0, scrollPosition);
      },

      closeHeaderSearch() {
        this.isPredictiveSearchVisible = false;
      },
    };
  },
};
