import * as React from "react";
import { useEffect, useState } from "react";
import ExtendedImage from "../../elements/ExtendedImage/ExtendedImage";
import ExtendedHeadings from "../../elements/ExtendedHeadings/ExtendedHeadings";
import useMobileBreakpoint from "@src/hooks/useMobileBreakpoint";
import { mobileBreakpoint } from "../../../../entrypoints/theme";

interface HotspotBlockItem {
  product: string;
  hotspot_x: number;
  hotspot_y: number;
}

export const ImageHotSpot = ({ settings, hotspot_block, additional }) => {
  const isMobile = useMobileBreakpoint(mobileBreakpoint);
  const [clickedHotspot, setClickedHotspot] = useState(0);
  const [animationKey, setAnimationKey] = useState(0);
  const [hotspots, setHotspots] = useState<HotspotBlockItem[]>([]);

  const parseAdditionalData = (data: string): HotspotBlockItem[] => {
    try {
      const parsedData = JSON.parse(data);
      if (Array.isArray(parsedData)) {
        return parsedData;
      } else {
        console.error('Parsed data is not an array:', parsedData);
        return [];
      }
    } catch (error) {
      console.error('Error parsing JSON:', error);
      return [];
    }
  };

  useEffect(() => {
    const hotspotsArr: HotspotBlockItem[] =
      hotspot_block && hotspot_block.length > 0
        ? hotspot_block
        : parseAdditionalData(additional || '');

    setHotspots(hotspotsArr);
  }, [hotspot_block, additional]);

  const handleHotspotClick = (index) => {
    setClickedHotspot(clickedHotspot === index ? null : index);
    setAnimationKey((prevKey) => prevKey + 1);
  };

  const handleProductClick = (url) => {
    if (url) {
      window.location.href = url;
    }
  };


  return (
    <div className="flex flex-col items-center lg:gap-24 lg:flex-row">
      {settings.featured_image && (
        <div className="relative w-full mb-2 lg:w-1/2 lg:mb-0">
          <ExtendedImage
            imageUrl={settings.featured_image}
            alt={settings.title || "Image"}
            isLazy={true}
            classes="w-full h-auto object-cover"
          />
          {hotspots.map((block, index) => {
            const positionStyle =
              block.settings.hotspot_x < 50
                ? { left: 'calc(100% + 28px)', top: 'calc(50% - 50px)' }
                : { right: 'calc(100% + 28px)', top: 'calc(50% - 50px)' };
            return (
              <div
                key={index}
                className="absolute z-[1] p-[7px] transform -translate-x-1/2 -translate-y-1/2 border-4 border-white rounded-full shadow-lg cursor-pointer"
                style={{
                  left: `${block.settings.hotspot_x}%`,
                  top: `${block.settings.hotspot_y}%`,
                }}
                onClick={() => handleHotspotClick(index)}
              >
                <div className="relative">
                  <div className="flex items-center justify-center w-3 h-3 bg-white rounded-full shadow-lg">
                    <i className="fas fa-plus"></i>
                  </div>
                  {clickedHotspot === index && !isMobile && (
                    <div
                      className="absolute z-[1] w-64 p-3 shadow-lg rounded-xl bg-accent slide-fade-in-element"
                      style={positionStyle}
                      key={animationKey}
                      onClick={() => handleProductClick(block.product?.url)}
                    >
                      <div className="flex items-center space-x-4 hover:no-underline">
                        <ExtendedImage
                          imageUrl={block.product?.image}
                          alt={block.product?.title}
                          isLazy={true}
                          classes="object-cover w-[80px] h-[80px]"
                        />
                        <p className="text-xl font-semibold text-black font-body">
                          {block.product?.title}
                        </p>
                      </div>
                    </div>
                  )}
                </div>
              </div>
            );
          })}
        </div>
      )}
      {isMobile && hotspots[clickedHotspot] && (
        <div
          className={`w-full slide-fade-in-element`}
          key={animationKey}
          onClick={() => handleProductClick(hotspots[clickedHotspot].product.url)}
        >
          <div className="flex items-center px-4 py-3 space-x-3 rounded-lg bg-accent">
            <ExtendedImage
              imageUrl={hotspots[clickedHotspot].product.image}
              alt={hotspots[clickedHotspot].product.title}
              isLazy={true}
              classes="object-cover w-[80px] h-[80px]"
            />
            <div>
              <p className="text-xl font-semibold text-black font-body">
                {hotspots[clickedHotspot].product.title}
              </p>
            </div>
          </div>
        </div>
      )}
      <div className="flex flex-col justify-center w-full h-full my-10 lg:w-1/2 lg:my-0">
        {settings.subheading && (
          <p className="mb-3 italic font-medium text-base/4 font-display text-primary lg:text-xl/4">
            {settings.subheading}
          </p>
        )}
        {settings.title && (
          <ExtendedHeadings
            text={settings.title}
            type={"h2"}
            classes={"h2 mb-3"}
          />
        )}
        {settings.description && (
          <p className="text-base text-primary font-body"
            dangerouslySetInnerHTML={{ __html: settings.description }}
          ></p>
        )}
      </div>
    </div>
  );
};

export default ImageHotSpot;
