import React, { useRef, useState, useEffect } from "react";
import ExtendedHeadings from "../../elements/ExtendedHeadings/ExtendedHeadings";

export const ImageCompare = ({ settings, additional }) => {
  const containerRef = useRef(null);
  const [sliderPosition, setSliderPosition] = useState(50);
  const [isDragging, setIsDragging] = useState(false);

  const handleMouseMove = (e) => {
    if (!isDragging || !containerRef.current) return;
    const { left, width } = containerRef.current.getBoundingClientRect();
    let position = ((e.clientX - left) / width) * 100;
    if (position < 0) position = 0;
    if (position > 100) position = 100;
    setSliderPosition(position);
  };

  const handleTouchMove = (e) => {
    if (!isDragging || !containerRef.current) return;
    const { left, width } = containerRef.current.getBoundingClientRect();
    let position = ((e.touches[0].clientX - left) / width) * 100;
    if (position < 0) position = 0;
    if (position > 100) position = 100;
    setSliderPosition(position);
  };

  const handleMouseDown = () => {
    setIsDragging(true);
  };

  const handleMouseUp = () => {
    setIsDragging(false);
  };

  useEffect(() => {
    document.addEventListener("mousemove", handleMouseMove);
    document.addEventListener("touchmove", handleTouchMove);
    document.addEventListener("mouseup", handleMouseUp);
    document.addEventListener("touchend", handleMouseUp);

    return () => {
      document.removeEventListener("mousemove", handleMouseMove);
      document.removeEventListener("touchmove", handleTouchMove);
      document.removeEventListener("mouseup", handleMouseUp);
      document.removeEventListener("touchend", handleMouseUp);
    };
  }, [isDragging]);

  let leftImage = settings.left_image;
  let rightImage = settings.right_image;

  if (additional) {
    try {
      const additionalData = JSON.parse(additional);
      if (additionalData.product_metafields && additionalData.product_metafields.image_compare) {
        const additionalLeftImage = additionalData.product_metafields.image_compare.left_image;
        const additionalRightImage = additionalData.product_metafields.image_compare.right_image;
        if (additionalLeftImage && !additionalLeftImage.includes('invalid url input')) {
          leftImage = additionalLeftImage;
        }
        if (additionalRightImage && !additionalRightImage.includes('invalid url input')) {
          rightImage = additionalRightImage;
        }
      }
    } catch (error) {
      console.error('Error parsing additional data:', error);
    }
  }

  if (!leftImage || !rightImage || leftImage.includes('invalid url input') || rightImage.includes('invalid url input')) {
    return null;
  }

  return (
    <div className="compare-section">
      {(settings.title || settings.description) && (
        <div className="mb-8 text-center">
          {settings.title && (
            <ExtendedHeadings
              text={settings.title}
              type={"h2"}
              classes={"h2 mb-5 text-primary"}
            />
          )}
          {settings.description && (
            <p
              className="mx-auto mt-3 text-sm text-center text-primary font-body lg:text-base"
              dangerouslySetInnerHTML={{ __html: settings.description }}
            ></p>
          )}
        </div>
      )}
      <div className="relative select-none" ref={containerRef} style={{ paddingBottom: '56.25%' }}>
        <div className="absolute inset-0 flex">
          <div
            className="absolute inset-0"
            style={{
              clipPath: `inset(0 ${100 - sliderPosition}% 0 0)`,
            }}
          >
            <img
              src={leftImage}
              alt="Left Image"
              className="object-cover w-full h-full"
            />
          </div>
          <div
            className="absolute inset-0"
            style={{
              clipPath: `inset(0 0 0 ${sliderPosition}%)`,
            }}
          >
            <img
              src={rightImage}
              alt="Right Image"
              className="object-cover w-full h-full"
            />
          </div>
        </div>
        <div
          className="absolute inset-y-0 flex items-center justify-center cursor-col-resize"
          style={{ left: `${sliderPosition}%`, transform: "translateX(-50%)" }}
          onMouseDown={handleMouseDown}
          onTouchStart={handleMouseDown}
        >
          <div className="absolute right-0 -left-[22px] inset-y-0">
            <div className="grid h-full touch-none cursor-grab will-change-transform place-items-center w-max before:content-[''] before:w-[2px] before:bg-secondary before:-z-[1] before:h-full before:absolute">
              <i className="icon-left-right-arrow text-[12px] bg-secondary rounded-xl py-[18px] px-2"></i>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ImageCompare;