import * as React from "react";
import { useState, useEffect } from "react";

export const ProductTOG = ({ settings }) => {
  const [isTooltipVisible, setIsTooltipVisible] = useState(false);
  const [activeIndex, setActiveIndex] = useState(0);
  const [hoveredIndex, setHoveredIndex] = useState(null);

  useEffect(() => {
    const index = settings.tog_siblings.findIndex(
      (sibling) =>
        sibling.tog_rating.tog_title === settings.tog_metafields?.tog_title
    );
    setActiveIndex(index !== -1 ? index : 0);
  }, [settings.tog_metafields, settings.tog_siblings]);

  const toggleTooltip = () => {
    setIsTooltipVisible(!isTooltipVisible);
  };

  const handleTOGClick = (index) => {
    setActiveIndex(index);
  };

  const handleMouseEnter = (index) => {
    setHoveredIndex(index);
  };

  const handleMouseLeave = () => {
    setHoveredIndex(null);
  };

  if (!settings.tog_siblings || settings.tog_siblings.length === 0) {
    return null;
  }

  const currentIndex = hoveredIndex !== null ? hoveredIndex : activeIndex;

  return (
    <div className="lg:mt-2 lg:py-2.5">
      <div className="flex items-center justify-between mb-1.5 relative">
        <div className="text-base/[22px] font-semibold font-body lg:text-xl">
          <span className="text-primary">TOG</span>

          <span className="ml-3 text-tertiary lg:ml-4">
            {hoveredIndex !== null
              ? settings.tog_siblings[hoveredIndex]?.tog_rating.tog_feel
              : settings.tog_siblings[activeIndex]?.tog_rating.tog_feel}
          </span>
        </div>

        <div className="relative">
          <span
            onClick={toggleTooltip}
            className="cursor-pointer text-[#A87C64] underline font-body font-semibold text-xs/[21px] lg:text-sm/7"
          >
            {settings.tog_tooltip_title}
          </span>

          {isTooltipVisible && (
            <div className="absolute bg-white text-[#323D3E] p-4 rounded-lg shadow-lg top-full right-0 mt-2 w-80 z-10 border border-[#FAF8F6]">
              <div className="flex items-center justify-between mb-2">
                <span className="text-sm font-semibold font-body text-highlight">
                  {settings.tog_tooltip_title}
                </span>
                <button
                  className="p-0 h-auto min-h-[auto] w-auto text-primary/50 hover:text-primary focus:outline-none"
                  onClick={toggleTooltip}
                >
                  <i className="icon-close text-[10px]"></i>
                </button>
              </div>
              <div className="text-xs text-primary font-body">
                {settings.tog_tooltip_description}
              </div>
            </div>
          )}
        </div>
      </div>

      <div className="flex items-center space-x-2 lg:space-x-4">
        {settings.tog_siblings.map((sibling, index) => (
          sibling.tog_rating.tog_title === settings.tog_metafields?.tog_title ? (
            <span
              key={index}
              className="px-3 py-2 font-semibold rounded-xl font-body text-xs/5 lg:text-base/7 text-[#FDFCFC] lg:px-4 lg:py-3"
              style={{ backgroundColor: sibling.tog_rating.tog_colour }}
              onMouseEnter={() => handleMouseEnter(index)}
              onMouseLeave={handleMouseLeave}
            >
              {sibling.tog_rating.tog_title}
            </span>
          ) : (
            <a
              key={index}
              href={sibling.url}
              onClick={() => handleTOGClick(index)}
              className="px-3 py-2 font-semibold rounded-xl font-body text-xs/5 lg:text-base/7 bg-darker text-primary hover:text-highlight lg:px-4 lg:py-3"
              onMouseEnter={() => handleMouseEnter(index)}
              onMouseLeave={handleMouseLeave}
            >
              {sibling.tog_rating.tog_title}
            </a>
          )
        ))}
      </div>

      <p className="mt-1 text-xs/[18px] font-normal font-body text-primary lg:mt-2 lg:text-base">
        {settings.tog_siblings[currentIndex]?.tog_rating.tog_temp_range_c && (
          <>
            Perfect for {settings.tog_siblings[currentIndex].tog_rating.tog_temp_range_c}°C
            {settings.tog_siblings[currentIndex].tog_rating.tog_temp_range_f && (
              <> / {settings.tog_siblings[currentIndex].tog_rating.tog_temp_range_f}°F</>
            )}
          </>
        )}
      </p>
    </div>
  );
};

export default ProductTOG;