export const pagination = {
    name: 'pagination',
    component() {
        return {
            current_page: 0,
            items_total: 0,
            per_page: 0,
            pages: 0,
            pageOffset: 1,
            showViewMore: true,
            currentItemsViewed: 0,
            isInit: false,
            init(items_total, per_page, useViewMore = false) {
                if (items_total && per_page) {
                    this.items_total = items_total;
                    this.per_page = per_page;
                    this.pages = Math.ceil(items_total / per_page);
                    this.showViewMore = useViewMore;
                    this.currentItemsViewed = this.per_page;
                    this.current_page = 0;

                    if (this.current_page + this.pageOffset === this.pages) {
                        this.currentItemsViewed = this.items_total;
                    } else {
                        this.currentItemsViewed = this.per_page;
                    }

                    // console.log('Initialised Pagination for Component');
                    // console.log(`Total Items: ${items_total}`);
                    // console.log(`Per Page Items: ${per_page}`);
                    // console.log(`Pages: ${this.pages}`);

                    this.$nextTick(() => {
                        if (this.showViewMore) {
                            this.renderViewMoreButton();
                        } else {
                            this.renderPagination();
                        }
                    });
                }
            },
            renderPagination() {
                const paginationContainer = this.$refs.paginationContainer;
                paginationContainer.innerHTML = ''; // Clear existing pagination

                // Create and append the "Previous" button
                const prevButton = document.createElement('button');
                prevButton.innerText = '← Previous';
                prevButton.disabled = this.current_page === 1;
                prevButton.className = 'px-4 py-2 text-gray-600 bg-gray-100 rounded-md hover:bg-gray-200 disabled:opacity-50';
                prevButton.onclick = () => this.prevPage();
                paginationContainer.appendChild(prevButton);

                // Create and append page numbers
                for (let p = 1; p <= this.pages; p++) {
                    const pageButton = document.createElement('button');
                    pageButton.innerText = p.toString();
                    pageButton.className = `mx-1 px-3 py-1 rounded hover:bg-gray-200 ${this.current_page === p ? 'bg-blue-500 text-white' : 'bg-white text-gray-700'}`;
                    pageButton.onclick = () => this.gotoPage(p);
                    paginationContainer.appendChild(pageButton);
                }

                // Create and append the "Next" button
                const nextButton = document.createElement('button');
                nextButton.innerText = 'Next →';
                nextButton.disabled = this.current_page === this.pages;
                nextButton.className = 'px-4 py-2 text-gray-600 bg-gray-100 rounded-md hover:bg-gray-200 disabled:opacity-50';
                nextButton.onclick = () => this.nextPage();
                paginationContainer.appendChild(nextButton);
            },
            renderViewMoreButton() {
                const paginationContainer = this.$refs.paginationContainer;
                paginationContainer.innerHTML = ''; // Clear existing controls
                paginationContainer.className = 'view-more';

                const viewMoreText = document.createElement('p');
                viewMoreText.className = 'view-more__text';
                viewMoreText.innerHTML = `You've viewed <strong>${this.currentItemsViewed} of ${this.items_total}</strong> products`;
                paginationContainer.appendChild(viewMoreText);


                const progressBar = document.createElement('div');
                progressBar.id = 'progress-bar';
                progressBar.className = 'view-more__progress-bar';
                paginationContainer.appendChild(progressBar);

                const progressBarWrapper = document.getElementById('progress-bar');
                const progressBarMarker = document.createElement('div');
                progressBarMarker.className = 'view-more__progress-bar-marker';
                progressBarMarker.style.width = `${this.currentItemsViewed / this.items_total * 100}%`;
                progressBarWrapper.appendChild(progressBarMarker);

                if ((this.current_page + this.pageOffset) < this.pages) {
                    const viewMoreButton = document.createElement('button');
                    viewMoreButton.innerText = 'Load More';
                    viewMoreButton.className = 'view-more__button btn-light';
                    viewMoreButton.onclick = () => this.nextPage();
                    paginationContainer.appendChild(viewMoreButton);
                }
            },
            prevPage() {
                if ((this.current_page + this.pageOffset) > 1) {
                    this.gotoPage(this.current_page - this.pageOffset);
                }
            },
            nextPage() {
                if ((this.current_page + this.pageOffset) < this.pages) {
                    this.gotoPage(this.current_page + this.pageOffset);
                    if (this.showViewMore) {
                        const productsShown = (this.current_page + this.pageOffset) * this.per_page;
                        if (productsShown <= this.items_total) {
                            this.currentItemsViewed = productsShown;
                        } else {
                            this.currentItemsViewed = this.items_total;
                        }
                        window.dispatchEvent(new CustomEvent('nextpage', {
                            detail: {
                                type: 'pagination',
                                page: this.current_page
                            }
                        }));
                        this.renderViewMoreButton();
                    } else {
                        this.renderPagination();
                    }
                }
            },
            gotoPage(page) {
                this.current_page = page;
                this.renderPagination();
            },
        }
    }
}

export default pagination;
