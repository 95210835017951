import React, { useState, useEffect } from "react";
import { Button } from "@mindarc/arctheme-components/frontend/src/stories/components/elements/Button/Button";
import ExtendedHeadings from "../../elements/ExtendedHeadings/ExtendedHeadings";
import { Paragraph } from "@mindarc/arctheme-components/frontend/src/stories/components/elements/Paragraph/Paragraph";
import { ExtendedTextField } from "../../elements/ExtendedTextField/ExtendedTextField";

interface ExtendedNewsletterSignupProps {
  heading: string;
  content: string;
  show_button: boolean;
  button_text: string;
  cta_text: string;
  image?: string;
  onClick?: (email: string) => void;
  enable_newsletter_form?: boolean;
  redirect_url?: string;
}

export const ExtendedNewsletterSignup: React.FC<ExtendedNewsletterSignupProps> = ({
  heading,
  content,
  show_button,
  button_text,
  cta_text,
  image,
  onClick = null,
  enable_newsletter_form,
  redirect_url = "/"
}) => {
  const [email, setEmail] = useState("");
  const [isSuccess, setIsSuccess] = useState(false);

  useEffect(() => {
    const params = new URLSearchParams(window.location.search);
    console.log("newsletterSubscribe", params);
    const customer_posted = params.get("customer_posted");
    if (customer_posted === "true") {
      setIsSuccess(true);
    }
  }, []);

  const handleEmailChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setEmail(event.target.value);
  };

  const handleClick = () => {
    if (onClick) {
      onClick(email);
    }
  };

  const handleRedirect = () => {
    window.location.href = redirect_url;
  };

  return (
    <div className="relative flex flex-col justify-between lg:flex-row lg:items-center">
      <div className="flex flex-col lg:flex-grow lg:w-2/3">
        <ExtendedHeadings
          text={heading}
          type={"h2"}
          classes={"h2 mb-2 !text-4xl text-secondary lg:!text-[40px]/[48px] lg:mb-1"}
        />
        <div
          className="mb-6 text-base font-normal text-secondary font-body lg:mb-0"
          dangerouslySetInnerHTML={{ __html: content.replace(/\n/g, "<br />") }}
        />
      </div>

      <form
        id={"at-newsletter-subscribe-form"}
        action={"/contact"}
        method={"POST"}
        className="flex items-center w-full lg:w-1/3 lg:mt-0 lg:justify-end"
      >
        <input type="hidden" name="form_type" value="customer" />
        <input type="hidden" name="contact[email]" value="" />
        <input type="hidden" name="contact[tags]" value="newsletter" />
        <input type="hidden" name="utf8" value="✓" />

        {isSuccess && (
          <div
            className="px-4 py-4 my-4 text-white bg-green-600 success-message"
            role="alert"
          >
            <p className="font-bold">Thank you for subscribing!</p>
            <p>You have been successfully subscribed to our newsletter.</p>
          </div>
        )}

        <div className="flex w-full gap-y-4 lg:gap-x-4 lg:max-w-96">
          {show_button && (
            <>
              {enable_newsletter_form ? (
                <>
                  <div className="flex-grow">
                    <ExtendedTextField
                      type={"default-input"}
                      id={"email"}
                      placeholder={"Enter your email here.."}
                      value={email}
                      onChange={handleEmailChange}
                      className="w-full"
                    />
                  </div>
                  <div>
                    <Button
                      label={button_text}
                      onClick={handleClick}
                      className="w-full lg:w-auto"
                    />
                    <Paragraph
                      text={cta_text}
                      type={"b3 text-secondary"}
                    />
                  </div>
                </>
              ) : (
                <div className="w-full">
                  <a
                    href={redirect_url}
                    className="block w-full px-6 py-4 text-base font-normal text-center transition-all duration-200 bg-transparent border cursor-pointer font-body border-accent hover:bg-accent hover:text-primary hover:no-underline lg:w-auto"
                  >
                    {button_text}
                  </a>
                </div>
              )}
            </>
          )}
        </div>
      </form>
    </div>
  );
};

export default ExtendedNewsletterSignup;