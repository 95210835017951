import * as React from "react";

const ExtendedImageComponent = ({
  imageUrl,
  alt = "",
  isLazy = true,
  classes = "",
}) => {
  return (
    <img
      src={imageUrl}
      alt={alt}
      loading={isLazy ? "lazy" : undefined}
      className={classes}
    />
  );
};

export default ExtendedImageComponent;
