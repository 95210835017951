import * as React from "react";
import { useState } from "react";import ExtendedImage from "../../elements/ExtendedImage/ExtendedImage";

export const ExtendedProductSwatches = ({ settings }) => {
  const activeSwatchUrl = settings.active_swatch_url;
  const activeSwatch = settings.product_swatches.find(swatch => swatch.url === activeSwatchUrl);
  const [hoveredLabel, setHoveredLabel] = useState(activeSwatch?.label?.split("|")[0]);

  const heritageSwatches = settings.product_swatches.filter(swatch => swatch.label?.includes("Heritage"));
  const limitedEditionSwatches = settings.product_swatches.filter(swatch => swatch.label?.includes("Limited Edition"));
  const uncategorizedSwatches = settings.product_swatches.filter(swatch => !swatch.label?.includes("Heritage") && !swatch.label?.includes("Limited Edition"));

  if (!settings.product_swatches || settings.product_swatches.length === 0) {
    return null;
  }

  const renderSwatches = (swatches) => {
    return (
      <div className="flex flex-wrap items-center gap-2">
        {swatches.map((swatch, index) => {
          const isActive = swatch.url === activeSwatchUrl;
          const swatchLabel = swatch.label?.split("|")[0];
          return (
            <a
              key={index}
              href={isActive ? undefined : swatch.url}
              className="flex items-center"
              onMouseEnter={() => setHoveredLabel(swatchLabel || "Unknown")}
              onMouseLeave={() => setHoveredLabel(activeSwatch?.label?.split("|")[0])}
            >
              {swatch.image ? (
                <ExtendedImage
                  imageUrl={swatch.image}
                  alt={`Swatch color for ${swatch.color}`}
                  isLazy={true}
                  classes={`w-8 h-8 rounded-full lg:w-12 lg:h-12 ${isActive ? 'border-2 border-primary' : ''}`}
                />
              ) : (
                <span
                  className={`flex items-center justify-center w-8 h-8 rounded-full lg:w-12 lg:h-12 ${
                    isActive ? 'border-2 border-primary' : 'border-transparent'
                  }`}
                  style={{ backgroundColor: swatch.color }}
                  title={swatch.color}
                />
              )}
            </a>
          );
        })}
      </div>
    );
  };

  return (
    <div className="py-2.5 border-y border-primary/10 lg:py-4">
      <div className="text-base/[22px] font-semibold font-body mb-2 lg:text-xl lg:mb-3">
        <span className="text-primary">Print</span>
        <span className="ml-3 text-tertiary lg:ml-4">{hoveredLabel}</span>
      </div>

      {uncategorizedSwatches.length > 0 && (
        <div className="mb-3">
          {renderSwatches(uncategorizedSwatches)}
        </div>
      )}

      {heritageSwatches.length > 0 && (
        <div className="mb-3">
          <div className="text-sm font-semibold font-body lg:text-base">Heritage</div>
          {renderSwatches(heritageSwatches)}
        </div>
      )}

      {limitedEditionSwatches.length > 0 && (
        <div className="mb-3">
          <div className="text-sm font-semibold font-body lg:text-base">Limited Edition</div>
          {renderSwatches(limitedEditionSwatches)}
        </div>
      )}
    </div>
  );
};

export default ExtendedProductSwatches;

